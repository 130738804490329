import { IDateRangeValue, IHierarchyValue, IOperatorValue, ISelectedDateRangeInfo, ISelectedFacetInfo, ISelectedHierarchyInfo, ISelectedOperatorInfo } from "../types";


export class TypeInstanceOf {
    public static OperatorInfo(obj: any): obj is ISelectedOperatorInfo {
        return 'operatorInfo' in obj && 'operand' in obj && 'item' in obj;
    }

    public static DateRangeInfo(obj: any): obj is ISelectedDateRangeInfo {
        return 'dateRange' in obj && 'item' in obj;
    }

    public static FacetInfo(obj: any): obj is ISelectedFacetInfo {
        return 'facets' in obj && 'item' in obj;
    }

    public static HierarchyInfo(obj: any): obj is ISelectedHierarchyInfo {
        return 'hierarchies' in obj && 'item' in obj;
    }

    public static HierarchyValue(obj: any): obj is IHierarchyValue {
        return 'name' in obj && 'key' in obj && 'value' in obj;
    }

    public static DateRangeValue(obj: any): obj is IDateRangeValue {
        return 'startDate' in obj && 'endDate' in obj;
    }

    public static OperatorValue(obj: any): obj is IOperatorValue {
        return 'operator' in obj && 'operand' in obj;
    }
}
