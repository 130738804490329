import { Component, ViewChild, Input, OnChanges, Output, EventEmitter, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, AfterViewInit } from '@angular/core';
import { DataTableService, FilterService } from '../../../services/services-index';
import { Subscription } from 'rxjs';
import { Enums } from '../../../enums/enums';
import { ExportCsv } from '../../../../_shared/utilities/generate-csv';
import * as Models from '../../../models/models-index';
import { MatSort } from '@angular/material/sort';
import { TransactionDetailsModalComponent } from './transaction-details-modal/transaction-details-modal.component';
import { TransactionDetailDialogModel, TransactionDetailsSettings } from './lead-transactions.models';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource } from '@angular/material/legacy-table';

@Component({
  selector: 'transaction-details-data-table',
  templateUrl: './transaction-details-data-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransactionDetailsDataTableComponent implements Models.ElementComponent, OnDestroy, OnInit, AfterViewInit {
  @Input() columns: any[] = [];
  @Output() getRowData = new EventEmitter<any>();
  @ViewChild(MatLegacyPaginator, { static: false }) paginator: MatLegacyPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @Input() panelConfiguration: Models.PanelConfiguration;
  @Input() settings: Models.ElementSettings;

  private _dataSet: Models.DataSet;
  
  @Input()
  set dataSet(value: Models.DataSet) {
    this._dataSet = value;
  }

  selectedMetric?: string;
  selectedMetrics?: string[];

  reportName: string = 'LeadTransactions'

  displayedColumns: string[];
  receivedData: any;
  dataSource: MatLegacyTableDataSource<any>;
  filterBreadcrumbsForExport: string[];
  subscriptions: Subscription[] = [];
  transactionDetailsSettings?: TransactionDetailsSettings;

  constructor(
    private dataTableService: DataTableService,
    private changeDetector: ChangeDetectorRef,
    private filterService: FilterService,
    private dialog: MatDialog) {
    this.columns = [];
    this.receivedData = [];
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.dataTableService.dataUpdated$
        .subscribe(updatedData => {
          this.transactionDetailsSettings = this.parseTransactionDetailsSettings();
          this.columns = updatedData.columns || [];
          this.receivedData = updatedData.data || [];
          this.displayedColumns = this.columns.filter(col => col.show === true).map(x => x.columnDef);
          this.dataSource = new MatLegacyTableDataSource(this.receivedData);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort; this.columns = this.columns.map(col => {
            if (col.columnDef === this.transactionDetailsSettings.selectionAttribute) {
              col.clickable = true;
            }

            return col;
          });

          this.changeDetector.markForCheck();
          this.changeDetector.detectChanges();
          this.filterService.requestBreadcrumbString();
        }),
      this.dataTableService.printButtonClicked$.subscribe(data => {
        if (data.printingOption === Enums.printingOptions.pdf) {
          console.log('PDF exporting is not supported in hierarchyDataTable');
        } else {
          this.excel(data.title);
        }
      }),
      this.filterService.filterBreadcrumbsReturned$.subscribe(breadcrumbs => {
        this.filterBreadcrumbsForExport = breadcrumbs;
      })
    );
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.displayedColumns = [];
    this.receivedData = [];
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  openDialog(rowData: any, columnData: any): void {
    const attributes = new Map();
    this.transactionDetailsSettings.detailsViewAttributes.forEach(attr => {
      const { displayName, name } = this._dataSet.columns.find(c => c.name === attr);
      attributes.set(displayName || name, rowData[attr]);
    });

    const data: TransactionDetailDialogModel = {
      header: this.transactionDetailsSettings.detailsViewHeader,
      attributes,
      showActivity: this.transactionDetailsSettings.detailsViewShowActivity,
      activityFilters: new Map([[this.transactionDetailsSettings.keyAttribute, rowData[this.transactionDetailsSettings.keyAttribute].toString()]]),
      activityHeader: this.transactionDetailsSettings.detailsViewActivityHeader,
      activityDataset: this.transactionDetailsSettings.detailsViewActivityDataSet
    };

    // TODO(future): switch statement for different modal types
    this.dialog.open(TransactionDetailsModalComponent, {
      data
    });
  }

  private excel(title: string): void {
    let data: string[][] = [[title]];

    (this.filterBreadcrumbsForExport || []).forEach(bc => {
      data.push([bc]);
    });

    const cols: string[] = this.columns.filter(col => (!!col.show && col.print != false) || col.print === true).map(col => col.header);

    data.push(['']);
    data.push(cols);
    data = data.concat(this.getRowsForExport());

    const printTitle = title.replace(/-| /g, ''); // Regular expression /-| /g = all instances of ' ' or '-'

    new ExportCsv(data, printTitle);
  }

  private getRowsForExport(): string[][] {
    const results: string[][] = [];

    const columns = this.columns.filter(col => (!!col.show && col.print != false) || col.print === true);

    const sortBy = (key, dir) => {
      if (dir === 'desc') {
        return (a, b) => (a[key] > b[key]) ? -1 : ((b[key] > a[key]) ? 1 : 0);
      }
      else {
        return (a, b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0);
      }
    };

    const orderedData = this.dataSource.filteredData.concat().sort(sortBy(this.sort.active, this.sort.direction))

    orderedData.forEach(dataRow => {
      const exportRow: string[] = [];
      columns.forEach(col => {
        col.printFormatter
          ? exportRow.push(col.printFormatter(dataRow[col.columnDef]).toString()) // TODO: check output
          : col.formatter
            ? exportRow.push(col.formatter(dataRow[col.columnDef]).toString()) // TODO: check output
            : exportRow.push(dataRow[col.columnDef] || '');
      });
      results.push(exportRow);
    });

    return results;
  }

  getColumnWidthClass(columnDef: string): string {
    if (columnDef === 'city' || columnDef === 'vehicleModel' || columnDef === 'saleModel' || columnDef === 'dealerCode' || columnDef === 'saleDealerCode' || columnDef === 'saleDealerCode' || columnDef === 'saleDate') {
      return 'small';
    } else if (columnDef === 'timeToRespond') {
      return 'smaller';
    } else if (columnDef === 'state') {
      return 'smallest';
    } else if (columnDef === 'leadKey') {
      return 'big';
    }
  }

  private parseTransactionDetailsSettings(): TransactionDetailsSettings {
    const settings = {
      selectionAttribute: this.settings?.options?.['selection-attribute'],
      keyAttribute: this.settings?.options?.['key-attribute'],
      detailsViewHeader: this.settings?.options?.['details-view-header'],
      detailsViewAttributes: this.settings?.options?.['details-view-attributes']?.split(','),
      detailsViewShowActivity: this.settings?.options?.['details-view-show-activity'] ?? false,
      detailsViewActivityHeader: this.settings?.options?.['details-view-activity-header'],
      detailsViewActivityDataSet: this.settings?.options?.['details-view-activity-data-set']
    }

    return settings;
  }
}
