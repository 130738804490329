import { Component, EventEmitter, Input, Output, ViewChild, OnChanges, SimpleChanges, forwardRef, Injector, OnInit, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, Validator, AbstractControl, ValidationErrors, NG_VALIDATORS, NgControl } from '@angular/forms';
import { MonacoEditorComponent, MonacoEditorConstructionOptions, MonacoEditorLoaderService, MonacoStandaloneCodeEditor } from '@materia-ui/ngx-monaco-editor';
import { filter, take } from 'rxjs/operators';
import Ajv from 'ajv';
import { MonacoConfigService } from '../../services/monaco-editor/monaco-config-service';

@Component({
  selector: 'json-editor',
  templateUrl: './json-editor.component.html',
  styleUrls: ['./json-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => JsonEditorComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => JsonEditorComponent),
      multi: true,
    },
  ]
})
export class JsonEditorComponent implements ControlValueAccessor, Validator, OnInit {
  @Input() validationSchema?: any;
  private _value: string = '{}';
  @Input() readOnly: boolean = false;

  @Output() jsonValidationStatus: EventEmitter<boolean> = new EventEmitter<boolean>();

  private static instanceCounter = 0;
  private instanceId: number;
  private editor: MonacoStandaloneCodeEditor;

  private ajv: Ajv;
  validationErrors: string[] = [];
  isValid: boolean = false;
  isTouched: boolean = false;
  public ngControl: NgControl | null;

  get value(): any {
    return this._value;
  }

  @Input()
  set value(value: any) {
    if (value !== this._value) {
      this._value = value;
      this.onChange(value);
      this.onTouched();
    }
  }

  onChange: any = () => { };
  onTouched: any = () => { };
  setTouched(): void {
    this.isTouched = true;
  }

  @ViewChild(MonacoEditorComponent, { static: false })
  monacoComponent: MonacoEditorComponent;
  editorOptions: MonacoEditorConstructionOptions = {
    theme: 'appTheme',
    language: 'json',
    roundedSelection: true,
    autoIndent: 'full'
  };

  modelUri: monaco.Uri;

  constructor(
    private readonly monacoLoaderService: MonacoEditorLoaderService,
    private readonly monacoConfigService: MonacoConfigService,
    private readonly injector: Injector,
    private cdRef: ChangeDetectorRef
  ) {
    this.ajv = new Ajv();
    this.instanceId = JsonEditorComponent.instanceCounter++;
  }

  ngOnInit(): void {
    // Get the NgControl reference using the Injector
    this.ngControl = this.injector.get(NgControl, null);
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  // is called automatically, no need to run on component creation
  editorInit(editor: MonacoStandaloneCodeEditor) {
    this.editor = editor;
    this.modelUri = monaco.Uri.parse(`a://b/${this.instanceId}.json`);

    this.monacoLoaderService.isMonacoLoaded$
      .pipe(
        filter(isLoaded => !!isLoaded),
        take(1)
      )
      .subscribe(() => {
        editor.setModel(monaco.editor.createModel(this.value, 'json', this.modelUri));
        if (this.validationSchema) {
          this.registerMonacoJsonSchemaValidator();
        }
        monaco.editor.setTheme('appTheme');
        editor.updateOptions({ readOnly: this.readOnly });

        // Programatic content selection example
        editor.setSelection({
          startLineNumber: 1,
          startColumn: 1,
          endColumn: 50,
          endLineNumber: 3
        });

        // to make form control "touched"
        this.onEditorValueChange(editor.getValue());

        editor.onDidChangeModelContent(() => {
          this.setTouched();
          setTimeout(() => this.onEditorValueChange(editor.getValue()));
        });

        this.beautify();
      });
  }

  registerMonacoJsonSchemaValidator() {
    this.monacoConfigService.addSchema({
      // Make the URI unique per instance
      uri: `mySchema-${this.instanceId}`,
      // Apply this schema to the current model only
      fileMatch: [this.modelUri.toString()],
      schema: this.validationSchema
    });
  }

  // called whenever reactive form control value is changed from outside
  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    this.validateControl();
    return this.isValid ? null : { invalidJson: true, errors: this.validationErrors };
  }

  onEditorValueChange(value: string): void {
    this.value = value;

    if (this.ngControl && this.ngControl.control && this.ngControl.control.value !== value) {
      this.ngControl.control.setValue(value, { emitEvent: false });
      // triggers validateControl() method
      this.ngControl.control.updateValueAndValidity();
    }

    // tell Angular monaco editor is updated, otherwise throws
    // ERROR RuntimeError: NG0100: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked
    this.cdRef.detectChanges();
  }

  beautify() {
    setTimeout(() => {
      try {
        let jsonValue = JSON.parse(this.value);
        let formattedValue = JSON.stringify(jsonValue, null, 2);
        this.editor.setValue(formattedValue);
      } catch (e) {
        // if not valid json we do not beatify
      }
    }, 0);
  }

  private validateControl() {
    if (!this.editor) {
      // editor is not initialized yet
      return;
    }

    const value = this.editor.getValue();
    if (this.validationSchema) {
      try {
        const parsedValue = JSON.parse(value);
        this.isValid = this.ajv.validate(this.validationSchema, parsedValue) as boolean;

        if (!this.isValid) {
          this.validationErrors = this.ajv.errorsText(this.ajv.errors).split(', ');
        } else {
          this.validationErrors = [];
        }
      } catch (e) {
        this.isValid = false;
      }
    }
  }
}
