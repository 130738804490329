<mat-menu #menu>
    <ng-container *ngFor="let item of items; let index = index">
      <button *ngIf="!item.children?.length; else withChildren" 
              mat-menu-item 
              (click)="selectItem(item)" 
              [class.selected]="isInSelectedPath(item)" 
              class="button-no-children">
        <span>{{ item.label ?? item.value }}</span>
        <!-- <mat-icon *ngIf="isInSelectedPath(item) && !item.children?.length">check</mat-icon> -->
        <mat-icon *ngIf="showCheckMark(item)">check</mat-icon>
        
      </button>
  
      <ng-template #withChildren>
        <button mat-menu-item 
                [matMenuTriggerFor]="innerMenu.menu" 
                [class.selected]="isInSelectedPath(item)">
          {{ item.label }}
        </button>
        <nested-menu #innerMenu [items]="item.children" [selectedItem]="selectedItem" (selected)="selectItem($event)"></nested-menu>
      </ng-template>
    </ng-container>
  </mat-menu>
  