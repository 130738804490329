import {
    IDefaultTableColumnConfig,
    ISalesSummaryConfig,
    IDualAxisLineGraphConfig } from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';
export class SalesSummaryConfig implements ISalesSummaryConfig {
    public metricDisplayModes = [
      { name: 'MOM', value: 'MOM' },
      { name: 'YOY', value: 'YOY' }
    ];

    public graphConfig: IDualAxisLineGraphConfig = {
        xAxisPropertyName: 'date',
        availableMetrics: [
            {
                id: Enums.saleMetrics.totalSales.metricId,
                displayName: Enums.saleMetrics.totalSales.name,
                propertyName: Enums.saleMetrics.totalSales.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.saleMetrics.leadSales.metricId,
                displayName: Enums.saleMetrics.leadSales.name,
                propertyName: Enums.saleMetrics.leadSales.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.saleMetrics.leadContribution.metricId,
                displayName: 'Lead Contribution Rate',
                propertyName: Enums.saleMetrics.leadContribution.nameKey,
                metricFormatKey: constants.formatKeys.percentageTwoDecimals
            },
            {
                id: Enums.saleMetrics.averageTimeToSale.metricId,
                displayName: Enums.saleMetrics.averageTimeToSale.name,
                propertyName: Enums.saleMetrics.averageTimeToSale.nameKey,
                metricFormatKey: constants.formatKeys.localeString
            },
        ],

        defaultMetricOneId: Enums.saleMetrics.totalSales.metricId,
        defaultMetricTwoId: Enums.saleMetrics.leadSales.metricId,
        metricOneColorHexCode: '#05141F',
        metricTwoColorHexCode: '#9EA1A2'
    };
    public summaryTableColumns: IDefaultTableColumnConfig[] = [
      { show: true,
        header: '',
        columnDef: 'entity',
        hasComparisonMetrics: false,
        metricFormatKey: constants.formatKeys.entityDisplayName,
        clickable: true
      },
      {
        show: false,
        print: true,
        header: 'Dealer Name',
        columnDef: 'displayName',
        hasComparisonMetrics: false,
        metricFormatKey: constants.formatKeys.entityDisplayName, clickable: false
      },
      {
        show: true, header: 'Total Sales',
        columnDef: 'totalSales',
        hasComparisonMetrics: true,
        metricFormatKey: constants.formatKeys.localeString,
        comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
        clickable: false
      },
      {
        show: true,
        header: 'Sales From Leads',
        columnDef: 'leadSales',
        hasComparisonMetrics: true,
        metricFormatKey: constants.formatKeys.localeString,
        comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
        clickable: false
      },
      {
        show: true,
        header: 'Lead Contribution Rate',
        columnDef: 'leadContribution',
        hasComparisonMetrics: true,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals,
        comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
        clickable: false
      },
      {
        show: true,
        header: 'Avg. Time to Sale',
        columnDef: 'averageTimeToSale',
        hasComparisonMetrics: true,
        metricFormatKey: constants.formatKeys.integerDaysOrNa,
        comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
        clickable: false
      },
  ];
}
