<mat-card class="mat-elevation-z1" style="background-color: white; color: black;">
    <mat-card-title>
        <i class="fa pad-right" [ngClass]="titleIconClasses"></i><b>{{title}}</b>
        <div class="pull-right ellipses-dropdown">
            <button title="options" mat-button class="card-options-button" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu"><i class="fa fa-ellipsis-v fa-primary" style="font-size: 1.4em;"></i></button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item style="outline: none;" (click)="openHelpTextClicked()">{{ "Help" | translate : locale | async }}</button>
            </mat-menu>
        </div>
    </mat-card-title>
    <mat-card-content class="card-block target-card billboard" style="margin-bottom:0px;">
      <div class="flex-row" fxLayout="row">
          <div class="metric-container full-width-on-small">
              <table>
              <tbody>
                  <tr>
                      <td>
                          <b class="metric-value">
                              {{metricValue}}
                          </b>
                          <i [ngClass]="[getTrendArrowIconClass(metricValuePOPValue),getTrendIconColor(metricValuePOPValue)]" style="font-size: 1.1em;"></i>
                      </td>
                  </tr>
                  <tr *ngIf="subtitleOne">
                      <td class="box-label" style="font-size:1.2rem">
                        <span class="subtitle-label">{{subtitleOneLabel | translate : locale | async }}</span>
                        <span class="subtitle-value">{{subtitleOne}}</span>
                      </td>
                  </tr>
                  <tr *ngIf="subtitleTwo">
                      <td class="box-label" style="font-style: italic;font-size:1.2rem">
                        <span class="subtitle-label">{{subtitleTwoLabel | translate : locale | async }}</span>
                        <span class="subtitle-value">{{subtitleTwo}}</span>
                      </td>
                  </tr>
                  <!-- <tr *ngIf="subtitleThree">
                      <td class="box-label" style="font-style: italic">{{ config.subtitle3Value }}</td>
                  </tr> -->
              </tbody>
              </table>
          </div>
          <div class="chart-container">
            <div id="chartContainer" *ngIf="!!chart" [chart]="chart" class="chart"></div>
          </div>
      </div>
    </mat-card-content>
    <mat-card-footer class="billboard-seperator">
      <table style="width:100%">
          <tbody>
              <tr>
                <td style="width:50%">
                    <div *ngIf="metricValuePOP">
                        {{"POP" | translate : locale | async }}: <b [ngClass]="getTrendColor(metricValuePOPValue)">
                            {{metricValuePOP}}</b>
                        <i style="margin-left: 5px; font-size:1.1em;" [ngClass]="[getTrendArrowIconClass(metricValuePOPValue),getTrendIconColor(metricValuePOPValue)]"></i>
                    </div>
                    <div *ngIf="!metricValuePOP">
                      {{"POP" | translate : locale | async }}: <b>N/A</b>
                    </div>
                </td>
                <td style="width:50%; text-align:right">
                    <div *ngIf="metricValueYOY">
                        {{"YOY" | translate : locale | async }}: <b [ngClass]="getTrendColor(metricValueYOYValue)">
                            {{metricValueYOY}}</b>
                            <i style="margin-left: 5px; font-size:1.1em;" [ngClass]="[getTrendArrowIconClass(metricValueYOYValue),getTrendIconColor(metricValueYOYValue)]"></i>
                    </div>
                    <div *ngIf="!metricValueYOY">
                      {{"YOY" | translate : locale | async }}: <b>N/A</b>
                    </div>
                </td>
              </tr>
          </tbody>
      </table>
    </mat-card-footer>
  </mat-card>
