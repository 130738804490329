import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-confirm-proceed-modal',
  template: `
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <div mat-dialog-content>
      <p>{{ data.message }}</p>
    </div>
    <div mat-dialog-actions>
      <button mat-flat-button color="basic" class="cancel-button" (click)="onCancelClick()">
        <mat-icon>cancel</mat-icon> Cancel
      </button>
      <div class="spacer"></div>
      <button mat-stroked-button color="primary" (click)="onConfirmClick()">
        <mat-icon>check_circle</mat-icon> Proceed
      </button>
    </div>
  `,
  styles: [`
    mat-dialog-content {
      font-size: 16px;
      line-height: 1.5;
      margin-bottom: 20px;
    }
    mat-dialog-actions {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .spacer {
      flex-grow: 1;
    }
    button {
      display: flex;
      align-items: center;
      gap: 5px;
    }
    .cancel-button {
      background-color: black;
      color: white;
    }
  `],
})
export class ConfirmProceedModalComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmProceedModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string; message: string }
  ) {}

  onCancelClick(): void {
    this.dialogRef.close(false);
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }
}
