import { Component, ChangeDetectionStrategy, Input, OnInit, OnDestroy } from '@angular/core';
import * as ElementModels from '../../../models/report-view.models';
import * as Highcharts from 'highcharts';
import { Chart } from 'angular-highcharts';
import * as Models from '../../../models/models-index';
import { SeriesOptionsType } from 'highcharts';
import { ChartService } from '../../chart/chart.service';
import { takeWhile } from 'rxjs/operators';
import * as SharedServices from '../../../services/services-index';
import { Subscription } from 'rxjs';
import { getChartColors } from '../panel-utils';

@Component({
  selector: 'kpi-multi-line-chart',
  templateUrl: './kpi-multi-line-chart.component.html',
  styleUrls: ['../panel-elements.scss', './kpi-multi-line-chart.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class KpiMultiLineChartComponent implements Models.ElementComponent, OnInit, OnDestroy {
  @Input() dataSet: ElementModels.DataSet;
  @Input() settings: ElementModels.ElementSettings;
  @Input() selectedMetrics: string[];
  subscriptions: Subscription[] = [];
  currentChart: Chart;
  chartTitle: string = '';
  locale: string;
  componentActive: boolean = true;
  translationService: Models.ITranslationService;

  constructor(
    private chartService: ChartService,
    private localeService: SharedServices.LocaleService,
  ) {

  }

  ngOnInit() {

    this.subscriptions.push(
      this.localeService.locale$.subscribe(loc => this.locale = loc),
      this.chartService.reflowChart$
        .pipe(takeWhile(() => this.componentActive))
        .subscribe(() => {
          setTimeout(() => {
            this.currentChart.ref.reflow();
          }, 200);
        })
    )

    let seriesData = [];

    const metricIndexMapping = this.dataSet.columns.reduce((map, col, index) => {
      map[col.name] = { ...col, index };
      return map;
    }, {} as Record<string, typeof this.dataSet.columns[0] & { index: number }>);

    // Generate x labels
    const xAxisLables = this.dataSet.rows.map(m => m[metricIndexMapping[this.settings.dimensionName].index].label)

    //console.log('SelectedMetrics', this.selectedMetrics);

    // Generate series data
    this.selectedMetrics.forEach(metric => {
      const data = this.dataSet.rows.map(m => {
        const value = m[metricIndexMapping[metric].index].value ?? 0;
        const label = m[metricIndexMapping[metric].index].label;

        return {
          y: value,
          name: label ? label : value
        };
      });

      seriesData.push({
        name: metricIndexMapping[metric].displayName,
        data: data
      });

      // generate title
      if (this.chartTitle != '') this.chartTitle += ' vs. ';
      this.chartTitle += metricIndexMapping[metric].displayName;
    });

    // Generate y axis options and link them to series
    let yAxisOptions = this.selectedMetrics.map((metric, index) => {
      const yAxisIsPercentage = this.isPercentageMetric(metric);
      return {
        title: { text: metricIndexMapping[metric].displayName },
        opposite: index % 2 === 1, // Put every second axis on the right side
        labels: {
          formatter: function () {
            return yAxisIsPercentage ? (this.value * 100).toFixed(0) + '%' : this.value.toString();
          }
        }
        // labels: {
        //   formatter: function () {
        //     // If the metric kind is a 'ratio', format as a percentage
        //     if (metricIndexMapping[metric].kind == "ratio") {
        //       return (this.value * 100).toFixed(2) + '%';
        //     } else {
        //       return (this.value / 1000).toLocaleString() + 'k';
        //     }
        //   }
        // }
      };
    });

    // Link series to the corresponding y-axis
    seriesData.forEach((series, index) => {
      series.yAxis = index; // Link this series to its y-axis
    });

    // Generate the actual chart
    this.currentChart = this.generateChart(seriesData, xAxisLables, yAxisOptions);
  }

  ngOnDestroy(): void {
    this.componentActive = false;
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private generateChart(seriesData: any[], xAxisLables: any[], yAxisOptions: any[]) {
    return new Chart({
      chart: {
        type: 'spline'
      },
      credits: { enabled: false },
      colors: getChartColors(this.settings),
      title: { text: this.chartTitle },
      xAxis: {
        allowDecimals: false,
        categories: xAxisLables
      },
      tooltip: {
        shared: true,
        formatter: function () {
          let s = '';

          for (let i = 0; i < this.points.length; i++) {
            const myPoint = this.points[i];
            s += '<br/>' + myPoint.series.name + ': ';

            // If 'name' property is defined, show it. Otherwise, show 'y' property.
            s += myPoint.point.name ? myPoint.point.name : myPoint.y;
          }
          return s;
        },
      },
      plotOptions: {
        area: {
          marker: {
            enabled: true,
            symbol: 'circle',
            radius: 2,
            states: {
              hover: {
                enabled: true
              }
            }
          }
        }
      },
      yAxis: yAxisOptions,
      series: seriesData
    });
  }

  private isPercentageMetric(metricName: string): boolean {
    // Implement logic to determine if the metric is a percentage
    // This could be based on the metric name, or any other criteria you have
    const metricIndex = this.dataSet.columns.findIndex(col => col.name === metricName);
    const metric = this.dataSet.columns[metricIndex];

    if (this.dataSet.rows.length == 0) return false;

    return this.dataSet.rows[0][metricIndex].label?.includes('%') || metric.name?.includes('%');
  }
}
