import { Injectable, signal, WritableSignal, effect } from '@angular/core';
import { ReportViewFilter } from '../../models/report-view.models';
import { IndexDbStorageService } from '../services-index';
import { catchError, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportViewFiltersService {
  private _reportViewFilters: WritableSignal<ReportViewFilter[]> = signal([]);

  constructor(private indexDbStorage: IndexDbStorageService) {
    // Load data from IndexedDB on service initialization
    this.loadReportViewFilters();

    // Save the report view filters to IndexedDB whenever they change
    effect(() => {
      const filters = this._reportViewFilters();
      this.saveReportViewFilters(filters);
    });
  }

  get reportViewFilters(): WritableSignal<ReportViewFilter[]> {
    return this._reportViewFilters;
  }

  loadReportViewFilters(): void {
    this.indexDbStorage.get<ReportViewFilter[]>('reportViewFilters')
      .pipe(
        catchError(error => {
          console.error('Failed to load report view filters from IndexedDB:', error);
          return of([]); // Return an empty array if loading fails
        })
      )
      .subscribe(filters => this._reportViewFilters.set(filters || []));
  }

  saveReportViewFilters(filters: ReportViewFilter[]): void {
    const filtersToSave = filters.map(filter => ({ ...filter })); // Ensure we're only storing plain data
    this.indexDbStorage.set<ReportViewFilter[]>('reportViewFilters', filtersToSave)
      .pipe(
        catchError(error => {
          console.error('Failed to save report view filters to IndexedDB:', error);
          return of(void 0);
        })
      )
      .subscribe();
  }

  updateReportViewFilters(filters: ReportViewFilter[]): void {
    this._reportViewFilters.set(filters);
  }
}
