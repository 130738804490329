import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IDateRangeValue, IHierarchyValue, IReplayItem, isHierarchyValueArray } from '../../../../types/faceted-filter.types';
import { TypeCheck } from '../../../../Utils/type-check';
import { BasePaneComponent } from '../base-pane/base-pane.component';

@Component({
	selector: 'filter-pane',
	templateUrl: './filter-pane.component.html',
	styleUrls: ['./filter-pane.component.scss'],
})
export class FilterPaneComponent extends BasePaneComponent implements OnInit, OnDestroy {

	@Input() set replayItem(replayItem: IReplayItem) {
		this.item = { ...replayItem.item };
		this.handleReplayItem(replayItem);
	}

	constructor() {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit()
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	// TODO: Clean this up
	private handleReplayItem(replayItem: IReplayItem): void {
		setTimeout(() => {
			const selected = this.item.items?.find(i => i.value === replayItem.value || i.label === replayItem.value || (replayItem.value as IDateRangeValue).name == i.label);

			if (selected) {
				if ((replayItem.actionable || TypeCheck.isUtilityContainer(selected.type))) {
					this.selectedItem = { ...selected };
					if (TypeCheck.hasOperatorType(selected.type)) {
						this.containerItemSelected(selected);
					} else if (TypeCheck.isDateRangeFacetContainer(selected.type)) {
						this.selectedItem = { ...selected };
						this.handleSingleSelectItem(selected);
					}
				} else {
					if (this.isMultiSelect) {
						this.selectedCheckableItems = [selected];
						this.handleMultiSelectItems();
					} else {
						if (TypeCheck.isContainer(selected.type)) {
							this.selectedItem = { ...selected };
						} else {
							this.handleSingleSelectItem(selected);
						}
					}
				}
			} else {
				if (Array.isArray(replayItem.value)) {
					if (isHierarchyValueArray(replayItem.value)) {
						const vals = replayItem.value.map(item => item.value);
						this.selectedCheckableItems = this.item.items.filter(i => vals.includes((i.value as IHierarchyValue).value));
						this.handleMultiSelectItems();
					} else {
						const vals: string[] = <string[]>[...replayItem.value];
						this.selectedCheckableItems = this.item.items.filter(i => vals.includes((i.value as string) ?? i.label));
						this.handleMultiSelectItems();
					}
				}
			}
		}, 1);
	}
}
