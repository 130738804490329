<div id="sidebar-container" class="sidebar-container" *ngIf="layoutHelper.sideNavToggleAction$ | async as toggleStatus">
  <div class="sidebar-fixed sidebar" [ngClass]="{'sidebar-compact': (toggleStatus === 'closed')}">
    <nav class="sidebar-nav">
      <ul class="nav">
        <li *ngFor="let routeConfig of routeConfigs" class="nav-item">
          <div class="sidebar-category">
            <div class="sidebar-category-open" *ngIf="(toggleStatus === 'open')">
              <div class="sidebar-link">
                <a *ngIf="!routeConfig.externalTool" class="nav-link" [ngClass]="{'active': (layoutHelper.currentCategory === routeConfig.category)}"
                  routerLinkActive="active" [routerLink]="[routeConfig.defaultRoute]">
                  <i class="sidebar-icon" [ngClass]="routeConfig.iconClass"></i>
                  <span>{{routeConfig.displayName | translatelayout: locale}}</span>
                </a>
                <a *ngIf="!!routeConfig.externalTool" class="nav-link" [ngClass]="{'active': (layoutHelper.currentCategory === routeConfig.category)}"
                  routerLinkActive="active">
                  <i class="sidebar-icon" [ngClass]="routeConfig.iconClass"></i>
                  <span>{{routeConfig.displayName | translatelayout: locale}}</span>
                </a>
              </div>
              <div class="sidebar-expand nav-link" (click)="layoutHelper.updateExpandedCategory(routeConfig.category)"
                [ngClass]="{'active': (layoutHelper.currentCategory === routeConfig.category)}">
                <i class="sidebar-category-icon mat-icon material-icons pull-right"
                  [@rotate90]="layoutHelper.expandedCategory !== routeConfig.category">
                  keyboard_arrow_right
                </i>
              </div>
            </div>
            <a *ngIf="(toggleStatus === 'closed') && !routeConfig.externalTool" class="nav-link"
              [ngClass]="{'active': (layoutHelper.currentCategory === routeConfig.category)}" routerLinkActive="active" 
              [routerLink]="[routeConfig.defaultRoute]" [matMenuTriggerFor]="currentMenu"
              #currentMenuTrigger="matMenuTrigger" id="{{routeConfig.id}}" (mouseenter)="openMenu(currentMenuTrigger)"
              style="width: 100%;">
              <i class="sidebar-icon" [ngClass]="routeConfig.iconClass"></i>
              <span>{{routeConfig.displayName | translatelayout: locale}}</span>
            </a>
            <a *ngIf="(toggleStatus === 'closed') && !!routeConfig.externalTool" class="nav-link"
              [ngClass]="{'active': (layoutHelper.currentCategory === routeConfig.category)}" routerLinkActive="active"
              [matMenuTriggerFor]="currentMenu" #currentMenuTrigger="matMenuTrigger" id="{{routeConfig.id}}" (mouseenter)="openMenu(currentMenuTrigger)"
              style="width: 100%;">
              <i class="sidebar-icon" [ngClass]="routeConfig.iconClass"></i>
              <span>{{routeConfig.displayName | translatelayout: locale}}</span>
            </a>
          </div>
          <mat-menu #currentMenu="matMenu" [overlapTrigger]="false" [hasBackdrop]="true"
            backdropClass="flyout-menu-backdrop" class="flyout-menu">
            <ng-template matMenuContent>
              <button class="flyout-menu-title" [disabled]="true" mat-menu-item>
                <i [ngClass]="routeConfig.iconClass"></i><span> {{routeConfig.displayName | translatelayout: locale}}</span>
              </button>
              <mat-divider></mat-divider>
              <div *ngFor="let child of routeConfig.children">
                <button *ngIf="!routeConfig.externalTool" mat-menu-item routerLinkActive="active" [routerLink]="[child.parent, child.route]">
                  {{child.displayName | translatelayout: locale}}
                </button>
                <button *ngIf="!!routeConfig.externalTool" mat-menu-item (click)="openExternalTool(child, routeConfig.newTab)">
                  {{child.displayName | translatelayout: locale}}
                </button>
              </div>
            </ng-template>
          </mat-menu>
          <div *ngIf="toggleStatus === 'open' && layoutHelper.expandedCategory === routeConfig.category">
            <ul class="nav">
              <li class="nav-item" *ngFor="let child of routeConfig.children">
                <a *ngIf="!routeConfig.externalTool" class="nav-link nav-item-child" routerLinkActive="active" [routerLink]="[child.parent, child.route]">
                  {{child.displayName | translatelayout: locale}}
                </a>
                <a *ngIf="!!routeConfig.externalTool" class="nav-link nav-item-child" (click)="openExternalTool(child, routeConfig.newTab)">
                  {{child.displayName | translatelayout: locale}}
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </nav>
  </div>
  <div class="sidebar-toggle pull-right" (click)="layoutHelper.toggleSidebar(toggleStatus)">
    <i class="sidebar-toggle-icon mat-icon-lg mat-icon material-icons pull-right" style="overflow: visible"
      [@rotate180]="layoutHelper.rotateMenuGlyph">
      keyboard_arrow_left
    </i>
  </div>
</div>
