import { ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef } from "@angular/core";
import { takeUntil } from "rxjs/operators";
import { IFacetedFilterDataItem, IReplayItem, ISelectionInfo, ISelectedHierarchyInfo, ISelectedFacetInfo, IHierarchyValue, IOperatorValue, ISelectedDateRangeInfo } from "../types/faceted-filter.types";
import { PaneComponentFactory } from "../Utils/pane-component.factory";
import { PaneInstanceManager } from "../Utils/pane-instance.manager";
import { TypeCheck } from "../Utils/type-check";
import { FilterPanelValueService } from "./filter-panel-value.service";
import { FilterPanelTypes } from "../types";
import { FacetedFilterFacetItemType } from "../types/constants";

@Injectable()
export class FilterPaneManagerService {
	private _host: ViewContainerRef;
  private _resolver: ComponentFactoryResolver;

	private _noPathReplayItemStash: IReplayItem;

	componentFactory: PaneComponentFactory;

	constructor(private valueService: FilterPanelValueService) { }

    setContainerRefHost(host: ViewContainerRef, resolver: ComponentFactoryResolver): void {
		this._host = host;
    this._resolver = resolver;
		this.componentFactory = new PaneComponentFactory(host, resolver);
	}

	hasContainerRefHost(): boolean {
		return !!this._host;
	}

	loadFilterPane(item: IFacetedFilterDataItem, type?: string): void {
		PaneInstanceManager.removeChildItemsIfExists(item.path);
		const componentInstance = this.componentFactory.loadComponentInstance(item, type);
		PaneInstanceManager.addActiveInstance(componentInstance);
		this.setNonReplayableItemValue(componentInstance);
		this.watchPaneInstanceEvents(componentInstance);
	}

	loadReplayFilterPane(replayItem: IReplayItem): void {
		PaneInstanceManager.removeChildItemsIfExists(replayItem.item.path);
		const componentInstance = this.componentFactory.loadReplayComponentInstance(replayItem);
		PaneInstanceManager.addActiveInstance(componentInstance);
		this.watchPaneInstanceEvents(componentInstance);
	}

	replayResult(replayItems: IReplayItem[]): void {
		this.stashNonReplayableItemIfExists(replayItems);

		for (let i = 0; i < replayItems.length; i++) {
			if (TypeCheck.isReplayableContainer(replayItems[i].item.type)) {
				this.loadReplayFilterPane(replayItems[i]);
			}
		}
	}

    purgeAllLoadedComponents(): void {
		PaneInstanceManager.purgeActiveInstances();
		this.valueService.clearSelectionInfo();
    }
	// TODO: Find the overlap below and consolidate
	private watchPaneInstanceEvents(componentInstance: ComponentRef<any>): void {
		if (!!componentInstance.instance.onContainerItemSelected) {
			componentInstance.instance.onContainerItemSelected.pipe(
				takeUntil(componentInstance.instance.destroy$)
			).subscribe((item: IFacetedFilterDataItem) => {
				this.onContainerItemSelected(item);
			});
		}

		if (!!componentInstance.instance.onDateRangeContainerItemSelected) {
			componentInstance.instance.onDateRangeContainerItemSelected.pipe(
				takeUntil(componentInstance.instance.destroy$)
			).subscribe(($event: ISelectedDateRangeInfo) => {
				this.onDateRangeContainerItemSelected($event);
			});
		}

		if (!!componentInstance.instance.onCheckableItemsChanged) {
			componentInstance.instance.onCheckableItemsChanged.pipe(
				takeUntil(componentInstance.instance.destroy$)
			).subscribe(($event: ISelectionInfo) => {
				this.onCheckableItemsChanged($event);
			});
		}

		if (!!componentInstance.instance.onOperatorExpressionSelected) {
			componentInstance.instance.onOperatorExpressionSelected.pipe(
				takeUntil(componentInstance.instance.destroy$)
			).subscribe(($event: ISelectionInfo) => {
				this.onOperatorExpressionSelected($event);
			});
		}

		if (!!componentInstance.instance.onDateRangeSelected) {
			componentInstance.instance.onDateRangeSelected.pipe(
				takeUntil(componentInstance.instance.destroy$)
			).subscribe(($event: ISelectionInfo) => {
				this.onDateRangeSelected($event);
			});
		}

		if (!!componentInstance.instance.onHierarchyContainerItemsChanged) {
			componentInstance.instance.onHierarchyContainerItemsChanged.pipe(
				takeUntil(componentInstance.instance.destroy$)
			).subscribe((item: ISelectedHierarchyInfo) => {
				this.onHierarchyContainerItemsChanged(item);
			});
		}

		if (!!componentInstance.instance.onHierarchyItemsChanged) {
			componentInstance.instance.onHierarchyItemsChanged.pipe(
				takeUntil(componentInstance.instance.destroy$)
			).subscribe((item: ISelectedHierarchyInfo) => {
				this.onHierarchyItemsChanged(item);
			});
		}

		if (!!componentInstance.instance.onHierarchyReplaySelection) {
			componentInstance.instance.onHierarchyReplaySelection.pipe(
				takeUntil(componentInstance.instance.destroy$)
			).subscribe((item: ISelectedHierarchyInfo) => {
				this.onHierarchyReplaySelection(item);
			});
		}

		if(!!componentInstance.instance.onCalendarTypeSelected) {
			componentInstance.instance.onCalendarTypeSelected.pipe(
				takeUntil(componentInstance.instance.destroy$)
			).subscribe((item: ISelectedDateRangeInfo) => {
				this.onCalendarTypeSelected(item);
			});
		}

		setTimeout(() => {
			componentInstance.location.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
		}, 1);
	}

	private onCalendarTypeSelected(info: ISelectedDateRangeInfo) {
		this.valueService.setSelectionInfo(info);
	}

	private onContainerItemSelected(item: IFacetedFilterDataItem): void {
		if (TypeCheck.isFacet(item.type) && !TypeCheck.isDateRangeFacetContainer(item.type)) {
			PaneInstanceManager.removeChildItemsIfExists(item.path);
			// TODO: Add Items to Value Store if ever needed...We'll see
		} else {
			this.valueService.clearSelectionInfo();
			this.loadFilterPane(item);
		}
	}

	private onDateRangeContainerItemSelected(selectionInfo: ISelectedDateRangeInfo): void {
		if (selectionInfo.dateRange && TypeCheck.isDateRangeArray(selectionInfo.item.value) && selectionInfo.item.value.length) {
			this.valueService.setSelectionInfo(selectionInfo);
			this.loadFilterPane(selectionInfo.item, 'calendarType');	
		}
		else if (selectionInfo.dateRange) {
			this.valueService.setSelectionInfo(selectionInfo);
			this.loadFilterPane(selectionInfo.item);
		} else {
			this.valueService.clearSelectionInfo();
			PaneInstanceManager.removeChildItemsIfExists(selectionInfo.item.path);
		}
	}

	private onCheckableItemsChanged(selectionInfo: ISelectionInfo): void {
		if (TypeCheck.isFacet(selectionInfo.item.type))
			PaneInstanceManager.removeChildItemsIfExists(selectionInfo.item.path);

		const selection = <ISelectedFacetInfo>selectionInfo;
		if (selection.facets && selection.facets.length) {
			this.valueService.setSelectionInfo(selection);

			//check to see if it's a date-range-container and if so, open up a calendar-type-pane
			if (selection.item.type === FacetedFilterFacetItemType.DateRange) {
				this.loadFilterPane(selection.item, 'calendarType');
			}
		}
		else {
			this.valueService.clearSelectionInfo();
		}
	}

	private onOperatorExpressionSelected(selectionInfo: ISelectionInfo): void {
		this.valueService.setSelectionInfo(selectionInfo);
	}

	private onDateRangeSelected(selectionInfo: ISelectionInfo): void {
		this.valueService.setSelectionInfo(selectionInfo);
	}

	private onHierarchyContainerItemsChanged(selectionInfo: ISelectedHierarchyInfo): void {
		this.valueService.clearSelectionInfo();
		this.valueService.setSelectionInfo(selectionInfo);

		if (!selectionInfo.hierarchies.length) {
			this.valueService.clearSelectionInfo();
		}
		if (selectionInfo.purgePath) {
			PaneInstanceManager.removeChildItemsIfExists(selectionInfo.purgePath);

			const parentSelection: ISelectedHierarchyInfo = {
				hierarchies: selectionInfo.hierarchies.length > 0 ? selectionInfo.hierarchies : [<IHierarchyValue>selectionInfo.item.value],
				item: selectionInfo.item
			};
			this.valueService.setSelectionInfo(parentSelection);
		}
		else {
			this.valueService.setSelectionInfo(selectionInfo);
			this.loadFilterPane(selectionInfo.item);
		}
	}

	private onHierarchyItemsChanged(selectionInfo: ISelectedHierarchyInfo): void {
		if (selectionInfo.hierarchies && selectionInfo.hierarchies.length)
			this.valueService.setSelectionInfo(selectionInfo);
		else {
			this.valueService.clearSelectionInfo();
			const parentSelection: ISelectedHierarchyInfo = {
				hierarchies: [<IHierarchyValue>selectionInfo.item.value],
				item: selectionInfo.item
			};
			this.valueService.setSelectionInfo(parentSelection);
		}
	}

	private onHierarchyReplaySelection(selectionInfo: ISelectedHierarchyInfo): void {
		this.valueService.clearSelectionInfo();
		this.valueService.setSelectionInfo(selectionInfo);
	}

	private stashNonReplayableItemIfExists(replayItems: IReplayItem[]): void {
		this._noPathReplayItemStash = replayItems.find(ri => !TypeCheck.isReplayableContainer(ri.item.type));
	}

	private setNonReplayableItemValue(component: ComponentRef<any>): void {
		if (this._noPathReplayItemStash) {
			component.instance.replayValue = this._noPathReplayItemStash.value;
			this._noPathReplayItemStash = undefined;
		}
	}
}
