
<ng-container *ngIf="item && item.items; else noData">

    <div class="pane-wrapper">

        <div class="pane scrollbar-thin scrollbar-thumb scrollbar-track-tint overflow-y-auto">

            <div *ngIf="isMultiSelect" class="w-full h-10 flex items-center px-3 text-gray-900 bg-white hover:bg-gray-50 active:bg-gray-200 cursor-pointer border-b border-gray-300">

                <mat-checkbox [indeterminate]="isIndeterminate()" [checked]="selectAllIsChecked()"
                    (change)="selectAllCheckboxChanged($event)" labelPosition="right"><span class="ml-2 text-base md:text-sm text-gray-900 font-medium">Select all</span>
                </mat-checkbox>

            </div>

            <ng-container *ngIf="itemsList && itemsList.length">

                <div *ngFor="let item of itemsList"
                        class="panel-option"
                        [ngClass]="{'active': selectedItem?.label === item.label}"
                        (click)="containerItemSelected(item);">
        
                    <div class="flex-1 truncate" [title]="item.label">{{item.label}}</div>
        
                    <continuation-indicator [isActive]="selectedItem?.label === item.label"></continuation-indicator>
        
                </div>

            </ng-container>

            <ng-container *ngIf="checkableItemsList && checkableItemsList.length">

                <mat-selection-list class="filter-item-select-list" #selectList [(ngModel)]="selectedCheckableItems" (selectionChange)="checkableItemsChanged($event)">
	
                    <mat-list-option checkboxPosition="before" *ngFor="let item of checkableItemsList; let idx = index; let first = first; let last = last;" [value]="item"
                                     class="select-list-item">
        
                            <div class="flex-1 truncate text-base md:text-sm text-gray-900 font-medium" [title]="item.label">{{item.label}}</div>
    
                            <!-- <continuation-indicator [isActive]="selectedFacetOption && selectedFacetOption.label === facet.label"
                                                    [facet]="facet"
                                                    [parentData]="facetData">
                            </continuation-indicator> -->
        
                    </mat-list-option>
        
                </mat-selection-list>

            </ng-container>
    
        </div>

    </div>

</ng-container>

<ng-template #noData>

    <div class="w-full h-full flex items-center justify-items-center">
        No Facet Data!!
    </div>

</ng-template>

