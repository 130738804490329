<spinner-overlay></spinner-overlay>
<div class="app flex-row align-items-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="sdcard-group mb-0 mat-elevation-z4">
          <div class="card p-4 login-card">
            <div class="card-block">
              <h2 class="text-2xl font-semibold mt-4 mb-2">{{environment.appName}} Login</h2>
              <p class="text-muted">Sign In to your account</p>
              <form #loginForm="ngForm" (ngSubmit)="login()" novalidate class="form-horizontal">
                <div class="input-group mb-3">
                  <span class="input-group-addon"><i class="icon-user"></i></span>
                  <input type="text" class="form-control" placeholder="Username"
                         name="userName" [(ngModel)]="userName">
                </div>
                <div class="input-group mb-3">
                  <span class="input-group-addon"><i class="icon-lock"></i></span>
                  <input type="password" class="form-control" placeholder="Password"
                         name="password" [(ngModel)]="password">
                </div>
                <div class="input-group mb-4">
                  <mat-checkbox name="rememberMe" [(ngModel)]="rememberMe">Remember Me</mat-checkbox>
                </div>
                <div class="row">
                  <div class="col-12" *ngIf="message">
                    <div class="alert alert-danger">
                      {{message}}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <button mat-raised-button type="submit" class="btn btn-primary px-4"
                            [disabled]="!userName || !password || forceLoginDisable">
                      Login
                    </button>
                  </div>
                  <div class="col-6">
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="card d-md-down-none pr-1 logo-holder" style="width:44%">
            <div class="login-brand-logo"></div>
            <div class="login-logo"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
