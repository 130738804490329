import {ActionReducer, Action} from '@ngrx/store';
import {LocalStorageService, ReportViewFiltersService} from '../_shared/services/services-index';
import { AppState } from './app-state.model';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

export function storageMetaReducer<S, A extends Action = Action>(saveKeys: string[],
                                                                  localStorageKey: string,
                                                                  storageService: LocalStorageService,
                                                                  reportViewFiltersService: ReportViewFiltersService,
                                                                  router: Router,
                                                                 ) {
  let onInit = true; // after load/refresh…
  return function(reducer: ActionReducer<S, A>) {
    return function(state: S, action: A): S {
      // get the next state.
      const nextState = reducer(state, action);
      // init the application state.
      if (onInit) {
        onInit           = false;
        const savedState = storageService.getSavedState(localStorageKey);
        if(!savedState) {
          // user enters the site for the first time or cleared the browser storage.
          // they will be redirected to login page by auth guard so let's just proceed.
          return nextState;
        }
        // TODO: Remove this if block after all clients and users have the new version of the app
        // set locally on 8/6/2024
        if(savedState.filters?.reportViewFilters?.length > 0) {
          // it means app is still using the old version 
          // when reportViewFilters property was not saved in the indexDB
          
          // force the user to logout and login again to clear the cache
          localStorage.removeItem('user');
          !!environment.loginRedirectUrl ? window.location.href = environment.loginRedirectUrl : router.navigateByUrl('/login');

          return nextState;
        }
        // Get reportViewFilters from indexdb through signal's value
        if(savedState.filters) {
          savedState.filters.reportViewFilters = reportViewFiltersService.reportViewFilters();
        }
        return {...nextState, ...savedState};
      }

      // save the next state to the application storage.
      const stateToSave = saveKeys.reduce((current: any, pick: string) => {
        if (pick === 'filters' && nextState[pick]) {
          // Exclude reportViewFilters from being saved into the localStorage.
          const { reportViewFilters, ...filtersWithoutReportViewFilters } = nextState[pick];
          current[pick] = filtersWithoutReportViewFilters;
        } else {
          current[pick] = nextState[pick];
        }
        return current;
      }, {});

      storageService.setSavedState(stateToSave, localStorageKey);

      // save reportViewFilters to IndexedDB
      reportViewFiltersService.updateReportViewFilters(nextState['filters'].reportViewFilters);

      return nextState;
    };
  };
}
