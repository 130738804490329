import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import * as Models from '../../../models/models-index';
import { HelpTextService } from '../../../services/help/helpText.service';
import { ElementBaseComponent } from '../ElementBaseComponent';

type MetricOutput = { "label": string, "value": string | number | Date, "trend": number, "subtitleOneValue": string | number | Date, "subtitleOneLabel" };

@Component({
  selector: 'tri-metric',
  templateUrl: './tri-metric.component.html',
  styleUrls: ['./tri-metric.component.scss']
})
export class TriMetricComponent extends ElementBaseComponent implements OnInit {

  @Input() dataSet: Models.DataSet;
  @Input() settings: Models.ElementSettings;
  @Input() locale: string;

  title;
  titleIconClasses;

  metrics: MetricOutput[] = [];

  constructor(helpTextService: HelpTextService) {
    super(helpTextService);
  }

  ngOnInit() {

    const row = this.dataSet.rows[0];

    if (!this.settings.metrics || this.settings.metrics.length === 0) {
      throw new Error("No metrics defined");
    }

    this.settings.metrics.forEach(metric => {
      const metricIndex = this.dataSet.columns.findIndex(col => col.name === metric.name);
      const metricColumn = this.dataSet.columns[metricIndex];
      const metricLabel = metricColumn.displayName ?? metricColumn.name;
      const metricPreviousPeriodIndex = this.dataSet.columns.findIndex(col => col.name === metric.previousPeriod);
      const metricIndexPOP = this.dataSet.columns.findIndex(col => col.name === metric.pop);

      const subtitleOneValue = row[metricPreviousPeriodIndex]?.label ?? row[metricPreviousPeriodIndex]?.value;
      const subtitleOneLabel = "Prev. Period:";
      this.metrics.push({ "label": metricLabel, "value": row[metricIndex].label ?? row[metricIndex].value, "trend": this.dataSet.rows[0][metricIndexPOP]?.value as number,"subtitleOneValue": subtitleOneValue, "subtitleOneLabel": subtitleOneLabel });
    });

    this.title = this.settings.title;
    this.titleIconClasses = this.settings.titleIconClasses;
  }

}
