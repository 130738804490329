import { ISaleType, ReportRequestModel } from './../../models/models';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Enums, IEnums } from '../../enums/enums';
import * as Models from '../../models/models-index';
import { catchError } from 'rxjs/operators';
import { AppInsightsService } from '../appInsights/appInsights.service';
import { throwError, Observable } from 'rxjs';

@Injectable()
export class ResourceService {
  constructor(
    private http: HttpClient,
    private appInsights: AppInsightsService) { }

  getResources(): Observable<Models.FilesInSectionsModel[]> {
    return this.http.get<Models.FilesInSectionsModel[]>(
      environment.baseV5ApiUri + '/content/filelist',
    )
      .pipe(
        catchError(this.handleError));
  }

    private handleError(err: any) {
        let errorMessage: string;
        if (!!err.error && err.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = `Backend returned code ${err?.status}: ${err?.body?.error}`;
        }
        this.appInsights.trackException(err);
        console.error(err);
        return throwError(() => errorMessage);
    }
}
