import {
  IDigAdOverviewBillboardConfig,
  IDigAdOverviewConfig,
  IDefaultTableColumnConfig,
  IDigAdOverviewKpiConfig,
  ICardHeaderConfig,
  IDigAdOverviewChannelCardConfig,
  IDigAdOverviewTrafficTypeCardConfig
} from '../../../../_shared/models/models-index';

import * as constants from '../../../../_shared/constants/constants';
export class DigAdOverviewConfig implements IDigAdOverviewConfig {

  public reportTitle: string = 'Digital Advertising Overview';
  public chartSeriesColors = ['#000', '#737373', '#A6A6A6', '#D9D9D9', '#CCCCCC'];

  public providerPerformanceCardHeader: ICardHeaderConfig = {
    title: 'Provider Performance',
    iconClass: 'fa-tasks',
    helpTextKey: constants.helpTextKeys.digAdOverviewProviderPerformanceHelp,
    helpTextTitle: 'Provider Performance',
  };
  public dealerPerformanceCardHeader: ICardHeaderConfig = {
    title: 'Dealer Performance',
    iconClass: 'fa-suitcase',
    helpTextKey: constants.helpTextKeys.digAdOverviewDealerPerformanceHelp,
    helpTextTitle: 'Dealer Performance',
  };
  public trafficTrendCardHeader: ICardHeaderConfig = {
    title: 'Traffic Trend',
    iconClass: 'fa-bar-chart',
    helpTextKey: constants.helpTextKeys.digAdOverviewTrafficTrendHelp,
    helpTextTitle: 'Traffic Trend',
  };
  public channelPerformanceCardHeader: ICardHeaderConfig = {
    title: 'Channel Performance',
    iconClass: 'fa-adjust',
    helpTextKey: constants.helpTextKeys.digAdOverviewChannelPerformanceHelp,
    helpTextTitle: 'Channel Performance',
  };
  public trafficTypeCardHeader: ICardHeaderConfig = {
    title: 'Organic vs Paid',
    iconClass: 'fa-adjust',
    helpTextKey: constants.helpTextKeys.digAdOverviewTrafficTypeHelp,
    helpTextTitle: 'Traffic Type',
  };

  public channelCardConfig: IDigAdOverviewChannelCardConfig = {
    channelColors: ['#c51230', '#9e2a75', '#5f1675', '#6b45cc', '#0f4ec4', '#266886', '#369e8d', '#209645', '#587e35', '#ccc145', '#cc8b45', '#666666'],
    dimensionPropertyName: 'channelName',
    primaryMetricPropertyName: 'totalVisits',
    secondaryMetricPropertyName: 'engagedVisits'
  };

  public trafficTypeCardConfig: IDigAdOverviewTrafficTypeCardConfig = {
    headerConfig: {
      title: 'Organic vs Paid',
      iconClass: 'fa-adjust',
      helpTextKey: constants.helpTextKeys.digAdOverviewTrafficTypeHelp,
      helpTextTitle: 'Traffic Type',
    },
    chartConfig: {
      colors: ['#000', '#737373'],
      paidPropertyName: 'paidVisits',
      organicPropertyName: 'organicVisits'
    }
  }

  public billboards: IDigAdOverviewBillboardConfig[] = [
    {
      title: 'Impressions',
      subtitle: null,
      subtitleOne: 'impressionsPreviousMTD',
      subtitleTwo: 'impressionsPreviousMonth',
      iconClass: 'fa-eye fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'impressions',
      metricMOMPropertyName: 'impressionsMOM',
      metricYOYPropertyName: 'impressionsYOY',
      metricPreviousMonthPropertyName: 'impressionsPreviousMonth',
      metricPreviousMTDPropertyName: 'impressionsPreviousMTD',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      metricPreviousYearPropertyName: null,
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      trendArrowDrivingPropertyName: 'impressionsMOM',
      metricFormatKey: constants.formatKeys.localeStringOrNa,
      helpTextKey: 'DigAdOverviewImpressionsBillboardHelp',
      helpTextTitle: 'Impressions',
      showTrendArrow: true
    },
    {
      title: 'Total Visits',
      subtitle: null,
      subtitleOne: 'visitsPreviousMTD',
      subtitleTwo: 'visitsPreviousMonth',
      iconClass: 'fa-users fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'visits',
      metricMOMPropertyName: 'visitsMOM',
      metricYOYPropertyName: 'visitsYOY',
      metricPreviousMonthPropertyName: 'visitsPreviousMonth',
      metricPreviousMTDPropertyName: 'visitsPreviousMTD',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      metricPreviousYearPropertyName: null,
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      trendArrowDrivingPropertyName: 'visitsMOM',
      metricFormatKey: constants.formatKeys.localeStringOrNa,
      helpTextKey: 'DigAdOverviewTotalVisitsBillboardHelp',
      helpTextTitle: 'Total Visits',
      showTrendArrow: true
    },
    {
      title: 'Engagements',
      subtitle: null,
      subtitleOne: 'engagementsPreviousMTD',
      subtitleTwo: 'engagementsPreviousMonth',
      iconClass: 'fa-user-plus fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'engagements',
      metricMOMPropertyName: 'engagementsMOM',
      metricYOYPropertyName: 'engagementsYOY',
      metricPreviousMonthPropertyName: 'engagementsPreviousMonth',
      metricPreviousMTDPropertyName: 'engagementsPreviousMTD',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      metricPreviousYearPropertyName: null,
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      trendArrowDrivingPropertyName: 'engagementsMOM',
      metricFormatKey: constants.formatKeys.localeStringOrNa,
      helpTextKey: 'DigAdOverviewEngagementsBillboardHelp',
      helpTextTitle: 'Engagements',
      showTrendArrow: true
    },
    {
      title: 'Actions',
      subtitle: null,
      subtitleOne: 'actionsPreviousMTD',
      subtitleTwo: 'actionsPreviousMonth',
      iconClass: 'fa-mouse-pointer fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'actions',
      metricMOMPropertyName: 'actionsMOM',
      metricYOYPropertyName: 'actionsYOY',
      metricPreviousMonthPropertyName: 'actionsPreviousMonth',
      metricPreviousMTDPropertyName: 'actionsPreviousMTD',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      metricPreviousYearPropertyName: null,
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      trendArrowDrivingPropertyName: 'actionsMOM',
      metricFormatKey: constants.formatKeys.localeStringOrNa,
      helpTextKey: 'DigAdOverviewActionsBillboardHelp',
      helpTextTitle: 'Actions',
      showTrendArrow: true
    },
  ];

  public kpiSheetOptions = {
    overview: [{
      name: 'name',
      label: '',
      isHeader: true
    }, {
      name: 'visits',
      label: 'Visits',
      format: constants.formatKeys.localeStringOrNa
    }, {
      name: 'vdps',
      label: 'VDPs',
      format: constants.formatKeys.localeStringOrNa
    }, {
      name: 'engagementRate',
      label: 'Engagement Rate',
      format: constants.formatKeys.percentageTwoDecimals
    }, {
      name: 'bounceRate',
      label: 'Bounce Rate',
      format: constants.formatKeys.percentageTwoDecimals
    }, {
      name: 'salesServiceOtherBounces',
      label: 'Sales | Service | Other | Bounces',      
    }]
  };

  public dealerBenchmarkColumns: IDefaultTableColumnConfig[] = [
    {
      show: true,
      header: '',
      columnDef: 'entity',
      metricFormatKey: constants.formatKeys.entityDisplayName,
      clickable: true
    },
    {
      show: false,
      print: true,
      header: 'Dealer Name',
      columnDef: 'displayName',
      metricFormatKey: constants.formatKeys.entityDisplayName,
      clickable: false
    },
    // Engagement Rate
    {
      show: true,
      header: 'Engagement Rate',
      columnDef: 'engagementRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Action Rate
    {
      show: true,
      header: 'Action Rate',
      columnDef: 'actionRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false,
      hasComparisonMetrics: true
    },
    //Page Views
    {
      show: true,
      header: 'Page Views',
      columnDef: 'pageViews',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    //VDPs
    {
      show: true,
      header: 'VDPs',
      columnDef: 'vdps',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    //Used VDPs
    {
      show: true,
      header: 'Used VDPs',
      columnDef: 'usedVdps',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    //Service Views
    {
      show: true,
      header: 'Service Views',
      columnDef: 'serviceViews',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    //Avg Time On Site
    {
      show: true,
      header: 'Avg Time On Site',
      columnDef: 'averageTimeOnSite',
      metricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds,
      clickable: false,
      hasComparisonMetrics: true
    },
    //Bounce Rate
    {
      show: true,
      header: 'Bounce Rate',
      columnDef: 'bounceRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false,
      hasComparisonMetrics: true
    },
  ];
  public dealerBenchmarkLookbackColumns?: IDefaultTableColumnConfig[] = [];
  public kpiCardConfig: IDigAdOverviewKpiConfig = {
    chartTitle: 'Total Visits vs. Engaged Visits',
    preCutoffChartTitle: 'Total Visits vs. Unique Visits',
    xAxisPropertyName: 'date',
    metrics: {
      'MetricOne': {
          yAxisTitle: 'Visits',
          displayName: 'Total Visits',
          pointLabelFormat: null,
          propertyName: 'totalVisits',
          metricFormatKey: null,
          formatter: null
      },
      'MetricTwo': {
          yAxisTitle: 'Visits',
          displayName: 'Engaged Visits',
          pointLabelFormat: null,
          propertyName: 'engagedVisits',
          metricFormatKey: null,
          formatter: null
      },
      'PreCutoffMetricTwo': {
          yAxisTitle: 'Visits',
          displayName: 'Unique Visits',
          pointLabelFormat: null,
          propertyName: 'uniqueVisits',
          metricFormatKey: null,
          formatter: null
      },
      'PreCutoffMetricOne': {
        yAxisTitle: 'Visits',
        displayName: 'Total Visits',
        pointLabelFormat: null,
        propertyName: 'totalVisits',
        metricFormatKey: null,
        formatter: null
      }
    }
  };
}
