import { Enums, DateMonthModes } from '../../_shared/enums/enums';
import { environment } from '../../../environments/environment';
import { IFilterConfig } from '../../_shared/models/models-index';
import { Filter, FilterConfigReport, FilterType } from '../../_shared/filter/filter.model';
import * as constants from '../../_shared/constants/constants';
export class FilterConfig implements IFilterConfig {
    NUMBER_OF_MONTHS_TO_SHOW_DEFAULT = environment.numberOfMonthsToShowDefault;
    NUMBER_OF_QUARTERS_TO_SHOW_DEFAULT = environment.numberOfQuartersToShowDefault;
    showEntireOrgHierarchyForAllUsers = false;
    includeInactiveDealersInMetrics = true;
    defaultMonthDateMode: DateMonthModes = 'calendar';
    filterTypeLookup: { [id: string]: FilterType } = {
        'org': { displayName: 'Hierarchy', name: 'org' },
        'vehicle': { displayName: 'Vehicle', name: 'vehicle' },
        'chat-provider': { displayName: 'Provider', name: 'chat-provider' },
        'device-type': { displayName: 'Device Type', name: 'device-type' },
        'lead-type': { displayName: 'Lead Type', name: 'lead-type' },
        'sale-type': { displayName: 'Sale Type', name: 'sale-type' },
        'source-type': { displayName: 'Source Type', name: 'source-type' },
        'vehicle-make': { displayName: 'Make', name: 'vehicle-make' },
        'vehicle-model': { displayName: 'Model', name: 'vehicle-model' },
        'website-provider': { displayName: 'Provider', name: 'website-provider' },
        'channel': { displayName: 'Channel', name: 'channel' },
        'digad-provider': { displayName: 'Provider', name: 'digad-provider' },
        'digital-retailing-provider': { displayName: 'Provider', name: 'digital-retailing-provider' },
    };

    filterReportConfigs: { [id: string]: FilterConfigReport } = {
        // Sales
        'SalesOverview': {
          dateMode: Enums.dateModes.currentMonth,
          restrictDates: true,
          reportName: constants.reportNames.salesOverview,
          filters: ['org'],
          defaults: ['org'],
          section: 'sales',
          orgTypes: [1],   },
        'SalesSummary': {
          dateMode: Enums.dateModes.currentMonth,
          reportName: constants.reportNames.salesSummary,
          filters: [
            'org', 'sale-type', 'lead-type', 'source-type'
          ],
          defaults: [
            'org', 'sale-type', 'lead-type', 'source-type'
          ],
          section: 'sales',
          orgTypes: [1],     },
        'SalesVehicleSummary': {
          dateMode: Enums.dateModes.currentMonth,
          reportName: constants.reportNames.salesVehicleSummary,
          filters: [
            'org', 'sale-type', 'lead-type', 'source-type'
          ],
          defaults: [
            'org', 'sale-type', 'lead-type', 'source-type'
          ],
          section: 'sales',
          orgTypes: [1],   },
        // Website
        'WebsiteOverview': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            reportName: constants.reportNames.websiteOverview,
            filters: ['org'],
            defaults: ['org'],
            section: 'website',
            orgTypes: [1]
        },
        'WebsiteScorecard': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            reportName: constants.reportNames.websiteScorecard,
            filters: [
              'org', 'website-provider'
            ],
            defaults: [
              'org'
            ],
            section: 'website',
            orgTypes: [1]
        },
        'WebsiteSummary': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.websiteSummary,
            filters: [
                'org', 'website-provider'
            ],
            defaults: [
                'org'
            ],
            section: 'website',
            orgTypes: [1]
        },
        'WebsiteReferrerSummary': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.websiteReferrerSummary,
            filters: [
                'org', 'device-type'
            ],
            defaults: [
                'org', 'device-type'
            ],
            section: 'website',
            orgTypes: [1]
        },
        //'WebsiteVehicleSummary': {
        //    dateMode: Enums.dateModes.currentMonth,
        //    reportName: 'WebsiteVehicleSummary',
        //    filters: [
        //        'org', 'website-provider'
        //    ],
        //    defaults: [
        //        'org', 'website-provider'
        //    ],
        //    section: 'website',
        //    orgTypes: [1]
        //},
        'WebsiteProviderSummary': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.websiteProviderSummary, // org only
            filters: ['org'],
            defaults: ['org'],
            section: 'website',
            orgTypes: [1]
        },
        // DigAd
        'DigitalAdvertisingOverview': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            reportName: constants.reportNames.digitalAdvertisingOverview,
            filters: ['org', 'channel'],
            defaults: ['org', 'channel'],
            section: 'digital-advertising',
            orgTypes: [1]
        },
        'DigitalAdvertisingScorecard': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            reportName: constants.reportNames.digitalAdvertisingScorecard,
            filters: ['org', 'channel', 'digad-provider'],
            defaults: ['org', 'channel'],
            section: 'digital-advertising',
            orgTypes: [1]
        },
        'DigitalAdvertisingSummary': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.digitalAdvertisingSummary,
            filters: ['org', 'channel', 'digad-provider'],
            defaults: ['org', 'channel'],
            section: 'digital-advertising',
            orgTypes: [1]
        },
        'DigitalAdvertisingProvider': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.digitalAdvertisingProvider,
            filters: ['org', 'channel'],
            defaults: ['org', 'channel'],
            section: 'digital-advertising',
            orgTypes: [1]
        },
        'DigitalAdvertisingChannel': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.digitalAdvertisingChannel,
            filters: ['org', 'digad-provider'],
            defaults: ['org'],
            section: 'digital-advertising',
            orgTypes: [1]
        },
    };

    FILTER_CONFIG: { [id: string]: Filter } = {
        'org': {
            type: 'org',
            selected: [],
            removable: false,
            lockable: true,
            locked: true
        },
        'vehicle': {
            type: 'vehicle',
            selected: [],
            removable: false,
            lockable: true,
            locked: true
        },
        'chat-provider': {
            type: 'chat-provider',
            available: [2,12,15,16],
            selected: [
                { value: 15, display: 'ActiveEngage' },
                { value: 16, display: 'CarChat24' },
                { value: 12, display: 'CarNow' },
                { value: 2, display: 'Conversations' }
            ],
            removable: false,
            lockable: true,
            locked: false
        },
        'device-type': {
            type: 'device-type',
            selected: [
                { value: 1, display: 'Desktop' },
                { value: 2, display: 'Tablet' },
                { value: 3, display: 'Phone' }
            ],
            removable: false,
            lockable: true,
            locked: false
        },
        'lead-type': {
            type: 'lead-type',
            available: [1,2,3,4,5],
            selected: [{ value: 1, display: 'New' }],
            removable: false,
            lockable: true,
            locked: false
        },
        'sale-type': {
            type: 'sale-type',
            available: [1,3],
            selected: [
              { value: 1, display: 'New' }],
            removable: false,
            lockable: true,
            locked: false
        },
        'source-type': {
            type: 'source-type',
            available: [3],
            selected: [
                { value: 3, display: 'Retailer Website'}
            ],
            removable: false,
            lockable: true,
            locked: false
        },
        'vehicle-make': {
            type: 'vehicle-make',
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'vehicle-class': {
            type: 'vehicle-class',
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'vehicle-model': {
            type: 'vehicle-model',
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'website-provider': {
            type: 'website-provider',
            available: [48,2,49,51,52],
            selected: [
              { value: 48, display: 'eDealer' },
              { value: 2, display: 'Dealer Inspire' },
              { value: 49, display: 'SM360' },
              { value: 51, display: 'Magnetis' },
              { value: 52, display: 'D2C Media' },
            ],
            removable: true,
            lockable: true,
            locked: false
        },
        'channel': {
            type: 'channel',
            available: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            selected: [
                { value: 1, display: 'Brand' },
                { value: 2, display: 'Paid Search' },
                { value: 3, display: 'Organic Search' },
                { value: 4, display: 'Paid Display' },
                { value: 5, display: 'Paid Video' },
                { value: 6, display: 'Paid Email' },
                { value: 7, display: 'Organic Email' },
                { value: 8, display: 'Paid Social' },
                { value: 9, display: 'Organic Social' },
                { value: 10, display: 'Third Party Sites' },
                { value: 11, display: 'Typed/Bookmarked' },
                { value: 12, display: 'Other' },
            ],
            removable: true,
            lockable: true,
            locked: false
        },
      'digad-provider': {
        type: 'digad-provider',
        available: [48, 2, 49, 51, 52],
        selected: [
          { value: 48, display: 'eDealer' },
          { value: 2, display: 'Dealer Inspire' },
          { value: 49, display: 'SM360' },
          { value: 51, display: 'Magnetis' },
          { value: 52, display: 'D2C Media' },
        ],
        removable: true,
        lockable: true,
        locked: false
      },
    };
}
