import {
    IDigAdScorecardConfig, IDigAdScorecardCategoryConfig } from '../../../../_shared/models/models-index';
import * as constants from '../../../../_shared/constants/constants';

export class DigAdScorecardConfig implements IDigAdScorecardConfig {
    public reportTitle = 'Digital Advertising Scorecard';
    public scorecards: IDigAdScorecardCategoryConfig[] = [
        {
            category: 'Awareness',
            billboards: [
                {
                    title: 'Impressions',
                    subtitle: '',
                    iconClass: 'fa-users fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'impressions',
                    metricMOMPropertyName: 'impressionsMOM',
                    metricYOYPropertyName: 'impressionsYOY',
                    metricPreviousMonthPropertyName: 'impressionsPreviousMonth',
                    metricPreviousMTDPropertyName: 'impressionsPreviousMTD',
                    metricPreviousYearPropertyName: 'impressionsPreviousYear',
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'impressionsMOM',
                    metricFormatKey: constants.formatKeys.localeStringOrNa,
                    subtitleMetricFormatKey: constants.formatKeys.localeStringOrNa,
                    helpTextKey: 'DigAdScorecardImpressionsHelp',
                    helpTextTitle: 'Impressions',
                    scoreCardCategory: 'Awareness'
                },
                {
                    title: 'Click Through Rate',
                    subtitle: '',
                    iconClass: 'fa-percent fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'clickThroughRate',
                    metricMOMPropertyName: 'clickThroughRateMOM',
                    metricYOYPropertyName: 'clickThroughRateYOY',
                    metricPreviousMonthPropertyName: 'clickThroughRatePreviousMonth',
                    metricPreviousMTDPropertyName: 'clickThroughRatePreviousMTD',
                    metricPreviousYearPropertyName: 'clickThroughRatePreviousYear',
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    subtitleMetricFormatKey: constants.formatKeys.percentageNoDecimals,
                    trendArrowDrivingPropertyName: 'clickThroughRateMOM',
                    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    helpTextKey: 'DigAdScorecardClickThroughRateHelp',
                    helpTextTitle: 'Click Through Rate',
                    scoreCardCategory: 'Awareness'
                },
                {
                    title: 'Cost Per Impression',
                    subtitle: '',
                    iconClass: 'fa-dollar fa-primary',
                    reverseMetric: true,
                    metricCurrentPropertyName: 'actualCostPerImpression',
                    metricMOMPropertyName: 'actualCostPerImpressionMOM',
                    metricYOYPropertyName: 'actualCostPerImpressionYOY',
                    metricPreviousMonthPropertyName: 'actualCostPerImpressionPreviousMonth',
                    metricPreviousMTDPropertyName: 'actualCostPerImpressionPreviousMTD',
                    metricPreviousYearPropertyName: 'actualCostPerImpressionPreviousYear',
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'actualCostPerImpressionMOM',
                    metricFormatKey: constants.formatKeys.currency,
                    subtitleMetricFormatKey: constants.formatKeys.currency,
                    helpTextKey: 'DigAdScorecardCostPerImpressionHelp',
                    helpTextTitle: 'Cost Per Impression',
                    scoreCardCategory: 'Awareness'
                }
            ],
        },
        {
            category: 'Traffic',
            billboards: [
                {
                    title: 'Visits',
                    subtitle: '',
                    iconClass: 'fa-users fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'totalVisits',
                    metricMOMPropertyName: 'totalVisitsMOM',
                    metricYOYPropertyName: 'averageTimeOnSiteYOY',
                    metricPreviousMonthPropertyName: 'totalVisitsPreviousMonth',
                    metricPreviousMTDPropertyName: 'totalVisitsPreviousMTD',
                    metricPreviousYearPropertyName: 'totalVisitsPreviousYear',
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'totalVisitsMOM',
                    metricFormatKey: constants.formatKeys.localeStringOrNa,
                    subtitleMetricFormatKey: constants.formatKeys.localeStringOrNa,
                    helpTextKey: 'DigAdScorecardVisitsHelp',
                    helpTextTitle: 'Visits',
                    scoreCardCategory: 'Traffic'
                },
                {
                    title: 'Unique Visits',
                    subtitle: '',
                    iconClass: 'fa-user fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'uniqueVisits',
                    metricMOMPropertyName: 'uniqueVisitsMOM',
                    metricYOYPropertyName: 'uniqueVisitsYOY',
                    metricPreviousMonthPropertyName: 'uniqueVisitsPreviousMonth',
                    metricPreviousMTDPropertyName: 'uniqueVisitsPreviousMTD',
                    metricPreviousYearPropertyName: 'uniqueVisitsPreviousYear',
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'uniqueVisitsMOM',
                    metricFormatKey: constants.formatKeys.localeStringOrNa,
                    subtitleMetricFormatKey: constants.formatKeys.localeStringOrNa,
                    helpTextKey: 'DigAdScorecardUniqueVisitsHelp',
                    helpTextTitle: 'Unique Visits',
                    scoreCardCategory: 'Traffic'
                },
                {
                    title: 'Page Views',
                    subtitle: '',
                    iconClass: 'fa-eye fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'pageViews',
                    metricMOMPropertyName: 'pageViewsMOM',
                    metricYOYPropertyName: 'pageViewsYOY',
                    metricPreviousMonthPropertyName: 'pageViewsPreviousMonth',
                    metricPreviousMTDPropertyName: 'pageViewsPreviousMTD',
                    metricPreviousYearPropertyName: 'pageViewsPreviousYear',
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'pageViewsMOM',
                    metricFormatKey: constants.formatKeys.localeStringOrNa,
                    subtitleMetricFormatKey: constants.formatKeys.localeStringOrNa,
                    helpTextKey: 'DigAdScorecardPageViewsHelp',
                    helpTextTitle: 'Page Views',
                    scoreCardCategory: 'Traffic'
                },
                {
                    title: 'Cost Per Click',
                    subtitle: '',
                    iconClass: 'fa-dollar fa-primary',
                    reverseMetric: true,
                    metricCurrentPropertyName: 'actualCostPerClick',
                    metricMOMPropertyName: 'actualCostPerClickMOM',
                    metricYOYPropertyName: 'actualCostPerClickYOY',
                    metricPreviousMonthPropertyName: 'actualCostPerClickPreviousMonth',
                    metricPreviousMTDPropertyName: 'actualCostPerClickPreviousMTD',
                    metricPreviousYearPropertyName: 'actualCostPerClickPreviousYear',
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'actualCostPerClickMOM',
                    metricFormatKey: constants.formatKeys.currency,
                    subtitleMetricFormatKey: constants.formatKeys.currency,
                    helpTextKey: 'DigAdScorecardCostPerClickHelp',
                    helpTextTitle: 'Cost Per Click',
                    scoreCardCategory: 'Traffic'
                },
            ]
        },
        {
            category: 'Engagement',
            billboards: [
                {
                    title: 'Time On Site',
                    subtitle: '',
                    iconClass: 'fa-clock-o fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'avgTimeOnSite',
                    metricMOMPropertyName: 'avgTimeOnSiteMOM',
                    metricYOYPropertyName: 'avgTimeOnSiteYOY',
                    metricPreviousMonthPropertyName: 'avgTimeOnSitePreviousMonth',
                    metricPreviousMTDPropertyName: 'avgTimeOnSitePreviousMTD',
                    metricPreviousYearPropertyName: 'avgTimeOnSitePreviousYear',
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'avgTimeOnSiteMOM',
                    metricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds,
                    subtitleMetricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds,
                    helpTextKey: 'DigAdScorecardTimeOnSiteHelp',
                    helpTextTitle: 'Time On Site',
                    scoreCardCategory: 'Engagement'
                },
                {
                    title: 'VDP Views',
                    subtitle: '',
                    iconClass: 'fa-eye fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'vdpViews',
                    metricMOMPropertyName: 'vdpViewsMOM',
                    metricYOYPropertyName: 'vdpViewsYOY',
                    metricPreviousMonthPropertyName: 'vdpViewsPreviousMonth',
                    metricPreviousMTDPropertyName: 'vdpViewsPreviousMTD',
                    metricPreviousYearPropertyName: 'vdpViewsPreviousYear',
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    subtitleMetricFormatKey: constants.formatKeys.localeStringOrNa,
                    trendArrowDrivingPropertyName: 'vdpViewsMOM',
                    metricFormatKey: constants.formatKeys.localeStringOrNa,
                    helpTextKey: 'DigAdScorecardVdpViewsHelp',
                    helpTextTitle: 'VDP Views',
                    scoreCardCategory: 'Engagement'
                },
                {
                    title: 'Engagement Rate',
                    subtitle: '',
                    iconClass: 'fa-percent fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'engagementRate',
                    metricMOMPropertyName: 'engagementRateMOM',
                    metricYOYPropertyName: 'engagementRateYOY',
                    metricPreviousMonthPropertyName: 'engagementRatePreviousMonth',
                    metricPreviousMTDPropertyName: 'engagementRatePreviousMTD',
                    metricPreviousYearPropertyName: 'engagementRatePreviousYear',
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    subtitleMetricFormatKey: constants.formatKeys.percentageNoDecimals,
                    trendArrowDrivingPropertyName: 'engagementRateMOM',
                    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    helpTextKey: 'DigAdScorecardEngagementRatesHelp',
                    helpTextTitle: 'Engagement Rate',
                    scoreCardCategory: 'Engagement'
                },
            ]
        },
        {
            category: 'Actions',
            billboards: [
                {
                    title: 'Form Submissions',
                    subtitle: '',
                    iconClass: 'fa-file-text fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'formSubmissions',
                    metricMOMPropertyName: 'formSubmissionsMOM',
                    metricYOYPropertyName: 'formSubmissionsYOY',
                    metricPreviousMonthPropertyName: 'formSubmissionsPreviousMonth',
                    metricPreviousMTDPropertyName: 'formSubmissionsPreviousMTD',
                    metricPreviousYearPropertyName: 'formSubmissionsPreviousYear',
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'formSubmissionsMOM',
                    metricFormatKey: constants.formatKeys.localeStringOrNa,
                    subtitleMetricFormatKey: constants.formatKeys.localeStringOrNa,
                    helpTextKey: 'DigAdScorecardFormSubmissionsHelp',
                    helpTextTitle: 'Form Submissions',
                    scoreCardCategory: 'Actions'
                },
                {
                    title: 'Click to Calls',
                    subtitle: '',
                    iconClass: 'fa-phone fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'clickToCalls',
                    metricMOMPropertyName: 'clickToCallsMOM',
                    metricYOYPropertyName: 'clickToCallsYOY',
                    metricPreviousMonthPropertyName: 'clickToCallsPreviousMonth',
                    metricPreviousMTDPropertyName: 'clickToCallsPreviousMTD',
                    metricPreviousYearPropertyName: 'clickToCallsPreviousYear',
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'clickToCallsMOM',
                    metricFormatKey: constants.formatKeys.localeStringOrNa,
                    subtitleMetricFormatKey: constants.formatKeys.localeStringOrNa,
                    helpTextKey: 'DigAdScorecardClickToCallsHelp',
                    helpTextTitle: 'Click to Calls',
                    scoreCardCategory: 'Actions'
                },
                {
                    title: 'Phone Calls',
                    subtitle: '',
                    iconClass: 'fa-phone fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'phoneCalls',
                    metricMOMPropertyName: 'phoneCallsMOM',
                    metricYOYPropertyName: 'phoneCallsYOY',
                    metricPreviousMonthPropertyName: 'phoneCallsPreviousMonth',
                    metricPreviousMTDPropertyName: 'phoneCallsPreviousMTD',
                    metricPreviousYearPropertyName: 'phoneCallsPreviousYear',
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'phoneCallsMOM',
                    metricFormatKey: constants.formatKeys.localeStringOrNa,
                    subtitleMetricFormatKey: constants.formatKeys.localeStringOrNa,
                    helpTextKey: 'DigAdScorecardPhoneCallsHelp',
                    helpTextTitle: 'Phone Calls',
                    scoreCardCategory: 'Actions'
                },
                {
                    title: 'Action Rate',
                    subtitle: '',
                    iconClass: 'fa-percent fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'actionRate',
                    metricMOMPropertyName: 'actionRateMOM',
                    metricYOYPropertyName: 'actionRateYOY',
                    metricPreviousMonthPropertyName: 'actionRatePreviousMonth',
                    metricPreviousMTDPropertyName: 'actionRatePreviousMTD',
                    metricPreviousYearPropertyName: 'actionRatePreviousYear',
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'actionRateMOM',
                    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    subtitleMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    helpTextKey: 'DigAdScorecardActionRateHelp',
                    helpTextTitle: 'Action Rate',
                    scoreCardCategory: 'Actions'
                },
                {
                    title: 'Cost Per Conversion',
                    subtitle: '',
                    iconClass: 'fa-dollar fa-primary',
                    reverseMetric: true,
                    metricCurrentPropertyName: 'actualCostPerAcquisition',
                    metricMOMPropertyName: 'actualCostPerAcquisitionMOM',
                    metricYOYPropertyName: 'actualCostPerAcquisitionYOY',
                    metricPreviousMonthPropertyName: 'actualCostPerAcquisitionPreviousMonth',
                    metricPreviousMTDPropertyName: 'actualCostPerAcquisitionPreviousMTD',
                    metricPreviousYearPropertyName: 'actualCostPerAcquisitionPreviousYear',
                    footerLeftLabel: 'MOM',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'actualCostPerAcquisitionMOM',
                    metricFormatKey: constants.formatKeys.currency,
                    subtitleMetricFormatKey: constants.formatKeys.currency,
                    helpTextKey: 'DigAdScorecardCostPerConversionHelp',
                    helpTextTitle: 'Cost Per Conversion',
                    scoreCardCategory: 'Actions'
                },

            ]
        }
    ];
}
