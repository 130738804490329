import { AfterViewInit, Component, ComponentFactoryResolver, Injector, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import * as SvgResources from '../../lib/svg.resources';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FacetedFilterPanelSearch } from '../../services/faceted-filter-panel-search.service';
import { FilterPaneManagerService } from '../../services/filter-pane-manager.service';

import { FilterPanelRef } from '../../types/filter-panel-ref';

import { IFacetedFilterDataItem, IFacetedFilterSearchResult, IFacetedFilterSelection, IReplayItem } from '../../types/faceted-filter.types';
import { ReplayAction } from '../../Utils/replay-action';

@Component({
	templateUrl: './faceted-filter-panel.component.html',
	styleUrls: ['./faceted-filter-panel.component.scss'],
	providers: [FilterPaneManagerService]
})
export class FacetedFilterPanelComponent implements OnInit, AfterViewInit, OnDestroy {
	private _destroy$ = new Subject<boolean>();

	filterDataItems: IFacetedFilterDataItem[];
	selectedRootItem: IFacetedFilterDataItem;

	isSearching = false;
	searchResults$: Observable<IFacetedFilterSearchResult[]>;

	chevronIcon = SvgResources.sizableSvgIcon(SvgResources.svgIconTypes.chevronRight, 4);

	@ViewChild('paneComponentHost', {read: ViewContainerRef}) paneComponentHost: ViewContainerRef;

	constructor(private filterDataInjector: Injector,
				private paneManager: FilterPaneManagerService,
				private searchService: FacetedFilterPanelSearch,
				private filterPanelRef: FilterPanelRef,
        private resolver: ComponentFactoryResolver) {

	}

	ngOnInit(): void {
		this.filterPanelRef.config.inputData.pipe(
			takeUntil(this._destroy$)
		).subscribe((data: any) => {
			// console.log('FacetedFilterPanelComponent: ngOnInit: data: ', data);
			this.filterDataItems = [...data.inputData as IFacetedFilterDataItem[]];
		})

		this.searchService.searchState$.pipe(
			takeUntil(this._destroy$)
		).subscribe((state: any) => {
			this.paneManager.purgeAllLoadedComponents();
			this.isSearching = state.isSearching;
			if (!this.isSearching)
				this.selectedRootItem = undefined;
		})
		this.searchResults$ = this.searchService.searchResults$;
	}

	ngOnDestroy(): void {
		this._destroy$.next(true);
        this._destroy$.complete();
	}

	ngAfterViewInit(): void {
		this.checkServiceHost();
		setTimeout(() => {
			if (this.filterPanelRef.config.inputFacetData) {
				this.replaySelectionData(this.filterPanelRef.config.inputFacetData);
			}
		}, 1);
	}

	onRootItemSelected(item: IFacetedFilterDataItem): void {
		this.selectedRootItem = undefined;
		this.selectedRootItem = item;

		// this.checkServiceHost();

		this.paneManager.purgeAllLoadedComponents();
		this.paneManager.loadFilterPane(item);
	}

	onSearchResultItemSelected(result: IFacetedFilterSearchResult): void {
		this.searchService.clearSearch();

		// this.checkServiceHost();

		const pathItems: string[] = result.path.split('|');
		const parentItem = this.filterDataItems.find(f => f.label === pathItems[0]);

		pathItems.shift();

		this.selectedRootItem = undefined;
		this.selectedRootItem = parentItem;

		const replayItems: IReplayItem[] = ReplayAction.GetItemsToReplay(pathItems, parentItem);

		this.paneManager.replayResult(replayItems);
	}

	replaySelectionData(selection: IFacetedFilterSelection): void {
		// this.checkServiceHost();
		const pathItems: string[] = selection?.path.split('|') ?? [];
		const parentItem = this.filterDataItems.find(f => f.label === pathItems[0]);

		this.selectedRootItem = undefined;
		this.selectedRootItem = parentItem;

		pathItems.shift();

		//console.log('>>>>>>>>>>>>>FacetedFilterPanelComponent: replaySelectionData: selection: ', selection);

		const replayItems: IReplayItem[] = ReplayAction.GetItemsToReplay(pathItems, parentItem, selection.value);

		this.paneManager.replayResult(replayItems);
	}

	private checkServiceHost(): void {
		if (!this.paneManager.hasContainerRefHost())
			this.paneManager.setContainerRefHost(this.paneComponentHost, this.resolver);
	}
}
