export const environment = {
  production: false,
  hmr: false,
  debug: true,
  client: 'Mazda-CA',
  hostName: 'insight-staging.mazdadigitaldealer.ca',
  environmentName: 'staging',
  appName: 'Mazda-CA Analytics',
  ssoPassword: 'ShiftDigital#123',
  defaultChartColors: ['#737373', '#000', '#A6A6A6', '#D9D9D9', '#CCCCCC'],
  applicationInsights: {
    instrumentationKey: 'ede19668-30ca-4f91-9cbb-ba9c382590c6'
  },
  defaultLocale: 'en',
  availableLocales: ['en', 'fr-CA'],
  timeZone: 'Eastern Standard Time',
  numberOfMonthsToShowDefault: 13,
  numberOfQuartersToShowDefault: 8,
  defaultOrgLevelTypeIds: [1, 4],
  closeRateLookbackMonths: 3,
  baseApiUri: 'https://mazdaca-analytics-api-staging.azurewebsites.net/api',
  authUri: 'https://mazdaca-analytics-api-staging.azurewebsites.net/token',
  baseV5ApiUri: 'https://mazda-ca-analytics-apiv5-staging.azurewebsites.net/api',
  retailerToolsBaseUri: '',
  retailerToolsIncludeAuth: false,
  mysteryShopBaseUri: '',
  favIconPath: '/assets/icons/clients/mazda-ca/favicon.ico',
  loginRedirectUrl: '',
  logoutRedirectUrl: '',
  sidebarDefaultOpen: false,
  useV5WebsiteOverview: false,
  v5Reports: ['ServiceSchedulerOverview']
};
