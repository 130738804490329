<div class="pane-wrapper">
  <div class="pane bg-white border-r border-gray-300 scrollable-y scrollbar-thin scrollbar-thumb-primary-200 scrollbar-thumb-rounded-md scrollbar-track-primary-50 hover:scrollbar-thumb-primary-200">
    <div class="relative w-full h-10 flex items-center text-gray-900 bg-white hover:bg-white active:bg-white cursor-pointer border-b border-gray-300">
      <select [(ngModel)]="selectedOption" (ngModelChange)="onCalendarTypeChange($event)" class="w-full h-full focus:ring-0 p-0 pl-3 border-0 active:ring-0 text-gray-900 font-inter font-medium text-base md:text-sm cursor-pointer">
        <option *ngFor="let option of options" [ngValue]="option" class="text-gray-900 font-inter font-medium text-base md:text-sm" >
          {{ option.kind }}
        </option>
      </select>
    </div>
  </div>
</div>
