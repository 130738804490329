import {
    IDefaultTableColumnConfig,
    IMultiLevelDualAxisLineGraphConfig,
    IWebsiteReferrerConfig,
    ICardHeaderConfig } from '../../../../_shared/models/models-index';
 import { Enums } from '../../../../_shared/enums/enums';
 import * as constants from '../../../../_shared/constants/constants';
 
 export class WebsiteReferrerConfig implements IWebsiteReferrerConfig {
     reportTitle = 'Referrer Report';
    public benchmarkCardHeader: ICardHeaderConfig = {
       title: 'Summary Data',
       iconClass: 'fa-file',
       helpTextKey: constants.helpTextKeys.websiteReferrerSummaryHelp,
       helpTextTitle: 'Referrer Summary',
       exportName: 'Referrer Report - Summary Data',
       metricDisplayModes: ['MOM', 'YOY']
    };
 
     public graphConfig: IMultiLevelDualAxisLineGraphConfig = {
         xAxisPropertyName: 'date',
         parentMetrics: [
             {
                 id: Enums.websiteMetrics.engagements.metricId,
                 displayName: Enums.websiteMetrics.engagements.name,
                 propertyName: Enums.websiteMetrics.engagements.nameKey,
                 metricFormatKey: constants.formatKeys.localeString
             },
             {
                 id: Enums.websiteMetrics.engagementRate.metricId,
                 displayName: Enums.websiteMetrics.engagementRate.name,
                 propertyName: Enums.websiteMetrics.engagementRate.nameKey,
                 metricFormatKey: constants.formatKeys.localeString
             },
             {
                 id: Enums.websiteMetrics.actions.metricId,
                 displayName: Enums.websiteMetrics.actions.name,
                 propertyName: Enums.websiteMetrics.actions.nameKey,
                 metricFormatKey: constants.formatKeys.localeString
             },
             {
                 id: Enums.websiteMetrics.actionRate.metricId,
                 displayName: Enums.websiteMetrics.actionRate.name,
                 propertyName: Enums.websiteMetrics.actionRate.nameKey,
                 metricFormatKey: constants.formatKeys.localeString
             },
             {
                 id: Enums.websiteMetrics.totalVisits.metricId,
                 displayName: Enums.websiteMetrics.totalVisits.name,
                 propertyName: Enums.websiteMetrics.totalVisits.nameKey,
                 metricFormatKey: constants.formatKeys.localeString
             },
             {
                 id: Enums.websiteMetrics.uniqueVisits.metricId,
                 displayName: Enums.websiteMetrics.uniqueVisits.name,
                 propertyName: Enums.websiteMetrics.uniqueVisits.nameKey,
                 metricFormatKey: constants.formatKeys.localeString
             },
             {
                 id: Enums.websiteMetrics.pageViews.metricId,
                 displayName: Enums.websiteMetrics.pageViews.name,
                 propertyName: Enums.websiteMetrics.pageViews.nameKey,
                 metricFormatKey: constants.formatKeys.localeString
             },
             {
                 id: Enums.websiteMetrics.servicePageViews.metricId,
                 displayName: Enums.websiteMetrics.servicePageViews.name,
                 propertyName: Enums.websiteMetrics.servicePageViews.nameKey,
                 metricFormatKey: constants.formatKeys.localeString
             },
             //{
             //    id: Enums.websiteMetrics.pageViewsPerVisit.metricId,
             //    displayName: Enums.websiteMetrics.pageViewsPerVisit.name,
             //    propertyName: Enums.websiteMetrics.pageViewsPerVisit.nameKey,
             //    metricFormatKey: constants.formatKeys.roundToHundreth
             //},
             {
                 id: Enums.websiteMetrics.avgTimeOnSite.metricId,
                 displayName: Enums.websiteMetrics.avgTimeOnSite.name,
                 propertyName: Enums.websiteMetrics.avgTimeOnSite.nameKey,
                 metricFormatKey: constants.formatKeys.localeString
             },
             
             {
                 id: Enums.websiteMetrics.formSubmissions.metricId,
                 displayName: Enums.websiteMetrics.formSubmissions.name,
                 propertyName: Enums.websiteMetrics.formSubmissions.nameKey,
                 metricFormatKey: constants.formatKeys.localeString
             },
             {
                 id: Enums.websiteMetrics.formConversionRate.metricId,
                 displayName: Enums.websiteMetrics.formConversionRate.name,
                 propertyName: Enums.websiteMetrics.formConversionRate.nameKey,
                 metricFormatKey: constants.formatKeys.percentageTwoDecimals
             },
             //// Phone Calls
             //{
             //    id: Enums.websiteMetrics.phoneCalls.metricId,
             //    displayName: Enums.websiteMetrics.phoneCalls.name,
             //    propertyName: Enums.websiteMetrics.phoneCalls.nameKey,
             //    metricFormatKey: constants.formatKeys.localeString
             //},
             //// Phone Contact Rate
             //{
             //    id: Enums.websiteMetrics.phoneConversionRate.metricId,
             //    displayName: Enums.websiteMetrics.phoneConversionRate.name,
             //    propertyName: Enums.websiteMetrics.phoneConversionRate.nameKey,
             //    metricFormatKey: constants.formatKeys.percentageTwoDecimals
             //},
             //// Total Contacts
             //{
             //    id: Enums.websiteMetrics.totalContacts.metricId,
             //    displayName: Enums.websiteMetrics.totalContacts.name,
             //    propertyName: Enums.websiteMetrics.totalContacts.nameKey,
             //    metricFormatKey: constants.formatKeys.localeString
             //},
             //// Contact Rate
             //{
             //    id: Enums.websiteMetrics.totalContactRate.metricId,
             //    displayName: Enums.websiteMetrics.totalContactRate.name,
             //    propertyName: Enums.websiteMetrics.totalContactRate.nameKey,
             //    metricFormatKey: constants.formatKeys.percentageTwoDecimals
             //},
             {
                 id: Enums.websiteMetrics.clickToCalls.metricId,
                 displayName: Enums.websiteMetrics.clickToCalls.name,
                 propertyName: Enums.websiteMetrics.clickToCalls.nameKey,
                 metricFormatKey: constants.formatKeys.localeString
             },
             {
                 id: Enums.websiteMetrics.bounceRate.metricId,
                 displayName: Enums.websiteMetrics.bounceRate.name,
                 propertyName: Enums.websiteMetrics.bounceRate.nameKey,
                 metricFormatKey: constants.formatKeys.localeString
             },
             {
                 id: Enums.websiteMetrics.newInventoryDetailViews.metricId,
                 displayName: Enums.websiteMetrics.newInventoryDetailViews.name,
                 propertyName: Enums.websiteMetrics.newInventoryDetailViews.nameKey,
                 metricFormatKey: constants.formatKeys.localeString
             },
             {
                 id: Enums.websiteMetrics.usedInventoryDetailViews.metricId,
                 displayName: Enums.websiteMetrics.usedInventoryDetailViews.name,
                 propertyName: Enums.websiteMetrics.usedInventoryDetailViews.nameKey,
                 metricFormatKey: constants.formatKeys.localeString
             },
             {
                 id: Enums.websiteMetrics.cpoInventoryDetailViews.metricId,
                 displayName: Enums.websiteMetrics.cpoInventoryDetailViews.name,
                 propertyName: Enums.websiteMetrics.cpoInventoryDetailViews.nameKey,
                 metricFormatKey: constants.formatKeys.localeString
             },
         ],
         childMetrics: [
             {
                 id: Enums.channels.allChannels.channelId,
                 displayName: Enums.channels.allChannels.channelName,
                 propertyName: Enums.channels.allChannels.channelName,
                 metricFormatKey: constants.formatKeys.entityDisplayName
             },
             {
                 id: Enums.channels.brand.channelId,
                 displayName: Enums.channels.brand.channelName,
                 propertyName: Enums.channels.brand.channelName,
                 metricFormatKey: constants.formatKeys.entityDisplayName
             },
             {
                 id: Enums.channels.organicEmail.channelId,
                 displayName: Enums.channels.organicEmail.channelName,
                 propertyName: Enums.channels.organicEmail.channelName,
                 metricFormatKey: constants.formatKeys.entityDisplayName
             },
             {
                 id: Enums.channels.organicSearch.channelId,
                 displayName: Enums.channels.organicSearch.channelName,
                 propertyName: Enums.channels.organicSearch.channelName,
                 metricFormatKey: constants.formatKeys.entityDisplayName
             },
             {
                 id: Enums.channels.organicSocial.channelId,
                 displayName: Enums.channels.organicSocial.channelName,
                 propertyName: Enums.channels.organicSocial.channelName,
                 metricFormatKey: constants.formatKeys.entityDisplayName
             },
             {
                 id: Enums.channels.other.channelId,
                 displayName: Enums.channels.other.channelName,
                 propertyName: Enums.channels.other.channelName,
                 metricFormatKey: constants.formatKeys.entityDisplayName
             },
             {
                 id: Enums.channels.paidDisplay.channelId,
                 displayName: Enums.channels.paidDisplay.channelName,
                 propertyName: Enums.channels.paidDisplay.channelName,
                 metricFormatKey: constants.formatKeys.entityDisplayName
             },
             {
                 id: Enums.channels.paidEmail.channelId,
                 displayName: Enums.channels.paidEmail.channelName,
                 propertyName: Enums.channels.paidEmail.channelName,
                 metricFormatKey: constants.formatKeys.entityDisplayName
             },
             {
                 id: Enums.channels.paidSearch.channelId,
                 displayName: Enums.channels.paidSearch.channelName,
                 propertyName: Enums.channels.paidSearch.channelName,
                 metricFormatKey: constants.formatKeys.entityDisplayName
             },
             {
                 id: Enums.channels.paidVideo.channelId,
                 displayName: Enums.channels.paidVideo.channelName,
                 propertyName: Enums.channels.paidVideo.channelName,
                 metricFormatKey: constants.formatKeys.entityDisplayName
             },
             {
                 id: Enums.channels.thirdPartySites.channelId,
                 displayName: Enums.channels.thirdPartySites.channelName,
                 propertyName: Enums.channels.thirdPartySites.channelName,
                 metricFormatKey: constants.formatKeys.entityDisplayName
             },
             {
                 id: Enums.channels.typedBookmarked.channelId,
                 displayName: Enums.channels.typedBookmarked.channelName,
                 propertyName: Enums.channels.typedBookmarked.channelName,
                 metricFormatKey: constants.formatKeys.entityDisplayName
             },
         ],
 
         defaultChildrenIds: [ Enums.channels.allChannels.channelId, Enums.channels.allChannels.channelId ],
         defaultParentIds: [Enums.websiteMetrics.totalVisits.metricId, Enums.websiteMetrics.uniqueVisits.metricId ],
         metricOneColorHexCode: '#000',
         metricTwoColorHexCode: '#737373'
     };
 
     public summaryTableColumns: IDefaultTableColumnConfig[] = [
          {
              show: true,
              header: 'Referring Channel',
              columnDef: 'entity',
              isMom: false,
              isYoy: false,
              metricFormatKey: constants.formatKeys.entityDisplayName,
              clickable: false
          },
          //Engagements
          {
              show: true,
              header: 'Engagements',
              columnDef: 'engagements',
              isMom: false,
              isYoy: false,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Previous Month Engagements',
              columnDef: 'engagementsPreviousMTD',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Engagements MOM',
              columnDef: 'engagementsMOM',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Previous Year Engagements',
              columnDef: 'engagementsPreviousYear',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Engagements YOY',
              columnDef: 'engagementsYOY',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          //Engagement Rate
          {
              show: true,
              header: 'Engagement Rate',
              columnDef: 'engagementRate',
              isMom: false,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Previous Month Engagement Rate',
              columnDef: 'engagementRatePreviousMTD',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Engagement Rate MOM',
              columnDef: 'engagementRateMOM',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Previous Year Engagement Rate',
              columnDef: 'engagementRatePreviousYear',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Engagement Rate YOY',
              columnDef: 'engagementRateYOY',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          //Actions
          {
              show: true,
              header: 'Actions',
              columnDef: 'actions',
              isMom: false,
              isYoy: false,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Previous Month Actions',
              columnDef: 'actionsPreviousMTD',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Actions MOM',
              columnDef: 'actionsMOM',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Previous Year Actions',
              columnDef: 'actionsPreviousYear',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Actions YOY',
              columnDef: 'actionsYOY',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          //Action Rate
          {
              show: true,
              header: 'Action Rate',
              columnDef: 'actionRate',
              isMom: false,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Previous Month Action Rate',
              columnDef: 'actionRatePreviousMTD',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Action Rate MOM',
              columnDef: 'actionRateMOM',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Previous Year Action Rate',
              columnDef: 'actionRatePreviousYear',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Action Rate YOY',
              columnDef: 'actionRateYOY',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          //Website Visits
          {
              show: true,
              header: 'Website Visits',
              columnDef: 'totalVisits',
              isMom: false,
              isYoy: false,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Previous Month Website Visits',
              columnDef: 'totalVisitsPreviousMTD',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Website Visits MOM',
              columnDef: 'totalVisitsMOM',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Previous Year Website Visits',
              columnDef: 'totalVisitsPreviousYear',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Website Visits YOY',
              columnDef: 'totalVisitsYOY',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          //Unique Visitors
          {
              show: true,
              header: 'Unique Visitors',
              columnDef: 'uniqueVisits',
              isMom: false, isYoy: false,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Previous Month Unique Visitors',
              columnDef: 'uniqueVisitsPreviousMTD',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Unique Visitors MOM',
              columnDef: 'uniqueVisitsMOM',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Previous Year Unique Visitors',
              columnDef: 'uniqueVisitsPreviousYear',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Unique Visitors YOY',
              columnDef: 'uniqueVisitsYOY',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          //Total Page Views
          {
              show: true,
              header: 'Total Page Views',
              columnDef: 'pageViews',
              isMom: false,
              isYoy: false,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Previous Month Total Page Views', columnDef: 'pageViewsPreviousMTD',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Total Page Views MOM',
              columnDef: 'pageViewsMOM',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Previous Year Total Page Views',
              columnDef: 'pageViewsPreviousYear',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Total Page Views YOY',
              columnDef: 'pageViewsYOY',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          //Total Service Page Views
          {
              show: true,
              header: 'Page Views Per Visit',
              columnDef: 'pageViewsPerVisit',
              isMom: false,
              isYoy: false,
              metricFormatKey: constants.formatKeys.roundToHundreth, clickable: false
          },
          {
              show: false,
              header: 'Previous Month Page Views Per Visit',
              columnDef: 'pageViewsPerVisitPreviousMTD',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.roundToHundreth, clickable: false
          },
          {
              show: false,
              header: 'Page Views Per Visit MOM',
              columnDef: 'pageViewsPerVisitMOM',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false, header: 'Previous Year Page Views Per Visit',
              columnDef: 'pageViewsPerVisitPreviousYear',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.roundToHundreth, clickable: false
          },
          {
              show: false,
              header: 'Page Views Per Visit YOY',
              columnDef: 'pageViewsPerVisitYOY',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          //Avg.Time On Site
          {
              show: true,
              header: 'Time on Site',
              columnDef: 'averageTimeOnSite',
              isMom: false, isYoy: false,
              metricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds, clickable: false
          },
          {
              show: false,
              header: 'Previous Month Time on Site',
              columnDef: 'averageTimeOnSitePreviousMTD',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds, clickable: false
          },
          {
              show: false,
              header: 'Time on Site MOM',
              columnDef: 'averageTimeOnSiteMOM',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Previous Year Time on Site',
              columnDef: 'averageTimeOnSitePreviousYear',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds, clickable: false
          },
          {
              show: false,
              header: 'Time on Site YOY',
              columnDef: 'averageTimeOnSiteYOY',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          //Form Submissions
          {
              show: true,
              header: 'Form Submissions',
              columnDef: 'formSubmissions',
              isMom: false,
              isYoy: false,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Previous Month Form Submissions',
              columnDef: 'formSubmissionsPreviousMTD',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Form Submissions MOM',
              columnDef: 'formSubmissionsMOM',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Previous Year Form Submissions',
              columnDef: 'formSubmissionsPreviousYear',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Form Submissions YOY',
              columnDef: 'formSubmissionsYOY',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          //Form Conversion Rate
          {
              show: true,
              header: 'Form Conversion Rate',
              columnDef: 'formConversionRate',
              isMom: false,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Previous Month Form Conversion Rate',
              columnDef: 'formConversionRatePreviousMTD',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Form Conversion Rate MOM',
              columnDef: 'formConversionRateMOM',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Previous Year Form Conversion Rate',
              columnDef: 'formConversionRatePreviousYear',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Form Conversion Rate YOY',
              columnDef: 'formConversionRateYOY',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          //Click to Call
          {
              show: true,
              header: 'Click to Call',
              columnDef: 'clickToCalls',
              isMom: false,
              isYoy: false,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Previous Month Click to Call',
              columnDef: 'clickToCallsPreviousMTD',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Click to Call MOM',
              columnDef: 'clickToCallsMOM',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Previous Year Click to Call',
              columnDef: 'clickToCallsPreviousYear',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
              show: false,
              header: 'Click to Call YOY',
              columnDef: 'clickToCallsYOY',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          //Bounce Rate
          {
              show: true,
              header: 'Bounce Rate',
              columnDef: 'bounceRate',
              isMom: false,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Previous Month Bounce Rate',
              columnDef: 'bounceRatePreviousMTD',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Bounce Rate MOM',
              columnDef: 'bounceRateMOM',
              isMom: true,
              isYoy: false,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Previous Year Bounce Rate',
              columnDef: 'bounceRatePreviousYear',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
              show: false,
              header: 'Bounce Rate YOY',
              columnDef: 'bounceRateYOY',
              isMom: false,
              isYoy: true,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          //New Inventory Detail Views
          {
              show: true,
              header: 'New Inventory Detail Views',
              columnDef: 'newInventoryDetailViews',
              isMom: false,
              isYoy: false,
              metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
             show: false,
             header: 'Previous Month New Inventory Detail Views',
             columnDef: 'newInventoryDetailViewsPreviousMTD',
             isMom: true,
             isYoy: false,
             metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
             show: false,
             header: 'New Inventory Detail Views MOM',
             columnDef: 'newInventoryDetailViewsMOM',
             isMom: true,
             isYoy: false,
             metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
             show: false,
             header: 'Previous Year New Inventory Detail Views',
             columnDef: 'newInventoryDetailViewsPreviousYear',
             isMom: false,
             isYoy: true,
             metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
             show: false,
             header: 'New Inventory Detail Views YOY',
             columnDef: 'newInventoryDetailViewsYOY',
             isMom: false,
             isYoy: true,
             metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          //Used Inventory Detail Views
          {
             show: true,
             header: 'Used Inventory Detail Views',
             columnDef: 'usedInventoryDetailViews',
             isMom: false,
             isYoy: false,
             metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
             show: false,
             header: 'Previous Month Used Inventory Detail Views',
             columnDef: 'usedInventoryDetailViewsPreviousMTD',
             isMom: true,
             isYoy: false,
             metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
             show: false,
             header: 'Used Inventory Detail Views MOM',
             columnDef: 'usedInventoryDetailViewsMOM',
             isMom: true,
             isYoy: false,
             metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
             show: false,
             header: 'Previous Year Used Inventory Detail Views',
             columnDef: 'usedInventoryDetailViewsPreviousYear',
             isMom: false,
             isYoy: true,
             metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
             show: false,
             header: 'Used Inventory Detail Views YOY',
             columnDef: 'usedInventoryDetailViewsYOY',
             isMom: false,
             isYoy: true,
             metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          //CPO Inventory Detail Views
          {
             show: true,
             header: 'CPO Inventory Detail Views',
             columnDef: 'cpoInventoryDetailViews',
             isMom: false,
             isYoy: false,
             metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
             show: false,
             header: 'Previous Month CPO Inventory Detail Views',
             columnDef: 'cpoInventoryDetailViewsPreviousMTD',
             isMom: true,
             isYoy: false,
             metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
             show: false,
             header: 'CPO Inventory Detail Views MOM',
             columnDef: 'cpoInventoryDetailViewsMOM',
             isMom: true,
             isYoy: false,
             metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          },
          {
             show: false,
             header: 'Previous Year CPO Inventory Detail Views',
             columnDef: 'cpoInventoryDetailViewsPreviousYear',
             isMom: false,
             isYoy: true,
             metricFormatKey: constants.formatKeys.localeString, clickable: false
          },
          {
             show: false,
             header: 'CPO Inventory Detail Views YOY',
             columnDef: 'cpoInventoryDetailViewsYOY',
             isMom: false,
             isYoy: true,
             metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
          }
       ];
 }
