import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as SvgResources from '../../../../lib/svg.resources';
import { IFacetedFilterDataItem, IReplayItem, IDateRangeValue, ISelectionInfo } from '../../../../types/faceted-filter.types';
import { TypeInstanceOf } from '../../../../Utils/type-instance-of';
import { BasePaneComponent } from '../base-pane/base-pane.component';

@Component({
	selector: 'ui-calendar-type-pane',
	templateUrl: './calendar-type-pane.component.html',
	styleUrls: ['./calendar-type-pane.component.scss'],
})
export class CalendarTypePaneComponent extends BasePaneComponent implements OnInit {
	options: IDateRangeValue[];
	selectedOption: IDateRangeValue;
	replayDataItem: IFacetedFilterDataItem;
	smallCloseIcon = SvgResources.sizableSvgIcon(SvgResources.svgIconTypes.close, 3);

	@Output() onCalendarTypeSelected = new EventEmitter<ISelectionInfo>();

	@Input() set replayValue(value: any) {
		if (TypeInstanceOf.DateRangeValue(value)) {
			this.replayUpdate(value);
		}
	}

	@Input() set replayItem(item: IReplayItem) {
		this.replayDataItem = item.item;
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.init();
	}

	onCalendarTypeChange(value: IDateRangeValue): void {
		this.emitValue(value);
	}

	private init(): void {
		const item = this.replayDataItem ?? this.item;
		this.options = (item.value ?? []) as IDateRangeValue[];
		this.emitValue(this.options[0]);
	}

	private emitValue(value: IDateRangeValue) {
		this.selectedOption = value ?? this.options[0];
		this.onCalendarTypeSelected.emit({dateRange: value, item: this.item});
	}

	private replayUpdate(replayValue: IDateRangeValue) {
		setTimeout(() => {
			if (replayValue) {
				this.emitValue((this.item.value as IDateRangeValue[]).find(i => i.kind === replayValue.kind));
			}
		}, 0);
	}
}
