import {
  IDefaultTableColumnConfig,
  IDigAdChannelConfig,
  IMultiLevelDualAxisLineGraphConfig,
  ICardHeaderConfig
} from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class DigAdChannelConfig implements IDigAdChannelConfig {
  reportTitle: string = 'Digital Advertising Channel Performance';
  public benchmarkCardHeader: ICardHeaderConfig = {
    title: 'Summary Data',
    iconClass: 'fa-file',
    helpTextKey: constants.helpTextKeys.digAdChannelSummaryHelp,
    helpTextTitle: 'Channel Summary',
    exportName: 'Digital Advertising Channel Performance - Summary Data',
    metricDisplayModes: ['MOM', 'YOY']
  };

  public graphConfig: IMultiLevelDualAxisLineGraphConfig = {
    xAxisPropertyName: 'date',
    parentMetrics: [
      {
        id: Enums.digitalAdvertisingMetrics.impressions.metricId,
        displayName: Enums.digitalAdvertisingMetrics.impressions.name,
        propertyName: Enums.digitalAdvertisingMetrics.impressions.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.digitalAdvertisingMetrics.clicks.metricId,
        displayName: Enums.digitalAdvertisingMetrics.clicks.name,
        propertyName: Enums.digitalAdvertisingMetrics.clicks.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.digitalAdvertisingMetrics.clickThroughRate.metricId,
        displayName: Enums.digitalAdvertisingMetrics.clickThroughRate.name,
        propertyName: Enums.digitalAdvertisingMetrics.clickThroughRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.digitalAdvertisingMetrics.actualCostPerClick.metricId,
        displayName: Enums.digitalAdvertisingMetrics.actualCostPerClick.name,
        propertyName: Enums.digitalAdvertisingMetrics.actualCostPerClick.nameKey,
        metricFormatKey: constants.formatKeys.currency
      },
      {
        id: Enums.digitalAdvertisingMetrics.actualCostPerImpression.metricId,
        displayName: Enums.digitalAdvertisingMetrics.actualCostPerImpression.name,
        propertyName: Enums.digitalAdvertisingMetrics.actualCostPerImpression.nameKey,
        metricFormatKey: constants.formatKeys.currency
      },
      {
        id: Enums.digitalAdvertisingMetrics.totalVisits.metricId,
        displayName: Enums.digitalAdvertisingMetrics.totalVisits.name,
        propertyName: Enums.digitalAdvertisingMetrics.totalVisits.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.digitalAdvertisingMetrics.uniqueVisitors.metricId,
        displayName: 'Unique Visits',
        propertyName: Enums.digitalAdvertisingMetrics.uniqueVisitors.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.digitalAdvertisingMetrics.engagementRate.metricId,
        displayName: Enums.digitalAdvertisingMetrics.engagementRate.name,
        propertyName: Enums.digitalAdvertisingMetrics.engagementRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.digitalAdvertisingMetrics.actionRate.metricId,
        displayName: Enums.digitalAdvertisingMetrics.actionRate.name,
        propertyName: Enums.digitalAdvertisingMetrics.actionRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.digitalAdvertisingMetrics.formSubmissions.metricId,
        displayName: Enums.digitalAdvertisingMetrics.formSubmissions.name,
        propertyName: Enums.digitalAdvertisingMetrics.formSubmissions.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.digitalAdvertisingMetrics.serviceSchedulers.metricId,
        displayName: Enums.digitalAdvertisingMetrics.serviceSchedulers.name,
        propertyName: Enums.digitalAdvertisingMetrics.serviceSchedulers.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.digitalAdvertisingMetrics.partOrders.metricId,
        displayName: Enums.digitalAdvertisingMetrics.partOrders.name,
        propertyName: Enums.digitalAdvertisingMetrics.partOrders.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.digitalAdvertisingMetrics.phoneCalls.metricId,
        displayName: Enums.digitalAdvertisingMetrics.phoneCalls.name,
        propertyName: Enums.digitalAdvertisingMetrics.phoneCalls.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.digitalAdvertisingMetrics.conversionRate.metricId,
        displayName: Enums.digitalAdvertisingMetrics.conversionRate.name,
        propertyName: Enums.digitalAdvertisingMetrics.conversionRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.digitalAdvertisingMetrics.actualCostPerAcquisition.metricId,
        displayName: 'Cost Per Conversion',
        propertyName: Enums.digitalAdvertisingMetrics.actualCostPerAcquisition.nameKey,
        metricFormatKey: constants.formatKeys.currency
      },
      {
        id: Enums.digitalAdvertisingMetrics.bounceRate.metricId,
        displayName: Enums.digitalAdvertisingMetrics.bounceRate.name,
        propertyName: Enums.digitalAdvertisingMetrics.bounceRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.digitalAdvertisingMetrics.socialReach.metricId,
        displayName: Enums.digitalAdvertisingMetrics.socialReach.name,
        propertyName: Enums.digitalAdvertisingMetrics.socialReach.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.digitalAdvertisingMetrics.socialFrequency.metricId,
        displayName: Enums.digitalAdvertisingMetrics.socialFrequency.name,
        propertyName: Enums.digitalAdvertisingMetrics.socialFrequency.nameKey,
        metricFormatKey: constants.formatKeys.roundToTenth
      },
      {
        id: Enums.digitalAdvertisingMetrics.completedVideoViews.metricId,
        displayName: Enums.digitalAdvertisingMetrics.completedVideoViews.name,
        propertyName: Enums.digitalAdvertisingMetrics.completedVideoViews.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.digitalAdvertisingMetrics.videoViews.metricId,
        displayName: Enums.digitalAdvertisingMetrics.videoViews.name,
        propertyName: Enums.digitalAdvertisingMetrics.videoViews.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.digitalAdvertisingMetrics.videoViewRate.metricId,
        displayName: Enums.digitalAdvertisingMetrics.videoViewRate.name,
        propertyName: Enums.digitalAdvertisingMetrics.videoViewRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      }
    ],
    childMetrics: [
      {
        id: Enums.digitalAdvertisingChannels.allChannels.channelId,
        displayName: Enums.digitalAdvertisingChannels.allChannels.channelName,
        propertyName: Enums.digitalAdvertisingChannels.allChannels.channelName,
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 2,
        displayName: 'Search',
        propertyName: 'Search',
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 4,
        displayName: 'Display',
        propertyName: 'Display',
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 5,
        displayName: 'Video',
        propertyName: 'Video',
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 8,
        displayName: 'Social',
        propertyName: 'Social',
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 13,
        displayName: 'Display Retargeting',
        propertyName: 'Display Retargeting',
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 14,
        displayName: 'Social Retargeting',
        propertyName: 'Social Retargeting',
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 15,
        displayName: 'Video Retargeting',
        propertyName: 'Video Retargeting',
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
    ],

    defaultChildrenIds: [Enums.digitalAdvertisingChannels.allChannels.channelId, Enums.digitalAdvertisingChannels.allChannels.channelId],
    defaultParentIds: [Enums.digitalAdvertisingMetrics.impressions.metricId, Enums.digitalAdvertisingMetrics.clicks.metricId],
    metricOneColorHexCode: '#000000',
    metricTwoColorHexCode: '#820828'
  };

  public summaryTableColumns: IDefaultTableColumnConfig[] = [
    {
      show: true,
      header: 'Channel',
      columnDef: 'entity',
      metricFormatKey: constants.formatKeys.entityDisplayName
    },

    // Impressions
    {
      show: true,
      header: 'Impressions',
      columnDef: 'impressions',
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: false,
      header: 'Previous Month Impressions',
      columnDef: 'impressionsPreviousMonth',
      isMom: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: false,
      header: 'Impressions MOM',
      columnDef: 'impressionsMOM',
      isMom: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: false,
      header: 'Previous Year Impressions',
      columnDef: 'impressionsPreviousYear',
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: false,
      header: 'Impressions YOY',
      columnDef: 'impressionsYOY',
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    // Clicks
    {
      show: true,
      header: 'Clicks',
      columnDef: 'clicks',
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: false,
      header: 'Previous Month Clicks',
      columnDef: 'clicksPreviousMonth',
      isMom: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: false,
      header: 'Clicks MOM',
      columnDef: 'clicksMOM',
      isMom: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: false,
      header: 'Previous Year Clicks',
      columnDef: 'clicksPreviousYear',
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: false,
      header: 'Clicks YOY',
      columnDef: 'clicksYOY',
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    // CTR
    {
      show: true,
      header: 'Click Through Rate',
      columnDef: 'clickThroughRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: false,
      header: 'Previous Month Click Through Rate',
      columnDef: 'clickThroughRatePreviousMonth',
      isMom: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: false,
      header: 'Click Through Rate MOM',
      columnDef: 'clickThroughRateMOM',
      isMom: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: false,
      header: 'Previous Year Click Through Rate',
      columnDef: 'clickThroughRatePreviousYear',
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: false,
      header: 'Click Through Rate YOY',
      columnDef: 'clickThroughRateYOY',
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    // CPC
    {
      show: true,
      header: 'Cost Per Click',
      columnDef: 'actualCostPerClick',
      metricFormatKey: constants.formatKeys.currency
    },
    {
      show: false,
      header: 'Previous Month Cost Per Click',
      columnDef: 'actualCostPerClickPreviousMonth',
      isMom: true,
      metricFormatKey: constants.formatKeys.currency
    },
    {
      show: false,
      header: 'Cost Per Click MOM',
      columnDef: 'actualCostPerClickMOM',
      isMom: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: false,
      header: 'Previous Year Cost Per Click',
      columnDef: 'actualCostPerClickPreviousYear',
      isYoy: true,
      metricFormatKey: constants.formatKeys.currency
    },
    {
      show: false,
      header: 'Cost Per Click YOY',
      columnDef: 'actualCostPerClickYOY',
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    // CPM
    {
      show: true,
      header: 'Cost Per Impression',
      columnDef: 'actualCostPerImpression',
      metricFormatKey: constants.formatKeys.currency
    },
    {
      show: false,
      header: 'Previous Month Cost Per Impression',
      columnDef: 'actualCostPerImpressionPreviousMonth',
      isMom: true,
      metricFormatKey: constants.formatKeys.currency
    },
    {
      show: false,
      header: 'Cost Per Impression MOM',
      columnDef: 'actualCostPerImpressionMOM',
      isMom: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: false,
      header: 'Previous Year Cost Per Impression',
      columnDef: 'actualCostPerImpressionPreviousYear',
      isYoy: true,
      metricFormatKey: constants.formatKeys.currency
    },
    {
      show: false,
      header: 'Cost Per Impression YOY',
      columnDef: 'actualCostPerImpressionYOY',
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    // Total Visits
    {
      show: true,
      header: 'Total Visits',
      columnDef: 'totalVisits',
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: false,
      header: 'Previous Month Total Visits',
      columnDef: 'totalVisitsPreviousMonth',
      isMom: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: false,
      header: 'Total Visits MOM',
      columnDef: 'totalVisitsMOM',
      isMom: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: false,
      header: 'Previous Year Total Visits',
      columnDef: 'totalVisitsPreviousYear',
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: false,
      header: 'Total Site Visits YOY',
      columnDef: 'totalVisitsYOY',
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    // Unique Site Visits
    {
      show: true,
      header: 'Unique Visits',
      columnDef: 'uniqueVisitors',
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: false,
      header: 'Previous Month Unique Visits',
      columnDef: 'uniqueVisitorsPreviousMonth',
      isMom: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: false,
      header: 'Unique Visits MOM',
      columnDef: 'uniqueVisitorsMOM',
      isMom: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: false,
      header: 'Previous Year Unique Visits',
      columnDef: 'uniqueVisitorsPreviousYear',
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: false,
      header: 'Unique Visits YOY',
      columnDef: 'uniqueVisitorsYOY',
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    // Engagement Rate
    {
      show: true,
      header: 'Engagement Rate',
      columnDef: 'engagementRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: false,
      header: 'Previous Month Engagement Rate',
      columnDef: 'engagementRatePreviousMonth',
      isMom: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: false,
      header: 'Engagement Rate MOM',
      columnDef: 'engagementRateMOM',
      isMom: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: false,
      header: 'Previous Year Engagement Rate',
      columnDef: 'engagementRatePreviousYear',
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: false,
      header: 'Engagement Rate YOY',
      columnDef: 'engagementRateYOY',
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    // Action Rate
    {
      show: true,
      header: 'Action Rate',
      columnDef: 'actionRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: false,
      header: 'Previous Month Action Rate',
      columnDef: 'actionRatePreviousMonth',
      isMom: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: false,
      header: 'Action Rate MOM',
      columnDef: 'actionRateMOM',
      isMom: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: false,
      header: 'Previous Year Action Rate',
      columnDef: 'actionRatePreviousYear',
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: false,
      header: 'Action Rate YOY',
      columnDef: 'actionRateYOY',
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    // Form Submissions
    {
      show: true,
      header: 'Form Submissions',
      columnDef: 'formSubmissions',
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: false,
      header: 'Previous Month Form Submissions',
      columnDef: 'formSubmissionsPreviousMonth',
      isMom: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: false,
      header: 'Form Submissions MOM',
      columnDef: 'formSubmissionsMOM',
      isMom: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: false,
      header: 'Previous Year Form Submissions',
      columnDef: 'formSubmissionsPreviousYear',
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: false,
      header: 'Form Submissions YOY',
      columnDef: 'formSubmissionsYOY',
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    // Service Schedules
    {
      show: true,
      header: 'Service Schedules',
      columnDef: 'serviceSchedulers',
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: false,
      header: 'Previous Month Service Schedules',
      columnDef: 'serviceSchedulersPreviousMonth',
      isMom: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: false,
      header: 'Service Schedules MOM',
      columnDef: 'serviceSchedulersMOM',
      isMom: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: false,
      header: 'Previous Year Service Schedules',
      columnDef: 'serviceSchedulersPreviousYear',
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: false,
      header: 'Service Schedules YOY',
      columnDef: 'serviceSchedulersYOY',
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    // Part Orders
    {
      show: true,
      header: 'Part Orders',
      columnDef: 'partOrders',
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: false,
      header: 'Previous Month Part Orders',
      columnDef: 'partOrdersPreviousMonth',
      isMom: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: false,
      header: 'Part Orders MOM',
      columnDef: 'partOrdersMOM',
      isMom: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: false,
      header: 'Previous Year Part Orders',
      columnDef: 'partOrdersPreviousYear',
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: false,
      header: 'Part Orders YOY',
      columnDef: 'partOrdersYOY',
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    // Phone Calls
    {
      show: true,
      header: 'Phone Calls',
      columnDef: 'phoneCalls',
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: false,
      header: 'Previous Month Phone Calls',
      columnDef: 'phoneCallsPreviousMonth',
      isMom: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: false,
      header: 'Phone Calls MOM',
      columnDef: 'phoneCallsMOM',
      isMom: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: false,
      header: 'Previous Year Phone Calls',
      columnDef: 'phoneCallsPreviousYear',
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: false,
      header: 'Phone Calls YOY',
      columnDef: 'phoneCallsYOY',
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    // Conversion Rate
    {
      show: true,
      header: 'Conversion Rate',
      columnDef: 'conversionRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: false,
      header: 'Previous Month Conversion Rate',
      columnDef: 'conversionRatePreviousMonth',
      isMom: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: false,
      header: 'Conversion Rate MOM',
      columnDef: 'conversionRateMOM',
      isMom: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: false,
      header: 'Previous Year Conversion Rate',
      columnDef: 'conversionRatePreviousYear',
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: false,
      header: 'Conversion Rate YOY',
      columnDef: 'conversionRateYOY',
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    // Cost per acquisition
    {
      show: true,
      header: 'Cost Per Conversion',
      columnDef: 'actualCostPerAcquisition',
      metricFormatKey: constants.formatKeys.currency
    },
    {
      show: false,
      header: 'Previous Month Cost Per Conversion',
      columnDef: 'actualCostPerAcquisitionPreviousMonth',
      isMom: true,
      metricFormatKey: constants.formatKeys.currency
    },
    {
      show: false,
      header: 'Cost Per Conversion MOM',
      columnDef: 'actualCostPerAcquisitionMOM',
      isMom: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: false,
      header: 'Previous Year Cost Per Conversion',
      columnDef: 'actualCostPerAcquisitionPreviousYear',
      isYoy: true,
      metricFormatKey: constants.formatKeys.currency
    },
    {
      show: false,
      header: 'Cost Per Conversion YOY',
      columnDef: 'actualCostPerAcquisitionYOY',
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    // Bounce Rate
    {
      show: true,
      header: 'Bounce Rate',
      columnDef: 'bounceRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: false,
      header: 'Previous Month Bounce Rate',
      columnDef: 'bounceRatePreviousMonth',
      isMom: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: false,
      header: 'Bounce Rate MOM',
      columnDef: 'bounceRateMOM',
      isMom: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: false,
      header: 'Previous Year Bounce Rate',
      columnDef: 'bounceRatePreviousYear',
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: false,
      header: 'Bounce Rate YOY',
      columnDef: 'bounceRateYOY',
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },




    // Social Reach
    {
      show: true,
      header: 'Social Reach',
      columnDef: 'socialReach',
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: false,
      header: 'Previous Month Social Reach',
      columnDef: 'socialReachPreviousMonth',
      isMom: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: false,
      header: 'Social Reach MOM',
      columnDef: 'socialReachMOM',
      isMom: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: false,
      header: 'Previous Year Social Reach',
      columnDef: 'socialReachPreviousYear',
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: false,
      header: 'Social Reach YOY',
      columnDef: 'socialReachYOY',
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    // Social Frequency
    {
      show: true,
      header: 'Social Frequency',
      columnDef: 'socialFrequency',
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: false,
      header: 'Previous Month Social Frequency',
      columnDef: 'socialFrequencyPreviousMonth',
      isMom: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: false,
      header: 'Social Frequency MOM',
      columnDef: 'socialFrequencyMOM',
      isMom: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: false,
      header: 'Previous Year Social Frequency',
      columnDef: 'socialFrequencyPreviousYear',
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: false,
      header: 'Social Frequency YOY',
      columnDef: 'socialFrequencyYOY',
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },

    // Completed Video Views
    {
      show: true,
      header: 'Completed Video Views',
      columnDef: 'completedVideoViews',
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: false,
      header: 'Previous Month Completed Video Views',
      columnDef: 'completedVideoViewsPreviousMonth',
      isMom: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: false,
      header: 'Completed Video Views MOM',
      columnDef: 'completedVideoViewsMOM',
      isMom: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: false,
      header: 'Previous Year Completed Video Views',
      columnDef: 'completedVideoViewsPreviousYear',
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: false,
      header: 'Completed Video Views YOY',
      columnDef: 'completedVideoViewsYOY',
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    // Video Views
    {
      show: true,
      header: 'Video Views',
      columnDef: 'videoViews',
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: false,
      header: 'Previous Month Video Views',
      columnDef: 'videoViewsPreviousMonth',
      isMom: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: false,
      header: 'Video Views MOM',
      columnDef: 'videoViewsMOM',
      isMom: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: false,
      header: 'Previous Year Video Views',
      columnDef: 'videoViewsPreviousYear',
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    {
      show: false,
      header: 'Video Views YOY',
      columnDef: 'videoViewsYOY',
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    // Video View Rate
    {
      show: true,
      header: 'Video Views Rate',
      columnDef: 'videoViewRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: false,
      header: 'Previous Month Video Views Rate',
      columnDef: 'videoViewRatePreviousMonth',
      isMom: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: false,
      header: 'Video Views Rate MOM',
      columnDef: 'videoViewRateMOM',
      isMom: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: false,
      header: 'Previous Year Video Views Rate',
      columnDef: 'videoViewRatePreviousYear',
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    {
      show: false,
      header: 'Video Views Rate YOY',
      columnDef: 'videoViewRateYOY',
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
  ];

}
