import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { ContentListResponseModel, ContentModel, FileContentModel, HtmlContentModel, ListRequestModel, SectionContentModel } from '../../models/content-v2.model';

@Injectable({
  providedIn: 'root'
})
export class ContentServiceV2 {

  jsonHeaders = new HttpHeaders().set('Content-Type', 'application/json');
  formHeaders = new HttpHeaders().set('Content-Type', 'multipart/form-data');

  constructor(
    private http: HttpClient
  ) { }

  ngOnInit() { }

  ngOnDestroy(): void { }

  private api = `${environment.baseV5ApiUri}/content`;

  getContent(name: string, locale?: string) {
    const localeSuffix = (!locale || locale === 'en') ? '' : '_' + locale;
    return this.http.get<ContentModel>(`${this.api}/name/${name + localeSuffix}`);
  }

  getList(request: ListRequestModel) {
    let params = new HttpParams();
    for (const [key, value] of Object.entries(request)) {
      if (value !== undefined && value !== null && value !== '') {
        params = params.append(key, value.toString());
      }
    }

    return this.http.get<ContentListResponseModel>(`${this.api}`, { params })
  }

  processContent(content: ContentModel, file: File, thumbnail: File = null): Observable<any> {
    let request: Observable<any>;
    switch (content.type) {
      case 'File':
        let fileContentModel = <FileContentModel>content;
        fileContentModel.file = file;
        fileContentModel.thumbnail = thumbnail;
        request = this.upsertFileContent(fileContentModel);
        break;
      case 'Section':
        let sectionContentModel = <SectionContentModel>content;
        request = !sectionContentModel.id
          ? this.createContent(sectionContentModel)
          : this.updateContent(sectionContentModel);
        break;
      case 'Video':
        let videoContentModel = <FileContentModel>content;
        videoContentModel.file = file;
        videoContentModel.thumbnail = thumbnail;
        request = this.upsertFileContent(videoContentModel);
        break;
      default: // Html Content
        let htmlContentModel = <HtmlContentModel>content;
        htmlContentModel.html = <string>htmlContentModel.value;
        request = !htmlContentModel.id
          ? this.createContent(htmlContentModel)
          : this.updateContent(htmlContentModel);
        break;
    }

    return request;
  }

  createContent(content: ContentModel) : Observable<string> {
    const formData = this.mapContentToFormData(content);

    return this.http.post<string>(
      this.api,
      formData,
      { headers: this.formHeaders }
    );
  }

  updateContent(content: ContentModel) {
    const formData = this.mapContentToFormData(content);

    return this.http.put<ContentModel>(
      `${this.api}/${content.id}`,
      formData,
      { headers: this.formHeaders }
    );
  }

  upsertFileContent(content: FileContentModel) {
    const formData = this.mapContentToFormData(content);
    const headers = this.formHeaders;

    if (content.file) {
      let mainFile = new Blob([content.file], { type: content.file.type });
      formData.append('main', mainFile, content.file.name);
    }

    if (content.thumbnail) {
      let thumbnailFile = new Blob([content.thumbnail], { type: content.thumbnail.type });
      formData.append('thumbnail', thumbnailFile, content.thumbnail.name);
    }
    return content.id
        ? this.http.put(`${this.api}/${content.id}`, formData, { headers }) // update
        : this.http.post(this.api, formData, { headers }); // insert
  }

  deleteContent(id: string) {
    return this.http.delete(`${this.api}/${id}`);
  }

  private mapContentToFormData(content: ContentModel): FormData {
    const requestModel : ContentModel = {
        id: content.id,
        type: content.type,
        createdBy: content.createdBy,
        name: content.name,
        isActive: content.isActive,
        description: content.description,
        value: content.value,
        order: content.order,
        sectionId: content.sectionId
    }

    const formData = new FormData();

    // Append the rest of the form data
    for (const [key, value] of Object.entries(requestModel)) {
        if (value !== undefined && value !== null && value !== '') {
            formData.append(key, value.toString());
        }
    }

    return formData;
  }
}
