import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import * as Models from '../_shared/models/models-index';
import { AuthenticationService, LocalCacheService, SpinnerService, FilterService, LocalStorageService, ConfigurationService, IndexDbStorageService } from '../_shared/services/services-index';
import { Enums, IEnums } from '../_shared/enums/enums';
import { environment } from '../../environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from '../_store/app-state.model';
import { AppActions } from '../_store/action-types';
import { tap, finalize, catchError, switchMap, filter } from 'rxjs/operators';
import { throwError, of, Subscription, EMPTY } from 'rxjs';
import { FilterSelectors, FilterActions } from '../_shared/filter/store';
import { AppSelectors } from '../_store/selector-types';
import { FilterBarService } from '../_shared/filter/filter-bar.service';


interface ILogin {
    message: string;
    userName: string;
    password: string;

    login(): void;
}

// interface IRememberInfo {
//    rememberMe: boolean;
//    userName: string;
// }

@Component({
    templateUrl: './login.component.html',
    styles: ['.card { margin-bottom: 0em !important }']
})

export class LoginComponent implements ILogin, OnInit, OnDestroy {
    private _subscription = new Subscription();
    message = '';
    userName = '';
    password = '';
    rememberMe: boolean;
    forceLoginDisable = false;
    enums: IEnums = Enums;
    environment = environment;

    constructor(
        private store: Store<AppState>,
        private authService: AuthenticationService,
        private router: Router,
        private spinner: SpinnerService,
        private cache: LocalCacheService,
        private filterBarService: FilterBarService,
        private configService: ConfigurationService,
        private indexDbStorageService: IndexDbStorageService
    ) {
        
    }

    ngOnInit() {
        const rememberInfo: Models.IRememberMe = this.cache.retrieveCache<Models.IRememberMe>(this.enums.cacheKeys.rememberMe);
        if (rememberInfo && rememberInfo.rememberMe) {
            this.userName = rememberInfo.userName;
            this.rememberMe = rememberInfo.rememberMe;
        }

        this.store.dispatch(AppActions.resetAuth());
        this.store.dispatch(FilterActions.resetFilters());
        this.filterBarService.clearFiltersLoaded();
    }

    ngOnDestroy() {
        this._subscription.unsubscribe();
    }

    login(): void {
        this.cache.purgeAllCaches();
        this.indexDbStorageService.purgeCache().subscribe();
        this.message = '';
        if (!this.userName || !this.password) {
            this.message = 'Please enter username and password';
            return;
        }

        this.spinner.show();

        this._subscription.add(
            this.authService.login(this.userName, this.password)
                .pipe(
                    tap(auth => this.store.dispatch(AppActions.login({auth}))),
                    switchMap(() => this.store.select(AppSelectors.selectAuthState).pipe(
                        filter(auth => !!auth.auth),
                        tap(_ => this.store.dispatch(FilterActions.resetAllReportViewFilters())),
                    )),
                    catchError(err => {
                        this.spinner.hide();
                        console.error(err);
                        this.message = 'Unknown email/password.';
                        return EMPTY;
                    })
                ).subscribe(() => {
                    this.spinner.hide();
                    this.router.navigateByUrl(this.configService.routing.defaultRoute);
                })
        );
    }
}
