import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BasePagedSortableRequest } from '../../models/models';
import { GetProvidersResponse, ProviderRequest, ProviderResponse } from '../../models/provider-lookup.model';

@Injectable({
    providedIn: 'root'
})
export class ProviderService {
    private api = `${environment.baseV5ApiUri}/Provider`;
    constructor(private http: HttpClient) { }

    getPage(request: BasePagedSortableRequest): Observable<GetProvidersResponse> {
        let params = new HttpParams();
        for (const [key, value] of Object.entries(request)) {
            if (value !== undefined && value !== null && value !== '') {
                params = params.append(key, value.toString());
            }
        }

        return this.http.get<GetProvidersResponse>(`${this.api}`, { params })
            .pipe(
                catchError((error) => {
                    console.error(error);
                    throw error;
                })
            );
    }

    getById(id: string): Observable<ProviderResponse> {
        return this.http.get<ProviderResponse>(`${this.api}/${id}`)
          .pipe(
            catchError((error) => {
              console.error(error);
              throw error;
            })
          );
      }

      create(request: ProviderRequest, file: File | null): Observable<string> {
        const headers = new HttpHeaders().set('Content-Type', 'multipart/form-data');
        const formData = new FormData();
        
        // Append file to formData if present
        if (file) {
          formData.append('Logo', file, file.name);
        }
        
        // Append the rest of the form data
        for (const [key, value] of Object.entries(request)) {
          if (value !== undefined && value !== null && value !== '') {
            formData.append(key, value.toString());
          }
        }
    
        return this.http.post<string>(`${this.api}`, formData, {headers: headers}).pipe(
          catchError((error) => {
            console.error(error);
            throw error;
          })
        );
      }

    update(id: string, request: ProviderRequest, file: File | null): Observable<void> {
        const headers = new HttpHeaders().set('Content-Type', 'multipart/form-data');
        const formData = new FormData();
        
        // Append file to formData if present
        if (file) {
          formData.append('Logo', file, file.name);
        }
        
        // Append the rest of the form data
        for (const [key, value] of Object.entries(request)) {
          if (value !== undefined && value !== null && value !== '') {
            formData.append(key, value.toString());
          }
        }

        return this.http.put<void>(`${this.api}/${id}`, formData, {headers: headers})
        .pipe(
          catchError((error) => {
            console.error(error);
            throw error;
          })
        );
    }
}