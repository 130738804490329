<mat-card
  class="mat-elevation-z1 element-card tri-metric-element"
  style="background-color: white; color: black;"
>
  <mat-card-title>
    <i class="fa pad-right" [ngClass]="titleIconClasses"></i><b>{{ title }}</b>
    <div class="pull-right ellipses-dropdown" *ngIf="settings.helpTextKey">
      <button
        title="options"
        mat-button
        class="card-options-button"
        #menuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="menu"
      >
        <i class="fa fa-ellipsis-v fa-primary" style="font-size: 1.4em"></i>
      </button>
      <mat-menu #menu="matMenu">
        <button
          mat-menu-item
          style="outline: none"
          (click)="openHelpTextClicked()"
        >
          {{ "Help" | translate : locale | async }}
        </button>
      </mat-menu>
    </div>
  </mat-card-title>
  <mat-card-content
    class="card-block target-card billboard"
    style="margin-bottom: 0px;"
  >
    <div class="metrics-container">
      <div class="metric-item">
        <div style="padding-right: 10px">
            <i
                [ngClass]="[
                getTrendArrowIconClass(metrics[0].trend),
                getTrendIconColor(metrics[0].trend)
                ]"
                style="font-size:40px"
            ></i>
        </div>
        <div fxLayout="column">
            <div style="font-size: 1.25rem">{{ metrics[0].label }}</div>
            <div style="font-size: 2.5rem; font-weight: 500; line-height: 1">
                {{ metrics[0].value }}
            </div>
        </div>            
        <div *ngIf="metrics[0].subtitleOneValue" class="subtitle">
          <span class="subtitle-label">{{ metrics[0].subtitleOneLabel }}</span>
          <span class="subtitle-value">{{ metrics[0].subtitleOneValue }}</span>
        </div>
      </div>
      <div class="metric-item-separator"></div>

      <div class="display:flex; flex-direction: row; align-items: center;">
        <div style="display:flex; flex-direction: row; align-items: center;">
          <div style="padding-right: 10px">
            <i
              [ngClass]="[
                getTrendArrowIconClass(metrics[1].trend),
                getTrendIconColor(metrics[1].trend)
              ]"
            ></i>            
          </div>
          <div fxLayout="column">
            <div style="font-size: .75rem">{{ metrics[1].label }}</div>
            <div style="font-size: 1.5rem; font-weight: 500; line-height: 1">
              {{ metrics[1].value }}
            </div>
          </div>
        </div>
        <div class="stacked-item-seperator"></div>
        <div style="display:flex; flex-direction: row; align-items: center;">
            <div style="padding-right: 10px">
              <i
                [ngClass]="[
                  getTrendArrowIconClass(metrics[2].trend),
                  getTrendIconColor(metrics[2].trend)
                ]"
              ></i>            
            </div>
            <div fxLayout="column">
              <div style="font-size: .75rem">{{ metrics[2].label }}</div>
              <div style="font-size: 1.5rem; font-weight: 500; line-height: 1">
                {{ metrics[2].value }}
              </div>
            </div>
          </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
