<h2 mat-dialog-title>
  {{data.header}}
</h2>
<mat-dialog-content>
  <ng-container *ngIf="data.attributes">
    <table class="table table-striped">
      <tbody [innerHTML]="detailRows">
      </tbody>
    </table>
  </ng-container>
  <ng-container *ngIf="data.showActivity">
    <div class="row" style="margin-top: 20px">
      <div class="col-12">
        <h2 class="pull-left">{{'Lead Activity' | translate | async}}</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <table class="table table-striped">
          <thead class="report-table-head">
            <tr>
              <th *ngFor="let column of columns">{{ column.displayName }}</th>
            </tr>
          </thead>
          <tbody *ngIf="activityData$ | async as activityData">
            <tr *ngFor="let row of activityData" class="center">
              <td *ngFor="let column of displayedColumns">{{ row[column] }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button mat-dialog-close>{{'Close' | translate | async}}</button>
</mat-dialog-actions>