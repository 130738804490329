import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectLoggedInUser } from '../../_store/app.selectors';
import { tap, take, switchMap } from 'rxjs/operators';
import { AppState } from '../../_store/app-state.model';
import { environment } from '../../../environments/environment';
import { RoutingConfigService } from '../services/services-index';
import { Observable, of } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private store: Store<AppState>,
        private router: Router,
        private readonly routingConfigService: RoutingConfigService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.applyAuthGuard(route, state);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.applyAuthGuard(route, state);
    }

    private applyAuthGuard(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.store.select(selectLoggedInUser).pipe(
            take(1),
            switchMap(user => {
                if (!user || !user.isAuthenticated) {
                    return of(false).pipe(
                        tap(() => {
                            environment.loginRedirectUrl
                                ? window.location.href = environment.loginRedirectUrl
                                : this.router.navigate(['/login']);
                        })
                    );
                } else if (user.isAuthenticated) {
                    return this.validateRoute(route, state);
                }
            })
        );
    }

	private validateRoute(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		const routeUrl = this.constructRouteUrl(route, state.url);

		// Compare userRouteUrls ignoring parameters
		if (this.routingConfigService.userRouteUrls.some(uru => {
			const constructedUrl = this.constructRouteUrl(route, uru);
			return constructedUrl === routeUrl;
		})) {
			return of(true);
		}


		console.log('Not authorized to access [' + routeUrl + ']');
		return of(false).pipe(
			tap(() => this.router.navigate([this.routingConfigService.defaultRoute]))
		);
	}

    private constructRouteUrl(route: ActivatedRouteSnapshot, url: string): string {
		const [urlWithoutQueryParams] = url.split('?');

        // Remove all route parameters including GUIDs and integers
        const urlWithoutParams = urlWithoutQueryParams.split('/').filter(part => !this.isGuid(part) && !this.isInteger(part) && !part.startsWith(':')).join('/');
        return urlWithoutParams;
    }

    private isGuid(part: string): boolean {
        const guidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
        return guidRegex.test(part);
    }

	private isInteger(part: string): boolean {
        const intRegex = /^\d+$/;
        return intRegex.test(part);
    }
}
