import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import * as Models from '../../../models/models-index';
import { HelpTextService } from '../../../services/help/helpText.service';

type MetricOutput = { "value": string | number | Date, "trend": number, "subtitleOneValue": string | number | Date, "metricInverted": boolean, "subtitleOneLabel" };

@Component({
  selector: 'kpi-dual-metric-detailed',
  templateUrl: './kpi-dual-metric-detailed.component.html',
  styleUrls: ['./kpi-dual-metric-detailed.component.scss']
})
export class KpiDualMetricDetailedComponent implements Models.ElementComponent, OnInit {

  @Input() dataSet: Models.DataSet;
  @Input() settings: Models.ElementSettings;
  @Input() locale: string;

  title;
  titleIconClasses;

  metrics: MetricOutput[] = [];

  constructor(private helpTextService: HelpTextService) {
  }

  ngOnInit() {

    const row = this.dataSet.rows[0];

    if (!this.settings.metrics || this.settings.metrics.length === 0) {
      throw new Error("No metrics defined");
    }
    
    this.settings.metrics.forEach(metric => {
      const metricIndex = this.dataSet.columns.findIndex(col => col.name === metric.name);
      const metricPreviousPeriodIndex = this.dataSet.columns.findIndex(col => col.name === metric.previousPeriod);
      const metricIndexPOP = this.dataSet.columns.findIndex(col => col.name === metric.pop);

      const subtitleOneValue = row[metricPreviousPeriodIndex]?.label ?? row[metricPreviousPeriodIndex]?.value;
      const subtitleOneLabel = "Prev. Period:";
      this.metrics.push({ "value": row[metricIndex].label ?? row[metricIndex].value, "trend": this.dataSet.rows[0][metricIndexPOP]?.value as number,"subtitleOneValue": subtitleOneValue, "metricInverted": metric.metricInverted ,"subtitleOneLabel": subtitleOneLabel });
    });

    this.title = this.settings.title;
    this.titleIconClasses = this.settings.titleIconClasses;
  }

  getTrendArrowIconClass(metric: MetricOutput): string {
    const isTrendUp = this.isTrendUp(metric);

    if (isTrendUp === null || metric.trend === 0) {
      return 'fa fa-horizontal-rule';
    } else if (metric.trend > 0) {
      return 'fa fa-chevron-circle-up chevron-icon';
    } else if (metric.trend < 0) {
      return 'fa fa-chevron-circle-down chevron-icon';
    }
  }

  getTrendIconColor(metric: MetricOutput): string {
    const isTrendUp = this.isTrendUp(metric);

    if (isTrendUp === null) {
      return 'bg-yellow';
    } else if (!isTrendUp) {
      return 'bg-red';
    } else if (!!isTrendUp) {
      return 'bg-green';
    }
  }

  getTrendColor(metric: MetricOutput): string {
    const isTrendUp = this.isTrendUp(metric);
    if (isTrendUp == null) {
      return 'text-yellow';
    } else if (!!isTrendUp) {
      return 'text-green';
    } else if (!isTrendUp) {
      return 'text-red';
    }
  }

  isTrendUp(metric: MetricOutput) {
    if (!metric.trend || metric.trend === 0)
      return null;

    const metricInverted = this.settings.metricInverted || metric.metricInverted;

    return metric.trend > 0
      ? metricInverted ? false : true
      : metricInverted ? true : false;
  }

  openHelpTextClicked(): void {
    this.helpTextService.openHelp(this.settings.helpTextKey, this.settings.helpTextTitle);
  }

}