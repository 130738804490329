import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IFacetedFilterDataItem, IReplayItem, ISelectedDateRangeInfo, ISelectedFacetInfo } from '../../../../types/faceted-filter.types';
import { TypeCheck } from '../../../../Utils/type-check';
import { BasePaneComponent } from '../base-pane/base-pane.component';

@Component({
	selector: 'date-range-container',
	templateUrl: './date-range-container.component.html',
	styleUrls: ['./date-range-container.component.scss'],
})
export class DateRangeContainerPaneComponent extends BasePaneComponent implements OnInit, OnDestroy {

	@Output() onDateRangeContainerItemSelected = new EventEmitter<ISelectedDateRangeInfo>();

	@Input() set replayItem(replayItem: IReplayItem) {
		this.item = { ...replayItem.item };
		this.handleReplayItem(replayItem);
	}

	manageSelectionList(): void {
		if (this.item.items?.length) {
			this.itemsList          = this.item.items?.filter(i => !TypeCheck.isDateRangeValue(i)) ?? [];
			this.checkableItemsList = this.item.items?.filter(i =>  TypeCheck.isDateRangeValue(i)) ?? [];
		}
	}

	protected handleSingleSelectItem(selectedOption: IFacetedFilterDataItem): void {
		this.selectedItem = undefined;
		this.selectedCheckableItems = [selectedOption];

		const selection: ISelectedDateRangeInfo = {
			dateRange: selectedOption.value[0],
			item: selectedOption
		};

		this.onDateRangeContainerItemSelected.emit(selection)
	}

	private handleReplayItem(replayItem: IReplayItem): void {
		setTimeout(() => {
			// const selected = this.item;
			this.selectedItem = this.item;
			this.handleSingleSelectItem(this.item);
		}, 0);
	}
}
