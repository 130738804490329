import { ConnectionPositionPair } from '@angular/cdk/overlay';


export const TopPosition: ConnectionPositionPair = {
    originX: 'center',
    originY: 'top',
    overlayX: 'center',
    overlayY: 'bottom'
}

export const RightPosition: ConnectionPositionPair = {
    originX: 'end',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'top'
}

export const BottomPosition: ConnectionPositionPair = {
    originX: 'center',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'top'
}

export const LeftPosition: ConnectionPositionPair = {
    originX: 'start',
    originY: 'top',
    overlayX: 'end',
    overlayY: 'top'
}

export enum FilterPanelRenderMethods {
    template = 'template',
    component = 'component',
    text = 'text'
}

export enum FilterPanelCloseType {
    backdropClick = 'backdropClick',
    cancel = 'cancel',
    applied = 'applied'
}

export enum FilterPanelStates {
    open = 'open',
    closed = 'closed'
}

export enum SdFilterPanelInputType {
    list = 'list',
    collection = 'collection'
}

export enum FacetedFilterContainerItemType {
  Container = 'container',
  HierarchyContainer = 'hierarchy-facet-container',
  DateRangeContainer = 'date-range-container'
}

export enum FacetedFilterUtilContainerItemType {
  DateRangePicker = 'date-range-picker',
  CalendarType = 'calendar-type',
  Operator = '-operator'
}

export enum FacetedFilterItemValueType {
  DateRangeValue = 'date-range-value',
  DateRangeMultiValue = 'date-range-multi-value',
  SimpleValue = 'simple-value',
  HierarchyValue = 'hierarchy-value',
  RetailerValue = 'retailer-value',
  OperatorValue = 'operator-value',
  BooleanValue = 'boolean-value'
}
