<div *ngIf="item; else noData" class="pane-wrapper">

  <div class="pane bg-white border-r border-gray-300 scrollable-y scrollbar-thin scrollbar-thumb-primary-200 scrollbar-thumb-rounded-md scrollbar-track-primary-50 hover:scrollbar-thumb-primary-200">

      <div *ngFor="let item of itemsList" class="panel-option w-full h-10 flex items-center px-3 py-3 text-gray-900 font-medium cursor-pointer border-b border-gray-300"
              [ngClass]="{'active': selectedItem?.label === item.label}"
              (click)="itemSelected(item);">

          <div class="flex-1 truncate" [title]="item.label">{{ item.label }}</div>

          <continuation-indicator [isActive]="selectedItem?.label === item.label"></continuation-indicator>
      </div>

      <mat-selection-list *ngIf="checkableItemsList.length" class="filter-item-select-list" #selectList [(ngModel)]="selectedCheckableItems" (selectionChange)="checkableItemsChanged($event)">
          <mat-list-option checkboxPosition="before" *ngFor="let item of checkableItemsList" [value]="item" class="select-list-item">
              <div class="flex-1 truncate text-base md:text-sm text-gray-900 font-medium" [title]="item.label">{{ item.label }}</div>
          </mat-list-option>
      </mat-selection-list>

  </div>

</div>

<ng-template #noData>
  <div class="w-full h-full flex items-center justify-items-center">
      No Facet Data!!
  </div>
</ng-template>
