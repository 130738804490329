import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectableItem } from '../nested-menu/nested-menu.component';
@Component({
  selector: 'nested-select',
  templateUrl: './nested-select.component.html',
  styleUrls: ['./nested-select.component.scss']
})
export class NestedSelectComponent {
  @Input() items?: SelectableItem[];
  private _selectedItem?: SelectableItem;
  @Input()
  set selectedItem(item: SelectableItem | undefined) {
    this._selectedItem = item;
  }
  get selectedItem(): SelectableItem | undefined {
    return this._selectedItem;
  }
  @Output() selected = new EventEmitter<SelectableItem>();

  get displayName(): string {
    return this.selectedItem?.label || this.selectedItem?.value || "Menu";
  }
}
