import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
// import { UntypedFormControl } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { MatCalendar } from '@angular/material/datepicker';
// import { SvgResources, TailwindBreakpoint, TailwindBreakpointService } from '@harmony-ui/app-common';
import * as SvgResources from '../../../../lib/svg.resources';
import { TailwindBreakpoint, TailwindBreakpointService } from '../../../../lib/tailwind-breakpoint.service';
// import { debounceTime, skipWhile, takeUntil, distinctUntilChanged, startWith, tap, take } from 'rxjs';
import { debounceTime, skipWhile, takeUntil, distinctUntilChanged, startWith, tap, take } from 'rxjs/operators';
import { IReplayItem, ISelectedDateRangeInfo } from '../../../../types/faceted-filter.types';
import { TypeInstanceOf } from '../../../../Utils/type-instance-of';
import { BasePaneComponent } from '../base-pane/base-pane.component';
import { DateAdapter } from '@angular/material/core';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import * as Services from '../../../../../services/services-index';

@Component({
	selector: 'date-range-pane',
	templateUrl: './date-range-pane.component.html',
	styleUrls: ['./date-range-pane.component.scss']
})
export class DateRangePaneComponent extends BasePaneComponent implements OnInit, OnDestroy {
	datePaneContext = { start: 'start', end: 'end' };

	// startDateField = new UntypedFormControl();
	// endDateField = new UntypedFormControl();
  	startDateField = new FormControl();
	endDateField = new FormControl();

	selectedStartDate: Date | null;
	selectedEndDate: Date | null;
	startAtDate: Date | null;

	autoSetEndDate = true;

	isDesktop: boolean;
	showEndDateOnInit: boolean;
	endDateShowing = false;
	hasValidStartDate = false;
	replaying = false;

	forwardIcon = SvgResources.sizableSvgIcon(SvgResources.svgIconTypes.chevronDblRight, 6);
	backIcon = SvgResources.sizableSvgIcon(SvgResources.svgIconTypes.chevronDblLeft, 6);

	localeSub: Subscription;

	@Input() set replayItem(replayItem: IReplayItem) {
		this.item = { ...replayItem.item };
	}

	@Input() set replayValue(value: any) {
		//console.log('>>>>>>>>>>>>>date-range-pane.replayValue', value);
		if (TypeInstanceOf.DateRangeValue(value)) {
			this.replaying = true;
			setTimeout(() => {
				if (value && value.startDate) {
					this.startDateSelected(moment(value.startDate).toDate());
					// this.selectedEndDate = undefined;
				}
			}, 1);
			setTimeout(() => {
				if (value && value.endDate) 
					this.endDateSelected(moment(value.endDate).toDate());
			}, 1);
		}
	}

	@ViewChild('startDatePanel') startDatePanel: ElementRef;
	@ViewChild('endDatePanel') endDatePanel: ElementRef;
	@ViewChild('startDateCalendar') startDateCalendar: MatCalendar<Date>;
	@ViewChild('endDateCalendar') endDateCalendar: MatCalendar<Date>;

	constructor(
		private breakpoints: TailwindBreakpointService,
		private localeService: Services.LocaleService,
		private dateAdapter: DateAdapter<any>
	) {
		super();
	}
	ngOnInit(): void {
		super.ngOnInit();
		this.createFieldListeners();

		this.breakpoints.sizeGte(TailwindBreakpoint.md).pipe(
			take(1)
		).subscribe((isDesktop) => {
			this.isDesktop = isDesktop;
			this.showEndDateOnInit = isDesktop;
		});

		this.localeSub = this.localeService.locale$.subscribe(loc => {
      this.dateAdapter.setLocale(loc);
    });
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	startDateSelected($event: Date): void {
		this.startDateField.setValue(this.dateStringFormatter($event.toString()));
	}

	endDateSelected($event: Date): void {
		this.endDateField.setValue(this.dateStringFormatter($event.toString()));
	}

	scrollTo(pane: string): void {
		if (pane === this.datePaneContext.end)
			this.showEndDatePanel();
		else
			this.showStartDatePanel();
	}

	private setStartDate(val: string): void {
		if (this.valueIsValidDate(val)) {
			this.hasValidStartDate = true;
			this.selectedStartDate = new Date(val);
			this.startAtDate = this.selectedStartDate;

			if (this.isDesktop && !this.replaying) {
				this.setDefaultEndDate();
			} else {
				this.selectedEndDate = undefined;
			}

			this.changeStartDateCalendarView();

			this.buildDateRangeInfo();
		} else {
			this.hasValidStartDate = false;
		}
	}

	private setEndDate(val: string): void {
		if (this.valueIsValidDate(val)) {
			this.selectedEndDate = new Date(val);
			this.changeEndDateCalendarView();

			this.buildDateRangeInfo();
		}
	}

	private showEndDatePanel(): void {
		if (!this.selectedEndDate)
			this.setDefaultEndDate();

		setTimeout(() => {
			this.endDatePanel.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
		}, 0);
	}

	private showStartDatePanel(): void {
		setTimeout(() => {
			this.startDatePanel.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}, 0);
	}

	private setDefaultEndDate(): void {
		const d = new Date(this.selectedStartDate);
		d.setDate(d.getDate() + 1);
		this.selectedEndDate = d;
		this.endDateField.setValue(this.dateStringFormatter(d.toString()));
	}

	private buildDateRangeInfo(): void {
		if (this.valueIsValidDate(this.selectedStartDate) && this.valueIsValidDate(this.selectedEndDate)) {
			const dateRangeInfo: ISelectedDateRangeInfo = {
				dateRange: { name: 'Custom', startDate: this.getLocalDate(this.selectedStartDate), endDate: this.getLocalDate(this.selectedEndDate) },
				item: this.item
			};

			this.onDateRangeSelected.emit(dateRangeInfo);
		}
	}

	private getLocalDate(date: Date): string {
		const offset = date.getTimezoneOffset();
		const localDate = new Date(date.getTime() - (offset * 60 * 1000));
		return localDate.toISOString().split('T')[0];
	  }

	private valueIsValidDate(value: string | Date): boolean {
		const regex = /^\d{1,2}\/\d{1,2}\/\d{2,4}$/;
		const dateVal: string = typeof value === 'string' ? value : this.dateStringFormatter(value?.toString());
		if (!regex.test(dateVal))
			return false;

		return !isNaN(Date.parse(dateVal));
	}

	private changeStartDateCalendarView(): void {
		setTimeout(() => {
			if (this.startDateCalendar) {
				this.startDateCalendar._goToDateInView(this.selectedStartDate, 'month');
			}
		}, 800);
	}

	private changeEndDateCalendarView(): void {
		setTimeout(() => {
			if (this.endDateCalendar) {
				this.endDateCalendar._goToDateInView(this.selectedEndDate, 'month');
			}
		}, 800);
	}

	private createFieldListeners(): void {
		this.startDateField.valueChanges.pipe(
			debounceTime(300),
			skipWhile(val => !val),
			takeUntil(this.destroy$),
			distinctUntilChanged(),
			startWith(''),
			tap((val: any) => this.setStartDate(val))
		).subscribe();

		this.endDateField.valueChanges.pipe(
			debounceTime(300),
			skipWhile(val => !val),
			takeUntil(this.destroy$),
			distinctUntilChanged(),
			startWith(''),
			tap((val: any) => this.setEndDate(val))
		).subscribe();
	}

	private dateStringFormatter(date: string): string {
		const d = new Date(date);
		const formatted = (d.getMonth() + 1) + '/' +
						d.getDate() + '/' +
						d.getFullYear();

		return formatted;
	}
}
