import { Routes, RouterModule, ExtraOptions } from '@angular/router';

// services
import { AuthGuard } from './_shared/routeGuards/authGuard';
import { Enums } from './_shared/enums/enums';

// pages
import { LayoutComponent } from './_layout/layout.component';
import { LoginComponent } from './login/login.component';
import { SsoLoginComponent } from './login/ssologin.component';
import { SsoLoginV2Component } from './login/ssologinV2.component';

import { RoutingConfig } from './_config/routing/routing.config';
import { IRoutingConfig } from './_shared/models/models-index';
import { LogoutComponent } from './logout/logout.component';

var routingConfig: IRoutingConfig = new RoutingConfig;
const defaultRoute = routingConfig.routes.find(r => r.default === true);

export const routes: Routes = [
  { path: 'login', component: LoginComponent, data: { title: 'Login' } },
  { path: 'ssoLogin', component: SsoLoginComponent },
  { path: 'ssoLoginV2', component: SsoLoginV2Component },
  { path: 'logout', component: LogoutComponent },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: defaultRoute.defaultRoute ?? '/leads/overview'
      }]
  }, {
    path: 'program-overview',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    data: {
      id: 'program-overview',
      title: 'Program Overview',
      breadcrumb: 'Program Overview',
      allowAnonymous: true
    },
    loadChildren: () => import ('./program-overview/program-overview.module').then(m => m.ProgramOverviewModule)
  }, 
  {
    path: 'audiences',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    data: {
      id: 'audiences',
      title: 'Audiences Overview',
      breadcrumb: 'Audience Overview',
      allowAnonymous: true
    },
    loadChildren: () => import ('./audiences/audiences.module').then(m => m.AudiencesModule)
  }, 
  {
    path: 'provider-tools',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    data: {
      id: 'provider-tools',
      title: 'Provider Tools',
      breadcrumb: 'Provider Tools',
      allowAnonymous: true
    },
    loadChildren: () => import ('./provider-tools/provider-tools.module').then(m => m.ProviderToolsModule)
  },
  {
    path: 'digAd',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    data: {
      id: 'digAd',
      title: 'Digital Advertising',
      breadcrumb: 'Digital Advertising',
      allowAnonymous: true
    },
    loadChildren: () => import ('./digitalAdvertising/digad.module').then(m => m.DigAdModule)
  },
  {
    path: 'leads',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    // resolve: [DefaultFilterModelResolver],
    data: {
      id: 'leads',
      title: 'Leads',
      breadcrumb: 'Leads',
      allowAnonymous: true
    },
    loadChildren: () => import ('./leads/leads.module').then(m => m.LeadsModule)
  },
  {
    path: 'inventory',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    // resolve: [DefaultFilterModelResolver],
    data: {
      id: 'inventory',
      title: 'Inventory',
      breadcrumb: 'Inventory',
      allowAnonymous: true
    },
    loadChildren: () => import ('./inventory/inventory.module').then(m => m.InventoryModule)
  },
  {
    path: 'resources',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    data: {
      id: 'resources',
      title: 'Resources',
      breadcrumb: 'Resources',
      allowAnonymous: true,
      hideFilters: true
    },
    loadChildren: () => import('./resources/resources.module').then(m => m.ResourcesModule)
  },
  {
    path: 'sales',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    data: {
      id: 'sales',
      title: 'Sales',
      breadcrumb: 'Sales',
      allowAnonymous: true
    },
    loadChildren: () => import('./sales/sales.module').then(m => m.SalesModule)
  },
  {
    path: 'management',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    data: {
      id: 'management',
      title: 'Management',
      breadcrumb: 'Management',
      roles: ['System Administrator', 'System Analyst'],
      hideFilters: true
    },
    loadChildren: () => import('./management/management.module').then(m => m.ManagementModule)
  },
  {
    path: 'website',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    data: {
      id: 'website',
      title: 'Website',
      breadcrumb: 'Website',
      allowAnonymous: true
    },
    loadChildren: () => import('./website/website.module').then(m => m.WebsiteModule)
  }, {
    path: 'website-performance',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    data: {
      id: 'website-performance',
      title: 'Website',
      breadcrumb: 'Website',
      allowAnonymous: true
    },
    loadChildren: () => import('./hospitality-website/hospitality-website.module').then(m => m.HospitalityWebsiteModule)
  },
  {
    path: 'user-activity',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    data: {
      id: 'user-activity',
      title: 'User Activity',
      breadcumb: 'User Activity',
      allowAnonymous: true
    },
    loadChildren: () => import('./user-activity/user-activity.module').then(m => m.UserActivityModule)
  },
  {
    path: 'external-link',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    data: {
      id: 'external-link',
      title: '',
      breadcumb: '',
      allowAnonymous: true
    },
    loadChildren: () => import('./external-link/external-link.module').then(m => m.ExternalLinkModule)
  },
];
const extraOptions: ExtraOptions = { enableTracing: false, useHash: false, onSameUrlNavigation: 'reload' };
export const AppRouter = RouterModule.forRoot(routes, extraOptions);
