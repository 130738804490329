import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { GetQueryConfigurationsRequest, GetQueryConfigurationsResponse, QueryConfigurationRequest, QueryConfigurationModel, TestQueryConfigurationRequest, GetTestQueryStoreResultResponse } from '../../models/query-configuration.model';

@Injectable({
  providedIn: 'root'
})
export class QueryConfigurationService {
  private api = `${environment.baseV5ApiUri}/QueryConfiguration`;
  constructor(private http: HttpClient) { }

  getAll(request: GetQueryConfigurationsRequest): Observable<GetQueryConfigurationsResponse> {
    let params = new HttpParams();
    for (const [key, value] of Object.entries(request)) {
      if (value !== undefined && value !== null && value !== '') {
        params = params.append(key, value.toString());
      }
    }

    return this.http.get<GetQueryConfigurationsResponse>(`${this.api}`, { params })
      .pipe(
        catchError((error) => {
          console.error(error);
          throw error;
        })
      );
  }

  create(request: QueryConfigurationRequest): Observable<any> {
    return this.http.post(`${this.api}`, request)
      .pipe(
        catchError((error) => {
          console.error(error);
          throw error;
        })
      );
  }

  getById(id: string): Observable<QueryConfigurationModel> {
    return this.http.get<QueryConfigurationModel>(`${this.api}/${id}`)
      .pipe(
        catchError((error) => {
          console.error(error);
          throw error;
        })
      );
  }

  update(id: string, request: QueryConfigurationRequest): Observable<void> {
    return this.http.put<void>(`${this.api}/${id}`, request)
      .pipe(
        catchError((error) => {
          console.error(error);
          throw error;
        })
      );
  }

  delete(id: string)
  {
    return this.http.delete<void>(`${this.api}/${id}`)
    .pipe(
      catchError((error) => {
        console.error(error);
        throw error;
      })
    );
  }

  testQueryStore(request: TestQueryConfigurationRequest): Observable<GetTestQueryStoreResultResponse> {
    return this.http.post<GetTestQueryStoreResultResponse>(
      `${this.api}/test`,
      request
    );
  }
}
