export class DigAdTranslationConfig {
    public digAdReportTitles = {
      'en': {
        dealerperformance: 'Dealer Performance',
        digadoverview: 'Digital Advertising Overview',
        scorecard: 'Digital Advertising Scorecard',
        digitaladvertisingscorecard: 'Digital Advertising Scorecard',
        digitaladvertisingsummary: 'Digital Advertising Summary',
        referrer: 'Referrer Report',
        referrerreport: 'Referrer Report',
        vehiclesummary: 'Vehicle Summary',
        digitaladvertisingsupplierperformance: 'Digital Advertising Supplier Performance',
        digitaladvertisingsupplierscorecard: 'Digital Advertising Supplier Scorecard',
      },
      'fr-CA': {
        dealerperformance: 'Rendement du concessionnaire',
        digadoverview: 'Aperçu du site Web',
        scorecard: 'Tableau de bord',
        digitaladvertisingscorecard: 'Tableau de bord',
        digitaladvertisingsummary: 'Sommaire de site Web',
        referrer: 'Référent',
        referrerreport: 'Référent',
        vehiclesummary: 'Résumé du véhicule',
        digitaladvertisingsupplierperformance: 'Performance du fournisseur de site Web',
        digitaladvertisingsupplierscorecard: 'Tableau de bord du fournisseur',
      }
    };

    // column and chart metric labels
    public digAdMetrics = {
      'en': {
        none: 'None',
        pageViews: 'Page Views',
        pageViewsPreviousMonth: 'Prev. Month Page Views',
        pageViewsMOM: 'Page Views MOM',
        pageViewsPreviousYear: 'Prev. Year Page Views',
        pageViewsYOY: 'Page Views YOY',
        newVdps: 'New VDPs',
        newVdpsPreviousMonth: 'Prev. Month New VDPs',
        newVdpsMOM: 'New VDPs MOM',
        newVdpsPreviousYear: 'Prev. Year New VDPs',
        newVdpsYOY: 'New VDPs YOY',
        usedVdps: 'Used VDPs',
        usedVdpsPreviousMonth: 'Prev. Month Used VDPs',
        usedVdpsMOM: 'Used VDPs MOM',
        usedVdpsPreviousYear: 'Prev. Year Used VDPs',
        usedVdpsYOY: 'Used VDPs YOY',
        vdps: 'VDPs',
        vdpspreviousmonth: 'Prev Month. VDPs',
        vdpsmom: 'VDPs MOM',
        vdpspreviousyear: 'Prev Year. VDPs',
        vdpsyoy: 'VDPs YOY',
        servicePageViews: 'Service Views',
        servicePageViewsPreviousMonth: 'Prev. Month Service Views',
        servicePageViewsMOM: 'Service Views MOM',
        servicePageViewsPreviousYear: 'Prev. Year Service Views',
        servicePageViewsYOY: 'Service Views YOY',
        engagementRate: 'Engagement Rate',
        engagementRatePreviousMonth: 'Prev. Month Engagement Rate',
        engagementRateMOM: 'Engagement Rate MOM',
        engagementRatePreviousYear: 'Prev. Year Engagement Rate',
        engagementRateYOY: 'Engagement Rate YOY',
        averageTimeOnSite: 'Avg. Time On Site',
        averageTimeOnSitePreviousMonth: 'Prev. Month Avg. Time On Site',
        averageTimeOnSiteMOM: 'Avg. Time On Site MOM',
        averageTimeOnSitePreviousYear: 'Prev. Year Avg. Time On Site',
        averageTimeOnSiteYOY: 'Avg. Time On Site YOY',
        avgTimeOnSite: 'Avg. Time On Site',
        avgTimeOnSitePreviousMonth: 'Prev. Month Avg. Time On Site',
        avgTimeOnSiteMOM: 'Avg. Time On Site MOM',
        avgTimeOnSitePreviousYear: 'Prev. Year Avg. Time On Site',
        avgTimeOnSiteYOY: 'Avg. Time On Site YOY',
        bounceRate: 'Bounce Rate',
        bounceRatePreviousMonth: 'Prev. Month Bounce Rate',
        bounceRateMOM: 'Bounce Rate MOM',
        bounceRatePreviousYear: 'Prev. Year Bounce Rate',
        bounceRateYOY: 'Bounce Rate YOY',
        totalVisits: 'Website Visits',
        totalVisitsPreviousMonth: 'Prev. Month Website Visits',
        totalVisitsMOM: 'Website Visits MOM',
        totalVisitsPreviousYear: 'Prev. Year Website Visits',
        totalVisitsYOY: 'Website Visits YOY',
        uniqueVisits: 'Unique Visits',
        uniqueVisitsPreviousMonth: 'Prev. Month Unique Visits',
        uniqueVisitsMOM: 'Unique Visits MOM',
        uniqueVisitsPreviousYear: 'Prev. Year Unique Visits',
        uniqueVisitsYOY: 'Unique Visits YOY',
        engagements: 'Engagements',
        engagementsPreviousMonth: 'Prev. Month Engagements',
        engagementsMOM: 'Engagements MOM',
        engagementsPreviousYear: 'Prev. Year Engagements',
        engagementsYOY: 'Engagements YOY',
        actions: 'Actions',
        actionsPreviousMonth: 'Prev. Month Actions',
        actionsMOM: 'Actions MOM',
        actionsPreviousYear: 'Prev. Year Actions',
        actionsYOY: 'Actions YOY',
        actionRate: 'Action Rate',
        actionRatePreviousMonth: 'Prev. Month Action Rate',
        actionRateMOM: 'Action Rate MOM',
        actionRatePreviousYear: 'Prev. Year Action Rate',
        actionRateYOY: 'Action Rate YOY',
        formSubmissions: 'Form Submissions',
        formSubmissionsPreviousMonth: 'Prev. Month Form Submissions',
        formSubmissionsMOM: 'Form Submissions MOM',
        formSubmissionsPreviousYear: 'Prev. Year Form Submissions',
        formSubmissionsYOY: 'Form Submissions YOY',
        formConversionRate: 'Form Conversion Rate',
        formConversionRatePreviousMonth: 'Prev. Month Form Conversion Rate',
        formConversionRateMOM: 'Form Conversion Rate MOM',
        formConversionRatePreviousYear: 'Prev. Year Form Conversion Rate',
        formConversionRateYOY: 'Form Conversion Rate YOY',
        phoneCalls: 'Phone Calls',
        phoneCallsPreviousMonth: 'Prev. Month Phone Calls',
        phoneCallsMOM: 'Phone Calls MOM',
        phoneCallsPreviousYear: 'Prev. Year Phone Calls',
        phoneCallsYOY: 'Phone Calls YOY',
        phoneConversionRate: 'Phone Conversion Rate',
        phoneConversionRatePreviousMonth: 'Prev. Month Phone Conversion Rate',
        phoneConversionRateMOM: 'Phone Conversion Rate MOM',
        phoneConversionRatePreviousYear: 'Prev. Year Phone Conversion Rate',
        phoneConversionRateYOY: 'Phone Conversion Rate YOY',
        totalContacts: 'Total Contacts',
        totalContactsPreviousMonth: 'Prev. Month Total Contacts',
        totalContactsMOM: 'Total Contacts MOM',
        totalContactsPreviousYear: 'Prev. Year Total Contacts',
        totalContactsYOY: 'Total Contacts YOY',
        totalContactRate: 'Total Contact Rate',
        totalContactRatePreviousMonth: 'Prev. Month Total Contact Rate',
        totalContactRateMOM: 'Total Contact Rate MOM',
        totalContactRatePreviousYear: 'Prev. Year Total Contact Rate',
        totalContactRateYOY: 'Total Contact Rate YOY',
        clickToCalls: 'Click To Call',
        clickToCallsPreviousMonth: 'Prev. Month Click To Call',
        clickToCallsMOM: 'Click To Call MOM',
        clickToCallsPreviousYear: 'Prev. Year Click To Call',
        clickToCallsYOY: 'Click To Call YOY',
        newInventoryDetailViews: 'New Inventory Detail Views',
        newInventoryDetailViewsPreviousMonth: 'Prev. Month New Inventory Detail Views',
        newInventoryDetailViewsMOM: 'New Inventory Detail Views MOM',
        newInventoryDetailViewsPreviousYear: 'Prev. Year New Inventory Detail Views',
        newInventoryDetailViewsYOY: 'New Inventory Detail Views YOY',
        usedInventoryDetailViews: 'Used Inventory Detail Views',
        usedInventoryDetailViewsPreviousMonth: 'Prev. Month Used Inventory Detail Views',
        usedInventoryDetailViewsMOM: 'Used Inventory Detail Views MOM',
        usedInventoryDetailViewsPreviousYear: 'Prev. Year Used Inventory Detail Views',
        usedInventoryDetailViewsYOY: 'Used Inventory Detail Views YOY',
        cpoInventoryDetailViews: 'CPO Inventory Detail Views',
        cpoInventoryDetailViewsPreviousMonth: 'Prev. Month CPO Inventory Detail Views',
        cpoInventoryDetailViewsMOM: 'CPO Inventory Detail Views MOM',
        cpoInventoryDetailViewsPreviousYear: 'Prev. Year CPO Inventory Detail Views',
        cpoInventoryDetailViewsYOY: 'CPO Inventory Detail Views YOY',
        pageViewsPerVisit: 'Page Views Per Visit',
        pageViewsPerVisitPreviousMonth: 'Prev. Month Page Views Per Visit',
        pageViewsPerVisitMOM: 'Page Views Per Visit MOM',
        pageViewsPerVisitPreviousYear: 'Prev. Year Page Views Per Visit',
        pageViewsPerVisitYOY: 'Page Views Per Visit YOY',
        dealerEnrollments: 'Dealer Enrollments',
        averageActions: 'Avg. Actions',
        averageEngagementRate: 'Avg. Engagement Rate',
        averageVisitors: 'Avg. Visitors',
        totals: 'TOTALS',
        vehiclemake: 'Vehicle Makes',
        impressions: 'Impressions',
        impressionsPreviousMonth: 'Previous Month Impressions',
        impressionsMOM: 'Impressions MOM',
        impressionsPreviousYear: 'Previous Year Impressions',
        impressionsYOY: 'Impressions YOY',
        clicks: 'Clicks',
        clicksPreviousMonth: 'Previous Month Clicks',
        clicksMOM: 'Clicks MOM',
        clicksPreviousYear: 'Previous Year Clicks',
        clicksYOY: 'Clicks YOY',
        clickThroughRate: 'Click Through Rate',
        clickThroughRatePreviousMonth: 'Previous Month Click Through Rate',
        clickThroughRateMOM: 'Click Through Rate MOM',
        clickThroughRatePreviousYear: 'Previous Year Click Through Rate',
        clickThroughRateYOY: 'Click Through Rate YOY',
        budgetCostPerClick: 'Cost Per Click',
        budgetCostPerClickPreviousMonth: 'Previous Month Cost Per Click',
        budgetCostPerClickMOM: 'Cost Per Click MOM',
        budgetCostPerClickPreviousYear: 'Previous Year Cost Per Click',
        budgetCostPerClickYOY: 'Cost Per Click YOY',
        budgetCostPerImpression: 'Cost Per Impression',
        budgetCostPerImpressionPreviousMonth: 'Previous Month Cost Per Impression',
        budgetCostPerImpressionMOM: 'Cost Per Impression MOM',
        budgetCostPerImpressionPreviousYear: 'Previous Year Cost Per Impression',
        budgetCostPerImpressionYOY: 'Cost Per Impression YOY',
        uniqueVisitors: 'Unique Visitors',
        uniqueVisitorsPreviousMonth: 'Previous Month Unique Visitors',
        uniqueVisitorsMOM: 'Unique Visitors MOM',
        uniqueVisitorsPreviousYear: 'Previous Year Unique Visitors',
        uniqueVisitorsYOY: 'Unique Visitors YOY',
        conversionRate: 'Conversion Rate',
        conversionRatePreviousMonth: 'Previous Month Conversion Rate',
        conversionRateMOM: 'Conversion Rate MOM',
        conversionRatePreviousYear: 'Previous Year Conversion Rate',
        conversionRateYOY: 'Conversion Rate YOY',
        budgetCostPerAcquisition: 'Cost Per Acquisition',
        budgetCostPerAcquisitionPreviousMonth: 'Previous Month Cost Per Acquisition',
        budgetCostPerAcquisitionMOM: 'Cost Per Acquisition MOM',
        budgetCostPerAcquisitionPreviousYear: 'Previous Year Cost Per Acquisition',
        budgetCostPerAcquisitionYOY: 'Cost Per Acquisition YOY',
        serviceviews: 'Service Views',
        serviceviewspreviousmonth: 'Prev. Month Service Views',
        serviceviewsmom: 'Service Views MOM',
        serviceviewspreviousyear: 'Prev. Year Service Views',
        serviceviewsyoy: 'Service Views YOY'
      },
      'fr-CA': {
        provider: 'Fournisseur',
        supplier: 'Fournisseur',
        none: 'None',
        pageViews: 'Page vues',
        pageViewsPreviousMonth: 'Mois préc. Pages vues',
        pageViewsMOM: 'Pages vues MSM',
        pageViewsPreviousYear: 'Année préc. Pages vues',
        pageViewsYOY: 'Pages vues ASA',
        newVdps: 'PDV neuf',
        newVdpsPreviousMonth: 'Mois préc. PDV neuf',
        newVdpsMOM: 'PDV neuf MSM',
        newVdpsPreviousYear: 'Année préc. PDV neuf',
        newVdpsYOY: 'PDV neuf ASA',
        newVdpViews: 'Nouvelles vues PDV',
        newVdpViewsPreviousMonth: 'Mois préc. Nouvelles vues PDV',
        newVdpViewsPreviousMom: 'Nouvelles vues PDV MSM',
        newVdpViewsPreviousPreviousYear: 'Année préc. Nouvelles vues PDV',
        newVdpViewsPreviousYoy: 'Nouvelles vues PDV ASA',
        usedVdps: 'PDV d’occasion',
        usedVdpsPreviousMonth: 'Mois préc. PDV d’occasion',
        usedVdpsMOM: 'PDV d’occasion MSM',
        usedVdpsPreviousYear: 'Année préc. PDV d’occasion',
        usedVdpsYOY: 'PDV d’occasion ASA',
        usedVdpViews: 'Vues PDV utilisées',
        usedVdpViewsPreviousMonth: 'Mois préc. Vues PDV utilisées',
        usedVdpViewsPreviousMom: 'Vues PDV utilisées MSM',
        usedVdpViewsPreviousPreviousYear: 'Année préc. Vues PDV utilisées',
        usedVdpViewsPreviousYoy: 'Vues PDV utilisées ASA',
        cpoVdps: 'Vues VOC PDV',
        cpoVdpsPreviousMonth: 'Mois préc. Vues VOC PDV',
        cpoVdpsMOM: 'Vues VOC PDV MSM',
        cpoVdpsPreviousYear: 'Année préc. Vues VOC PDV',
        cpoVdpsYOY: 'Vues VOC PDV ASA',
        cpoVdpViews: 'Vues PDC PDV',
        cpoVdpViewsPreviousMonth: 'Mois préc. Vues PDC PDV',
        cpoVdpViewsPreviousMom: 'Vues PDC PDV MSM',
        cpoVdpViewsPreviousPreviousYear: 'Année préc. Vues PDC PDV',
        cpoVdpViewsPreviousYoy: 'Vues PDC PDV ASA',
        otherVdps: 'Autres vues PDV',
        otherVdpsPreviousMonth: 'Mois préc. Autres vues PDV',
        otherVdpsMOM: 'Autres vues PDV MSM',
        otherVdpsPreviousYear: 'Année préc. Autres vues PDV',
        otherVdpsYOY: 'Autres vues PDV ASA',
        otherVdpViews: 'Autres vues PDV',
        otherVdpViewsPreviousMonth: 'Mois préc. Autres vues PDV',
        otherVdpViewsPreviousMom: 'Autres vues PDV MSM',
        otherVdpViewsPreviousPreviousYear: 'Année préc. Autres vues PDV',
        otherVdpViewsPreviousYoy: 'Autres vues PDV ASA',
        servicePageViews: 'Vues du service',
        servicePageViewsPreviousMonth: 'Mois préc. Vues du service',
        servicePageViewsMOM: 'Vues du service MSM',
        servicePageViewsPreviousYear: 'Année préc. Vues du service',
        servicePageViewsYOY: 'Vues du service ASA',
        serviceViews: 'Vues du service',
        serviceViewsPreviousMonth: 'Mois préc. Vues du service',
        serviceViewsMOM: 'Vues du service MSM',
        serviceViewsPreviousYear: 'Année préc. Vues du service',
        serviceViewsYOY: 'Vues du service ASA',
        totalVdps: 'Vues PDV totales',
        totalVdpsPreviousMonth: 'Mois préc. Vues PDV totales',
        totalVdpsMOM: 'Vues PDV totales MSM',
        totalVdpsPreviousYear: 'Année préc. Vues PDV totales',
        totalVdpsYOY: 'Vues PDV totales ASA',
        totalVdpViews: 'Vues PDV totales',
        totalVdpViewsPreviousMonth: 'Mois préc. Vues PDV totales',
        totalVdpViewsPreviousMom: 'Vues PDV totales MSM',
        totalVdpViewsPreviousPreviousYear: 'Année préc. Vues PDV totales',
        totalVdpViewsPreviousYoy: 'Vues PDV totales ASA',
        vdps: 'PDV',
        vdpspreviousmonth: 'Mois préc. PDV',
        vdpsmom: 'PDV MSM',
        vdpspreviousyear: 'Année préc. PDV',
        vdpsyoy: 'PDV ASA',
        engagementRate: 'Taux d\'engagement',
        engagementRatePreviousMonth: 'Mois préc. Taux d\'engagement',
        engagementRateMOM: 'Taux d\'engagement MSM',
        engagementRatePreviousYear: 'Année préc. Taux d\'engagement',
        engagementRateYOY: 'Taux d\'engagement ASA',
        averageTimeOnSite: 'Temps moy. sur le site',
        averageTimeOnSitePreviousMonth: 'Mois préc. Temps moy. sur le site',
        averageTimeOnSiteMOM: 'Temps moy. sur le site MSM',
        averageTimeOnSitePreviousYear: 'Année préc. Temps moy. sur le site',
        averageTimeOnSiteYOY: 'Temps moy. sur le site ASA',
        avgTimeOnSite: 'Temps moy. sur le site',
        avgTimeOnSitePreviousMonth: 'Mois préc. Temps moy. sur le site',
        avgTimeOnSiteMOM: 'Temps moy. sur le site MSM',
        avgTimeOnSitePreviousYear: 'Année préc. Temps moy. sur le site',
        avgTimeOnSiteYOY: 'Temps moy. sur le site ASA',
        bounceRate: 'Taux de rebond',
        bounceRatePreviousMonth: 'Mois préc. Taux de rebond',
        bounceRateMOM: 'Taux de rebond MSM',
        bounceRatePreviousYear: 'Année préc. Taux de rebond',
        bounceRateYOY: 'Taux de rebond ASA',
        totalVisits: 'Visites du site Web',
        totalVisitsPreviousMonth: 'Mois préc. Visites du site Web',
        totalVisitsMOM: 'Visites du site Web MSM',
        totalVisitsPreviousYear: 'Année préc. Visites du site Web',
        totalVisitsYOY: 'Visites du site Web ASA',
        uniqueVisits: 'Visites uniques quotidiennes',
        uniqueVisitsPreviousMonth: 'Mois préc. Visites uniques quotidiennes',
        uniqueVisitsMOM: 'Unique Visits MSM',
        uniqueVisitsPreviousYear: 'Année préc. Visites uniques quotidiennes',
        uniqueVisitsYOY: 'Visites uniques quotidiennes ASA',
        engagements: 'Engagements',
        engagementsPreviousMonth: 'Mois préc. Engagements',
        engagementsMOM: 'Engagements MSM',
        engagementsPreviousYear: 'Année préc. Engagements',
        engagementsYOY: 'Engagements ASA',
        actions: 'Actions',
        actionsPreviousMonth: 'Mois préc. Actions',
        actionsMOM: 'Actions MSM',
        actionsPreviousYear: 'Année préc. Actions',
        actionsYOY: 'Actions ASA',
        actionRate: 'Taux d\'actions',
        actionRatePreviousMonth: 'Mois préc. Taux d\'actions',
        actionRateMOM: 'Taux d\'actions MSM',
        actionRatePreviousYear: 'Année préc. Taux d\'actions',
        actionRateYOY: 'Taux d\'actions ASA',
        formSubmissions: 'Les Soumissions De Formulaire',
        formSubmissionsPreviousMonth: 'Mois préc. Les Soumissions De Formulaire',
        formSubmissionsMOM: 'Les Soumissions De Formulaire MSM',
        formSubmissionsPreviousYear: 'Année préc. Les Soumissions De Formulaire',
        formSubmissionsYOY: 'Les Soumissions De Formulaire ASA',
        formConversionRate: 'Taux de conversion des formulaires',
        formConversionRatePreviousMonth: 'Mois préc. Taux de conversion des formulaires',
        formConversionRateMOM: 'Taux de conversion des formulaires MSM',
        formConversionRatePreviousYear: 'Année préc. Taux de conversion des formulaires',
        formConversionRateYOY: 'Taux de conversion des formulaires ASA',
        phoneCalls: 'Nombre d’appels',
        phoneCallsPreviousMonth: 'Mois préc. Nombre d’appels',
        phoneCallsMOM: 'Nombre d’appels MSM',
        phoneCallsPreviousYear: 'Année préc. Nombre d’appels',
        phoneCallsYOY: 'Nombre d’appels ASA',
        phoneConversionRate: 'Taux de conversion des appels',
        phoneConversionRatePreviousMonth: 'Mois préc. Taux de conversion des appels',
        phoneConversionRateMOM: 'Taux de conversion des appels MSM',
        phoneConversionRatePreviousYear: 'Année préc. Taux de conversion des appels',
        phoneConversionRateYOY: 'Taux de conversion des appels ASA',
        totalContacts: 'Nombre d\'appels',
        totalContactsPreviousMonth: 'Mois préc. Nombre d\'appels',
        totalContactsMOM: 'Nombre d\'appels MSM',
        totalContactsPreviousYear: 'Année préc. Nombre d\'appels',
        totalContactsYOY: 'Nombre d\'appels ASA',
        totalContactRate: 'Taux de conversion des appels',
        totalContactRatePreviousMonth: 'Mois préc. Taux de conversion des appels',
        totalContactRateMOM: 'Taux de conversion des appels MSM',
        totalContactRatePreviousYear: 'Année préc. Taux de conversion des appels',
        totalContactRateYOY: 'Taux de conversion des appels ASA',
        clickToCalls: 'Clics pour appeler',
        clickToCallsPreviousMonth: 'Mois préc. Clics pour appeler',
        clickToCallsMOM: 'Clics pour appeler MSM',
        clickToCallsPreviousYear: 'Année préc. Clics pour appeler',
        clickToCallsYOY: 'Clics pour appeler ASA',
        newInventoryDetailViews: 'Nouvelles vues de détail d\'inventaire',
        newInventoryDetailViewsPreviousMonth: 'Mois préc. Nouvelles vues de détail d\'inventaire',
        newInventoryDetailViewsMOM: 'Nouvelles vues de détail d\'inventaire MSM',
        newInventoryDetailViewsPreviousYear: 'Année préc. Nouvelles vues de détail d\'inventaire',
        newInventoryDetailViewsYOY: 'Nouvelles vues de détail d\'inventaire ASA',
        usedInventoryDetailViews: 'Vues de détail d\'inventaire utilisées',
        usedInventoryDetailViewsPreviousMonth: 'Mois préc. Vues de détail d\'inventaire utilisées',
        usedInventoryDetailViewsMOM: 'Vues de détail d\'inventaire utilisées MSM',
        usedInventoryDetailViewsPreviousYear: 'Année préc. Vues de détail d\'inventaire utilisées',
        usedInventoryDetailViewsYOY: 'Vues de détail d\'inventaire utilisées ASA',
        cpoInventoryDetailViews: 'Vues détaillées de l\'inventaire VOC',
        cpoInventoryDetailViewsPreviousMonth: 'Mois préc. Vues détaillées de l\'inventaire VOC',
        cpoInventoryDetailViewsMOM: 'Vues détaillées de l\'inventaire VOC MSM',
        cpoInventoryDetailViewsPreviousYear: 'Année préc. Vues détaillées de l\'inventaire VOC',
        cpoInventoryDetailViewsYOY: 'Vues détaillées de l\'inventaire VOC ASA',
        pageViewsPerVisit: 'Pages vues par visite',
        pageViewsPerVisitPreviousMonth: 'Mois préc. Pages vues par visite',
        pageViewsPerVisitMOM: 'Page Views Per Visit MSM',
        pageViewsPerVisitPreviousYear: 'Année préc. Pages vues par visite',
        pageViewsPerVisitYOY: 'Pages vues par visite ASA',
        dealerEnrollments: 'Inscriptions des concessionnaires',
        averageActions: 'Moy. Actions',
        averageEngagementRate: 'Moy. Taux d\'engagement',
        averageVisitors: 'Moy. Visiteurs',
        totals: 'TOTAUX',
        vehiclemake: 'Marque du véhicule',
        impressions: 'Impressions',
        impressionsPreviousMonth: 'Impressions du mois précédent',
        impressionsMOM: 'Impressions MOM',
        impressionsPreviousYear: 'Impressions de l\'année précédente',
        impressionsYOY: 'Impressions YOY',
        clicks: 'Clics',
        clicksPreviousMonth: 'Clics du mois précédent',
        clicksMOM: 'Clics MOM',
        clicksPreviousYear: 'Clics de l\'année précédente',
        clicksYOY: 'Clics YOY',
        clickThroughRate: 'Taux de clic',
        clickThroughRatePreviousMonth: 'Taux de clic du mois précédent',
        clickThroughRateMOM: 'Taux de clic MOM',
        clickThroughRatePreviousYear: 'Taux de clic de l\'année précédente',
        clickThroughRateYOY: 'Taux de clic YOY',
        budgetCostPerClick: 'Coût par clic',
        budgetCostPerClickPreviousMonth: 'Coût par clic du mois précédent',
        budgetCostPerClickMOM: 'Coût par clic MOM',
        budgetCostPerClickPreviousYear: 'Coût par clic de l\'année précédente',
        budgetCostPerClickYOY: 'Coût par clic YOY',
        budgetCostPerImpression: 'Coût par Impression',
        budgetCostPerImpressionPreviousMonth: 'Coût par impression du mois précédent',
        budgetCostPerImpressionMOM: 'Coût par impression MOM',
        budgetCostPerImpressionPreviousYear: 'Coût par impression de l\'année précédente',
        budgetCostPerImpressionYOY: 'Coût par impression YOY',
        uniqueVisitors: 'Visiteurs Uniques',
        uniqueVisitorsPreviousMonth: 'Visiteurs Uniques du mois précédent',
        uniqueVisitorsMOM: 'Visiteurs Uniques MOM',
        uniqueVisitorsPreviousYear: 'Visiteurs Uniques de l\'année précédente',
        uniqueVisitorsYOY: 'Visiteurs Uniques YOY',
        conversionRate: 'Taux de conversion',
        conversionRatePreviousMonth: 'Taux de conversion du mois précédent',
        conversionRateMOM: 'Taux de conversion MOM',
        conversionRatePreviousYear: 'Taux de conversion de l\'année précédente',
        conversionRateYOY: 'Taux de conversion YOY',
        budgetCostPerAcquisition: 'Coût par acquisition',
        budgetCostPerAcquisitionPreviousMonth: 'Coût par acquisition du mois précédent',
        budgetCostPerAcquisitionMOM: 'Coût par acquisition MOM',
        budgetCostPerAcquisitionPreviousYear: 'Coût par acquisition de l\'année précédente',
        budgetCostPerAcquisitionYOY: 'Coût par acquisition YOY',

      }
    };
    public digAdLabels = {
      'en': {
        allmodels: 'All Models',
        devicetype: 'Device Type',
        engagedvisits: 'Engaged Visits',
        totalvisits: 'Total Visits',
        visitortrend: 'Visitor Trend',
        visits: 'Visits',
        uniquevisitors: 'Unique Visitors',
        engagementrate: 'Engagement Rate',
        actionrate: 'Action Rate',
        referrertype: 'Referrer Type',
        channel: 'Channel',
        referrerquality: 'Referrer Quality',
        vdps: 'VDPs',
        bouncerate: 'Bounce Rate',
        sales: 'Sales',
        service: 'Service',
        other: 'Other',
        bounces: 'Bounces',
        salesserviceotherbounces: 'Sales | Service | Other | Bounces',
        trafficbydevice: 'Traffic By Device',
        provider: 'Provider',
        desktop: 'Desktop',
        tablet: 'Tablet',
        mobile: 'Mobile',
        actions: 'Actions',
        engagement: 'Engagement',
        traffic: 'Traffic',
        awareness: 'Awareness',
        volume: 'Volume',
        pageviews: 'Page Views',
        uniquevisits: 'Unique Visits',
        timeonsite: 'Time on Site',
        vdpviews: 'VDP Views',
        formsubmissions: 'Form Submissions',
        engagements: 'Engagements',
        clicktocalls: 'Click to Calls',
        calltrackingvolume: 'Call Tracking Volume',
        dealeravg: 'Dealer Avg',
        allproviders: 'All Providers',
        allsuppliers: 'All Suppliers',
        sm360: 'SM360',
        cdk: 'Sincro',
        foxdealer: 'Fox Dealer',
        dealercom: 'Dealer.com',
        edealer: 'EDealer',
        dealerinspire: 'Dealer Inspire',
        impressions: 'Impressions',
        clickthroughrate: 'Click Through Rate',
        costperimpression: 'Cost Per Impression',
        costperclick: 'Cost Per Click',
        phonecalls: 'Phone Calls',
        costperconversion: 'Cost Per Conversion',
        providerperformance: 'Provider Performance',
        organicvspaid: 'Organic vs Paid',
        channelperformance: 'Channel Performance',
        traffictrend: 'Traffic Trend'
      },
      'fr-CA': {
        allmodels: 'Tous les modèles',
        devicetype: 'Type d\'appareil',
        engagedvisits: 'Visites avec engagements',
        totalvisits: 'Total de visites',
        visitortrend: 'Tendances des visiteurs',
        visits: 'Visiteurs',
        uniquevisitors: 'Visiteurs uniques',
        engagementrate: 'Taux d\'engagement',
        actionrate: 'Taux d\'actions',
        referrertype: 'Type référent',
        channel: 'Chaîne',
        referrerquality: 'Qualité du référent',
        vdps: 'PDV',
        bouncerate: 'Taux de rebond',
        sales: 'Ventes',
        service: 'Service',
        other: 'Autre',
        bounces: 'Rebond',
        salesserviceotherbounces: 'Ventes | Service | Autre | Rebond',
        trafficbydevice: 'Trafic selon l\'appareil',
        provider: 'Fournisseur',
        supplier: 'Fournisseur',
        desktop: 'Bureau',
        tablet: 'Tablette',
        mobile: 'Mobile',
        actions: 'Actions',
        engagement: 'Engagement',
        traffic: 'Trafic',
        awareness: 'Conscience',
        volume: 'Le volume',
        pageviews: 'Pages vues',
        uniquevisits: 'Visites Uniques',
        timeonsite: 'Temps passé sur le site',
        vdpviews: 'Vues de PDV',
        formsubmissions: 'Les Soumissions De Formulaire',
        engagements: 'Engagements',
        clicktocalls: 'Clics pour appeler',
        calltrackingvolume: 'Nombre d\'appels',
        dealeravg: 'Concessionnaire moy',
        allproviders: 'Tous Les Fourniesseurs',
        allsuppliers: 'Tous Les Fourniesseurs',
        sm360: 'SM360',
        cdk: 'Sincro',
        foxdealer: 'Fox Dealer',
        dealercom: 'Dealer.com',
        edealer: 'EDealer',
        dealerinspire: 'Dealer Inspire',
        totals: 'TOTAUX',
        vehiclemake: 'Marque du véhicule',
        impressions: 'Impressions',
        clickthroughrate: 'Taux de clic',
        costperimpression: 'Coût par Impression',
        costperclick: 'Coût par Clic',
        phonecalls: 'Appels Téléphoniques',
        costperconversion: 'Coût par Conversion',
        providerperformance: 'Performance d Fournisseur',
        organicvspaid: 'Organique vs Payant',
        channelperformance: 'Canal de Performance',
        traffictrend: 'Tendance du Trafic',
      }
    };

    public digAdChartDimensions = {
        'en': {
          allchannels: 'All Channels',
          typedbookmarked: 'Typed/Bookmarked',
          thirdpartysites: 'Third Party Sites',
          organicsearch: 'Organic Search',
          paidsocial: 'Paid Social',
          organicsocial: 'Organic Social',
          paidsearch: 'Paid Search',
          other: 'Other',
          paiddisplay: 'Paid Display',
          brand: 'Brand',
          paidemail: 'Paid Email',
          organicemail: 'Organic Email',
          allproviders: 'All Suppliers',
          search: 'Search',
          display: 'Display',
          video: 'Video',
          social: 'Social',
          email: 'Email',
          paid: 'Paid',
          organic: 'Organic'
        },
        'fr-CA': {
          allchannels: 'Toutes Les Chaînes',
          allmodels: 'Tous les modèles',
          typedbookmarked: 'Dactylographié/ajouté à ses favori',
          thirdpartysites: 'Sites Tiers',
          organicsearch: 'Recherche organique',
          paidsocial: 'Social payant',
          organicsocial: 'Social organique',
          paidsearch: 'Recherche payante',
          other: 'Autres',
          paiddisplay: 'Affichage payant',
          brand: 'Marque',
          paidemail: 'Courriel payant',
          organicemail: 'Courriel organique',
          allproviders: 'Tous Les Fourniesseurs',
          search: 'Recherche',
          display: 'Affichage',
          video: 'Vidéo',
          social: 'Social',
          email: 'Courriel',
          paid: 'Payant',
          organic: 'Organique',
        }
      };

    public digAdChannels = {
     'en': {
       allchannels: 'All Channels',
       allmodels: 'All Models',
       thirdpartysites: 'Third Party Sites',
       typedbookmarked: 'Typed/Bookmarked',
       organicsearch: 'Organic Search',
       paidsocial: 'Paid Social',
       organicsocial: 'Organic Social',
       paidsearch: 'Paid Search',
       other: 'Other',
       paiddisplay: 'Paid Display',
       brand: 'Brand',
       paidemail: 'Paid Email',
       organicemail: 'Organic Email',
       allproviders: 'All Suppliers',
       allsuppliers: 'All Suppliers',
       search: 'Search',
       display: 'Display',
       video: 'Video',
       social: 'Social',
       email: 'Email'
     },
     'fr-CA': {
       allchannels: 'Toutes Les Chaînes',
       allmodels: 'Tous les modèles',
       thirdpartysites: 'Sites Tiers',
       typedbookmarked: 'Dactylographié/ajouté à ses favori',
       organicsearch: 'Recherche organique',
       paidsocial: 'Social payant',
       organicsocial: 'Social organique',
       paidsearch: 'Recherche payante',
       other: 'Autres',
       paiddisplay: 'Affichage payant',
       brand: 'Marque',
       paidemail: 'Courriel payant',
       organicemail: 'Courriel organique',
       allproviders: 'Tous Les Fourniesseurs',
       allsuppliers: 'Tous Les Fourniesseurs',
       search: '__Search__',
       display: '__Display__',
       video: '__Video__',
       social: '__Social__',
       email: '__Email__'
     }
    };
}
