import { Component, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { MatMenu } from '@angular/material/menu';

export interface SelectableItem {
  value: any;
  label: string;
  children?: SelectableItem[];
}

@Component({
  selector: 'nested-menu',
  templateUrl: './nested-menu.component.html',
  styleUrls: ['./nested-menu.component.scss']
})
export class NestedMenuComponent {
  @ViewChild('menu', { static: true }) menu!: MatMenu;

  @Input() items: SelectableItem[] = [];

  private _selectedItem?: SelectableItem;
  @Input()
  set selectedItem(item: SelectableItem | undefined) {
    this._selectedItem = item;
    if (item) {
      this.updateSelectedPath();
    }
  }
  get selectedItem(): SelectableItem | undefined {
    return this._selectedItem;
  }

  @Output() selected = new EventEmitter<SelectableItem>();

  selectedPath: SelectableItem[] = [];

  selectItem(item: SelectableItem): void {
    this.selectedItem = item;
    this.selected.emit(item);
    this.updateSelectedPath();
  }

  private updateSelectedPath(): void {
    if (this.selectedItem) {
      this.selectedPath = [];
      this.findAndSetSelectedPath(this.items, this.selectedItem);
    }
  }

  private findAndSetSelectedPath(items: SelectableItem[], selectedItem: SelectableItem): boolean {
    for (let item of items) {
      if (item.value === selectedItem.value) {
        this.selectedPath.push(item);
        return true;
      }
      if (item.children && item.children.length > 0) {
        if (this.findAndSetSelectedPath(item.children, selectedItem)) {
          this.selectedPath.unshift(item);
          return true;
        }
      }
    }
    return false;
  }

  isInSelectedPath(item: SelectableItem): boolean {
    return this.selectedPath.some(pathItem => pathItem.value === item.value);
  }

  showCheckMark(item: SelectableItem): boolean {
    return this.selectedItem?.value === item.value;
  }
}
