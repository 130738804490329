<div class="faceted-filter-panel-content-container text-base md:text-sm scrollable-x scrollbar-thin scrollbar-thumb scrollbar-thumb-rounded-md scrollbar-track-tint hover:scrollbar-thumb">

    <div *ngIf="isSearching; else dataTemplate;" class="w-full h-full">

        <div class="search-result-container" *ngIf="searchResults$ | async as searchResults; else noResults">

            <div class="w-full h-14 flex items-center px-3 py-3 text-gray-900 font-medium border-b border-gray-100">
                <span>{{searchResults?.length}}</span>&nbsp;<span>Results</span>
            </div>

            <ng-container *ngFor="let result of searchResults; let even = even">

                <div class="w-full min-h-[56px] py-1 flex flex-col justify-center text-gray-900 font-inter border-b border-gray-200 cursor-pointer panel-option"
                     [ngClass]="{'bg-gray-50': even}" (click)="onSearchResultItemSelected(result)">

                    <div class="px-3 font-medium">{{result.label}}</div>
                    <div class="px-3 text-sm font-normal">{{result.path}}</div>

                </div>

            </ng-container>

        </div>

    </div>

    <ng-template #dataTemplate>

        <div *ngIf="filterDataItems; else noData" class="pane root-pane">

            <ng-container *ngFor="let item of filterDataItems">

                <div [ngClass]="{'active': selectedRootItem?.label === item.label}" class="panel-option w-full h-10 flex items-center px-3 py-3 text-gray-900 font-medium cursor-pointer border-b border-gray-300"
                     (click)="onRootItemSelected(item);">

                    <div class="flex-1">{{item.label}}</div>

                    <continuation-indicator *ngIf="item.items" [isActive]="selectedRootItem?.label === item.label"></continuation-indicator>

                </div>

            </ng-container>

        </div>

    </ng-template>

    <ng-container #paneComponentHost></ng-container>

    <ng-template #noData>

        <div class="w-full h-full flex items-center justify-items-center">
            No Filter In
        </div>

    </ng-template>

    <ng-template #noResults>

        <div class="w-full h-full flex items-center justify-center">
            No results for search.
        </div>

    </ng-template>

</div>
